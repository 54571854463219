import GroupFinder from "../UniversalTable/GroupFinder";
// import config from "../../../config/config";
import Jwt_proxy from "../../API/Api";
import { useState } from "react";
const GroupFinderViewer = ({
  data,
  grouplist,
  Arr,
  copy,
  groupsSel,
  setGroupsSel,
  onShow,
  setShowPopup,
  showPopup,
  setGrouplist,
  historyPopup,
}) => {
  const Tree = ({ data, oldata, hr }) => {
    const { name, id } = data;
    const [active, setActive] = useState(false);
    let classLists = "TreeBody";
    if (active) {
      classLists += " acActive";
    }
    const addGroupToList = (department, str = "department") => {
      let arr = [];
      if (
        !groupsSel.some(
          (i) =>
            i.department_id === department.id &&
            i.department_name === department.name
        )
      ) {
        arr.push({
          department_id: null,
          group_id: null,
          group_name: null,
          job_position_id: null,
          job_position_name: null,
          department_name: null,
          fl_sec_read: false,
          fl_sec_cont_change: false,
          fl_sec_write: false,
          fl_sec_admin: false,
          fl_art_read: false,
          fl_art_write: false,
          fl_art_cont_download: false,
          fl_art_cont_print: false,
          fl_art_cont_change: false,
          [str + "_name"]: department.name,
          [str + "_id"]: department.id,
          delete: false,
        });
      }
      const uniqueGroupsSel = Array.from(
        new Map(
          [...groupsSel, ...arr].map((item) => [
            `${item.group_id}-${item.department_id}-${item.job_position_id}`,
            item,
          ])
        ).values()
      );
      setGroupsSel((groupsSel) => [...uniqueGroupsSel]);
    };
    let dat = [
      ...oldata.map((i, n) =>
        i.pid === id ? (
          <Tree data={i} id={i.id} pid={i.pid} oldata={oldata} hr={"—"} />
        ) : null
      ),
    ];

    let [buttonToggle, setButtonToggle] = useState("+");
    function ButtonToggleOpen() {
      if (buttonToggle === "+") {
        setButtonToggle((buttonToggle = "-"));
      } else {
        setButtonToggle((buttonToggle = "+"));
      }
      setActive(!active);
    }

    return (
      <>
        <div className={"TreeGroupItem"}>
          <div className={"TreeGroupItemTitle"} id={id}>
            {hr}{" "}
            <button
              type="button"
              className="TreeOpenButton"
              onClick={() => {
                ButtonToggleOpen();
              }}
            >
              {buttonToggle}
            </button>
            <img src="/static/img/Group 187.svg" alt=""></img>
            <span
              onDoubleClick={() => {
                addGroupToList(data);
                onShow();
              }}
              className="strong"
            >
              {name}
            </span>
          </div>
          <div className={classLists}>{dat}</div>
        </div>
      </>
    );
  };

  async function getDataFromPopupTree() {
    const ip = localStorage.getItem("server_ip");
    const token = localStorage.getItem("token");
    let res = await fetch(ip + "/api/hr/list/get_deportaments", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-token": token,
      },
      body: JSON.stringify({}),
    });
    if (res.ok) {
      let r = await res.json();
      r = [
        ...r.map((i, n) =>
          i.pid == null ? (
            <Tree data={i} id={i.id} pid={i.pid} oldata={r} hr={"—"} />
          ) : null
        ),
      ];
      onShow(
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            minHeight: "42vh",
          }}
        >
          <span>Выбрать отдел</span>
          <div className="scroll">{r}</div>
          <span>Кликните дважды чтобы выбрать</span>
        </div>
      );
    }
  }

  async function getAllGroups(str) {
    let token = localStorage.getItem("token");
    let ip = localStorage.getItem("server_ip");
    let res = await fetch(ip + "/api/wiki/admin/get_all_groups", {
      headers: {
        "Content-Type": "application/json",
        "x-token": token,
      },
      method: "POST",
      body: {},
    });
    if (res.ok) {
      let r = await res.json();
      if (str === "add") {
        onShow(
          <>
            <span>Выбрать группу</span>
            <GroupFinder
              setShowPopup={setShowPopup}
              remove={false}
              setGrouplist={setGrouplist}
              groupsSel={groupsSel}
              setGroupsSel={setGroupsSel}
              copy={copy}
              grouplist={r.filter(
                (item) =>
                  !groupsSel.some((group) => group.group_id === item.id) ||
                  groupsSel.some(
                    (group) =>
                      group.group_id === item.id && group.delete === true
                  )
              )}
              str={"group"}
            />
          </>
        );
      }
      if (str === "remove") {
        onShow(
          <>
            <GroupFinder
              setShowPopup={setShowPopup}
              setGrouplist={setGrouplist}
              remove={true}
              groupsSel={groupsSel}
              setGroupsSel={setGroupsSel}
              copy={copy}
              grouplist={r.filter((item) =>
                groupsSel.some((group) => group.group_id === item.id)
              )}
            />
          </>
        );
      }
    }
  }

  async function getAllJobs() {
    let token = localStorage.getItem("token");
    let ip = localStorage.getItem("server_ip");
    let res = await fetch(ip + "/api/hr/list/get_job_positions", {
      headers: {
        "Content-Type": "application/json",
        "x-token": token,
      },
      method: "POST",
      body: {},
    });
    if (res.ok) {
      let r = await res.json();

      onShow(
        <>
          <span>Выбрать должность</span>
          <GroupFinder
            setShowPopup={setShowPopup}
            remove={false}
            setGrouplist={setGrouplist}
            groupsSel={groupsSel}
            setGroupsSel={setGroupsSel}
            copy={copy}
            grouplist={r.filter(
              (item) =>
                !groupsSel.some((group) => group.job_position_id === item.id) ||
                groupsSel.some(
                  (group) =>
                    group.job_position_id === item.id && group.delete === true
                )
            )}
            str={"job_position"}
          />
        </>
      );
    }
  }

  function checkRules(e, group_id, department_id, job_position_id) {
    setGroupsSel(
      groupsSel.map((i) =>
        i.group_id !== null && i.group_id === group_id
          ? { ...i, [e.target.name]: !i[e.target.name] }
          : i.department_id !== null && i.department_id == department_id
          ? { ...i, [e.target.name]: !i[e.target.name] }
          : i.job_position_id !== null && i.job_position_id == job_position_id
          ? { ...i, [e.target.name]: !i[e.target.name] }
          : i
      )
    );
  }

  function getTableDataGroups(str) {
    Jwt_proxy(() => getAllGroups(str));
  }
  const [selectedItem, setSelectedItem] = useState({});
  function deleteItem() {
    // console.log(selectedItem);
    // const index = groupsSel.findIndex((element) => (
    //   element.group_id === selectedItem.group_id &&
    //   element.department_id === selectedItem.department_id
    // ));
    // if (index !== -1) {
    //   const newGroupsSel = [...groupsSel];
    //   newGroupsSel.splice(index, 1);
    //   setGroupsSel(newGroupsSel);
    const uniqueGroupsSel = Array.from(
      new Map(
        [...groupsSel].map((item) => [
          `${item.group_id}-${item.department_id}-${item.job_position_id}`,
          item,
        ])
      ).values()
    );
    setGroupsSel(
      uniqueGroupsSel.map((i) => ({
        ...i,
        delete:
          i.group_id === selectedItem.group_id &&
          i.department_id === selectedItem.department_id &&
          i.job_position_id === selectedItem.job_position_id
            ? true
            : i.delete,
      }))
    );
    setSelectedItem({});
  }

  return (
    <>
      <div
        className="popopColumn border"
        style={{
          maxHeight: "30vh",
          display: "flex",
          overflowY: "scroll",
        }}
      >
      
          
            <table  style={{position:'sticky', left:0, width:'auto', zIndex:20, maxWidth:'20vw'}}>
              <thead>
                <tr>
                  <th style={{maxWidth:'30%'}}>Группа</th>
                  <th style={{maxWidth:'30%'}}>Отдел</th>
                  <th style={{maxWidth:'30%'}}>Должность</th>
                </tr>
              </thead>

              <tbody>
                {[
                  ...groupsSel.map((i) =>
                    !i.delete ? (
                      <tr
                        style={{
                          backgroundColor:
                            i.group_id === selectedItem.group_id &&
                            i.department_id === selectedItem.department_id &&
                            i.job_position_id === selectedItem.job_position_id
                              ? "gray"
                              : "white",
                        }}
                        onClick={() => {
                          if (Object.keys(selectedItem).length === 0) {
                            setSelectedItem({ ...i, delete: true });
                          } else {
                            setSelectedItem({});
                          }
                        }}
                      >
                        <td
                        style={{maxWidth:'30%'}}
                          onChange={(e) =>
                            checkRules(
                              e,
                              i.group_id,
                              i.department_id,
                              i.job_position_id
                            )
                          }
                          disabled={historyPopup !== false ? "disabled" : ""}
                        >
                          {i.group_name?i.group_name.substr(0, 20):null}
                        </td>
                        <td
                        style={{maxWidth:'30%'}}
                          onChange={(e) => checkRules(e, i.department_id)}
                          disabled={historyPopup !== false ? "disabled" : ""}
                        >
                          {i.department_name?i.department_name.substr(0, 20):null}
                        </td>
                        <td
                        style={{maxWidth:'30%'}}
                          onChange={(e) => checkRules(e, i.job_position_id)}
                          disabled={historyPopup !== false ? "disabled" : ""}
                        >
                          {i.job_position_name?i.job_position_name.substr(0, 20):null}
                        </td>
                      </tr>
                    ) : null
                  ),
                ]}
              </tbody>
            </table>
         
          
            <table style={{ minWidth: "50vw" }}>
              <thead>
                <tr>
                  <th>Чтение раздела</th>
                  <th>Перенос раздела</th>
                  <th>Изменение раздела</th>
                  <th>Администрирование раздела</th>
                  <th>Чтение статей</th>
                  <th>Перенос статей</th>
                  <th>Редактирование статей</th>
                  <th>Скачивание статей</th>
                  <th>Печать статей</th>
                </tr>
              </thead>
              <tbody className="tbodyScroll">
                {[
                  ...groupsSel.map((i) =>
                    !i.delete ? (
                      <tr
                        style={{
                          backgroundColor:
                            i.group_id === selectedItem.group_id &&
                            i.department_id === selectedItem.department_id &&
                            i.job_position_id === selectedItem.job_position_id
                              ? "gray"
                              : "white",
                        }}
                        onClick={() => {
                          if (Object.keys(selectedItem).length === 0) {
                            setSelectedItem({ ...i, delete: true });
                          } else {
                            setSelectedItem({});
                          }
                        }}
                      >
                        <td
                          onChange={(e) =>
                            checkRules(
                              e,
                              i.group_id,
                              i.department_id,
                              i.job_position_id
                            )
                          }
                          disabled={historyPopup !== false ? "disabled" : ""}
                        >
                          <input
                            type="checkbox"
                            checked={i.fl_sec_read ? "checked" : ""}
                            name="fl_sec_read"
                            disabled={historyPopup !== false ? "disabled" : ""}
                          />
                          {i.fl_sec_read}
                        </td>
                        <td
                          onChange={(e) =>
                            checkRules(
                              e,
                              i.group_id,
                              i.department_id,
                              i.job_position_id
                            )
                          }
                        >
                          <input
                            type="checkbox"
                            checked={i.fl_sec_cont_change ? "checked" : ""}
                            name="fl_sec_cont_change"
                            disabled={historyPopup !== false ? "disabled" : ""}
                          />
                          {i.fl_sec_cont_change}
                        </td>
                        <td
                          onChange={(e) =>
                            checkRules(
                              e,
                              i.group_id,
                              i.department_id,
                              i.job_position_id
                            )
                          }
                        >
                          <input
                            type="checkbox"
                            checked={i.fl_sec_write ? "checked" : ""}
                            name="fl_sec_write"
                            disabled={historyPopup !== false ? "disabled" : ""}
                          />
                          {i.fl_sec_write}
                        </td>
                        <td
                          onChange={(e) =>
                            checkRules(
                              e,
                              i.group_id,
                              i.department_id,
                              i.job_position_id
                            )
                          }
                        >
                          <input
                            type="checkbox"
                            checked={i.fl_sec_admin ? "checked" : ""}
                            name="fl_sec_admin"
                            disabled={historyPopup !== false ? "disabled" : ""}
                          />
                          {i.fl_sec_admin}
                        </td>
                        <td
                          onChange={(e) =>
                            checkRules(
                              e,
                              i.group_id,
                              i.department_id,
                              i.job_position_id
                            )
                          }
                        >
                          <input
                            type="checkbox"
                            checked={i.fl_art_read ? "checked" : ""}
                            name="fl_art_read"
                            disabled={historyPopup !== false ? "disabled" : ""}
                          />
                          {i.fl_art_read}
                        </td>
                        <td
                          onChange={(e) =>
                            checkRules(
                              e,
                              i.group_id,
                              i.department_id,
                              i.job_position_id
                            )
                          }
                        >
                          <input
                            type="checkbox"
                            checked={i.fl_art_cont_change ? "checked" : ""}
                            name="fl_art_cont_change"
                            disabled={historyPopup !== false ? "disabled" : ""}
                          />
                          {i.fl_sec_cont_change}
                        </td>
                        <td
                          onChange={(e) =>
                            checkRules(
                              e,
                              i.group_id,
                              i.department_id,
                              i.job_position_id
                            )
                          }
                        >
                          <input
                            type="checkbox"
                            checked={i.fl_art_write ? "checked" : ""}
                            name="fl_art_write"
                            disabled={historyPopup !== false ? "disabled" : ""}
                          />
                          {i.fl_art_write}
                        </td>
                        <td
                          onChange={(e) =>
                            checkRules(
                              e,
                              i.group_id,
                              i.department_id,
                              i.job_position_id
                            )
                          }
                        >
                          <input
                            type="checkbox"
                            checked={i.fl_art_cont_download ? "checked" : ""}
                            name="fl_art_cont_download"
                            disabled={historyPopup !== false ? "disabled" : ""}
                          />
                          {i.fl_art_cont_download}
                        </td>
                        <td
                          onChange={(e) =>
                            checkRules(
                              e,
                              i.group_id,
                              i.department_id,
                              i.job_position_id
                            )
                          }
                        >
                          <input
                            type="checkbox"
                            checked={i.fl_art_cont_print ? "checked" : ""}
                            name="fl_art_cont_print"
                            disabled={historyPopup !== false ? "disabled" : ""}
                          />
                          {i.fl_art_cont_print}
                        </td>
                        
                      </tr>
                    ) : null
                  ),
                ]}
              </tbody>
            </table>
          </div>
      
    

      <div style={{ width: "98.8%" }}>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            marginTop: "5%",
          }}
        >
          <button
            type="button"
            style={{ width: "20%" }}
            className="wikiButton"
            disabled={historyPopup !== false ? "disabled" : ""}
            onClick={() => {
              getTableDataGroups("add");
            }}
          >
            Выбрать группу
          </button>
          <button
            type="button"
            style={{ width: "20%" }}
            className="wikiButton"
            disabled={historyPopup !== false ? "disabled" : ""}
            onClick={() => {
              getDataFromPopupTree();
            }}
          >
            Выбрать отдел
          </button>
          <button
            type="button"
            style={{ width: "20%" }}
            className="wikiButton"
            disabled={historyPopup !== false ? "disabled" : ""}
            onClick={() => {
              getAllJobs();
            }}
          >
            Выбрать должность
          </button>

          <button
            style={{ width: "20%" }}
            type="button"
            className="wikiButton"
            disabled={historyPopup !== false ? "disabled" : ""}
            onClick={() => {
              deleteItem();
            }}
          >
            Удалить
          </button>
        </div>
      </div>
    </>
  );
};
export default GroupFinderViewer;
