import React, { useState, useCallback, useEffect } from "react";
import "./MainRightToolbar.css";
import PopupTwooLevel from "../Popup/PopupTwooLevel";
import Jwt_proxy from "../../API/Api";
// import config from "../../../config/config";
import { useNavigate } from "react-router-dom";
import Datefunc from "../DateFunc";

const ListTollbar = ({
  post,
  articleHistory,
  setpopupActive,
  onRedact,
  setArticleData,
  RigtMenuShow,
  setRightMenuShow,
  ArticleData,
  getLeftArr,
  getSections,
  setArticleText,
  redArticle,
  setRedArticle,
  GoArticleToRedact,
  itsArticleHistory,
  setHistoryPopup,
  setFormData,
  editOpen,
  toggle_width_article,
}) => {
  let NowDate = new Datefunc();
  useEffect(() => {
    const leftPane = document.querySelector(".rightToolbar");
    const gutter = document.querySelector(".gufright");
    function resizer(e) {
      window.addEventListener("mousemove", mousemove);
      window.addEventListener("mouseup", mouseup);
      document.body.style.userSelect = "none";
      let prevX = e.x;
      const leftPanel = leftPane.getBoundingClientRect();

      function mousemove(e) {
        let newX = prevX - e.x;
        leftPane.style.width = newX + leftPanel.width + "px";
      }

      function mouseup() {
        document.body.style.userSelect = '';
        window.removeEventListener("mousemove", mousemove);
        window.removeEventListener("mouseup", mouseup);
      }
    }

    gutter.addEventListener("mousedown", resizer);
  }, []);

  let navigate = useNavigate();
  const goSection = () => {
    navigate("/wiki/section/" + ArticleData.section_id);
  };
  const goarticlehistory = (id, date) => {
    navigate("/wiki/history/article/" + id + "/" + date);
  };

  function goArticle(id) {
    navigate("/wiki/article/" + id);
  }

  const ToolbarTableItem = ({ tag }) => {
    return <div className="Tag">{tag}</div>;
  };

  const Version = ({ ver, length }) => {
    return (
      <tr
        // style={(ver.id===ArticleData.id&&ver.modification_date===ArticleData.modification_date)?{backgroundColor:'gray'}:{}}
        className="slct"
        onClick={() => {
          if (ver.content_type === "article") {
            if (ver.number === length) {
              goArticle(ver.id);
            } else {
              goarticlehistory(ver.id, ver.modification_date);
            }
          } else if (ver.content_type === "file") {
            if (ver.number === length) {
              navigate("/wiki/file/" + ver.id);
            } else {
              navigate(
                "/wiki/history/file/" + ver.id + "/" + ver.modification_date
              );
            }
          } else if (ver.content_type === "diagram") {
            if (ver.number === length) {
              navigate("/wiki/diagram/" + ver.id);
            } else {
              navigate(
                "/wiki/history/diagram/" + ver.id + "/" + ver.modification_date
              );
            }
          } else if (ver.content_type === "section") {
            setFormData((formData)=>({...formData, id: ver.id}))
            setHistoryPopup({ date: ver.modification_date, id: ver.id });
            setpopupActive(true);
          }
        }}
      >
        <td>{ver.number}</td>
        <td>{ver.autor_name}</td>
        <td>{NowDate.nowDateTime(ver.modification_date)}</td>
      </tr>
    );
  };
  function getTags(post) {
    if (post.tags !== undefined) {
      return [...post.tags.map((i) => <ToolbarTableItem tag={i} />)];
    } else {
      return "";
    }
  }
  function getVersions(versions) {
    if (versions !== undefined) {
      return [
        ...versions.map((i) => <Version ver={i} length={versions.length} />),
      ];
    } else {
    }
  }

  let ver = getVersions(articleHistory);
  let tgs = getTags(post);

  let [width, setWidth] = useState("18%");

  let btnIc;
  if (!RigtMenuShow) {
    btnIc = ">";
  } else {
    btnIc = "<";
  }
  return (
    <div
      style={
        RigtMenuShow ? { minWidth: "10px", width: "40px", display: "flex" } : { display: "flex" }
      }
      className="rightToolbar"
    >
      <div className={"gutter gufright"}></div>
      <button
        className="btnIcrement" style={{border:'none'}}
        onClick={() => toggle_width_article(setRightMenuShow, RigtMenuShow)}
      >
        {btnIc}
      </button>
      <div
        className="MainRightToolbar"
        style={RigtMenuShow ? { display: "none" } : {}}
      >
        <div className="toolbarTable">
          <h3>ТЭГИ ДОКУМЕНТА</h3>
          <div className="ToolbarTableStringTags">{tgs}</div>

          <div className="ToolbarTableString">
            <h3>ПОСЛЕДНИЕ ИЗМЕНЕНИЯ В ДОКУМЕНТЕ</h3>
            <table>
              <tr>
                <td>N</td>
                <td>Автор </td>
                <td>Дата</td>
              </tr>
              {ver !== undefined ? ver.reverse() : ""}
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListTollbar;
