import { useEffect, useState } from "react";
import "./treecontexmenu.css";
const TreeContextMenu = ({
  deleteBtn,
  createBtnArt,
  redactBtn,
  createSecBtn,
  showContext,
  setStowContext,
  coords,
  array,
  isart,
}) => {
  const [visible, setVisible] = useState(array);
  // const [arr, setArr] = useState([
  //   { name: "Удалить", fun: deleteBtn },
  //   { name: "Редактировать", fun: redactBtn },
  // ]);
  useEffect(() => {
    if (isart) {
      let newArr=[]
      newArr.push(array[0], array[array.length-1])
      setVisible(newArr);
    } else {
      setVisible(array);
    }
  }, [isart, coords]);
  function onClick(fun){
    fun()
    setStowContext(false)
  }
  return (
    <div
      className="TreeContextMenu"
      onMouseEnter={() => setStowContext(true)}
      onMouseLeave={() => setStowContext(false)}
      style={
        
           { left: coords[0], top: coords[1], display: showContext?"block":'none' }
          
      }
    >
      <ul>{[...visible.map((i) => <li onClick={()=>onClick(i.fun)}>{i.name}</li>)]}</ul>
    </div>
  );
};

export default TreeContextMenu;
