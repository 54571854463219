import "./MainOptProduct.css";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
const MainOptProductCard = ({
  data,
  popupActive,
  setPopupActive,
  selectedLineId,
  setSelectedLineId,
  setScrollId
}) => {
  const nav = useNavigate()
  const Slider = ({ data }) => {
    const [slideIndex, setSlideIndex] = useState(0);

    const nextSlide = () => {
      setSlideIndex((prevIndex) =>
        prevIndex === data.img.length - 1 ? 0 : prevIndex + 1
      );
    };

    const prevSlide = () => {
      setSlideIndex((prevIndex) =>
        prevIndex === 0 ? data.img.length - 1 : prevIndex - 1
      );
    };

    return (
      <div className="contentItemImage">
        <div className="slider" sliderId={data.id}>
         
          <img src={data.img[slideIndex]} alt=""></img>
          <div className="sliderline">
            {data.img.map((i, index) => (
              <div
                className="sliderlineItem"
                key={index}
                style={{
                  opacity: index === slideIndex ? "100%" : "0%",
                  width: 98 / data.img.length + "%",
                }}
              ></div>
            ))}
          </div>
        </div>
        <div className="sliderBued">
          <img src="/static/img/Frame 1418.svg" alt="sliderBued" />
        </div>
        <div className="sliderbtns">
          <button onClick={prevSlide} className="sliderPrew sliderBtn">
            <img src="/static/img/toleft.svg" alt="sliderPrew" />
          </button>
          <button onClick={nextSlide} className="sliderNext sliderBtn">
            <img src="/static/img/toright.svg" alt="sliderNext" />
          </button>
        </div>
      </div>
    );
  };
  // showSlides(slideIndex);
  const ref = useRef(null);
  const Accordeon = ({ title, data, long }) => {
    const [isOpen, setIsopen] = useState(false);

    function OpenAccordeon() {
      setIsopen(!isOpen);
    }
    function onChangeAppNumber(e) {
      e.target.value = e.target.value.replace(/[^+\d]/g, "");
      // setFormData((formData) => ({
      //   ...formData,
      //   [e.target.name]: e.target.value,
      // }));
    }
    useEffect(() => {
      let array = ref.current;
      let arr = array.querySelectorAll(".contentAccordeon");
      if (arr[0].style.display === "none") {
        arr[1].style.maxHeight = "100%";
      } else {
        arr[1].style.maxHeight = "80%";
      }
    }, [isOpen]);
    return (
      <>
        <div
          style={{
            width: "100%",
            padding: "2% 2% 0 0",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <span>{title}</span>{" "}
          <div className="accordeonBtn" onClick={OpenAccordeon}>
            <img
              src="/static/img/Rectangle 899.svg"
              className={isOpen ? "rotateClass" : ""}
              alt=""
            />
          </div>
        </div>
        <hr />
        <div
          className="contentAccordeon"
          style={
            isOpen
              ? { display: "block", overflowY: "scroll" }
              : { display: "none" }
          }
        >
          <p>{data}</p>
        </div>
      </>
    );
  };
  function tabPrice(str) {
    if (str.length > 3) {
      let f = str.slice(-4, str.length - 1);
      let t = str.slice(0, -3);
      return t + " " + f;
    }
  }
  let match = data.name.match(/^(.*\d+)\s(.*)$/);
  let beforeLastSpace;
  let afterLastSpace;
  if (match) {
    beforeLastSpace = match[1];
    afterLastSpace = match[2];
  }



  return (
    <div class="contentItem">
      <Slider data={data} />
      <div class="contentItemInfo">
        <div class="contentItemDscription">
          <ul>
            <div class="flexLi">
              <li>{afterLastSpace}</li>
              <li>{beforeLastSpace}</li>
            </div>
            <li>
              Метка:&nbsp;
              {data.product_label}
            </li>
            <div class="flexLi">
            <li >
              Оптовая цена:&nbsp;
              {tabPrice(`${data.price}`)} <img src="/static/img/rub.svg" className="rub" alt="" />
            </li>
            <li >
              Розничная цена:&nbsp;
              {tabPrice(`${data.price}`)} <img src="/static/img/rub.svg" className="rub" alt="" />
            </li>
            </div>
            <li> Размеры и количество</li>
            <li className="sizeBoxWrapper">
              {data.sizes.map((i) =>
                <div className="sizeBox">{i}</div> 
              )}
            </li>
            <li className="sizeBoxWrapper">
              {data.sizes.map((i) =>
                <div className="sizeBox"><input className="sizeBoxInput" type="text"/></div> 
              )}
            </li>
            
          </ul>
        </div>
        <div class="catrtBtnBlock">
          <button
            onClick={
                  ()=>{setScrollId(data.line_id)
                  nav('/opt/capsul_line')}
            }
            class="catrtBtn white"
            productId={data.id}
          >
            Перейти к капсуле
          </button>
          <button
            class="catrtBtn"
            productId={data.id}
          >
            Добавить в корзину
          </button>
        </div>
        <div className="borderTop">Итого: {0}<img src="/static/img/rub.svg" className="rub" alt="" /></div>
        <div
          className="ProductAccordeonWrapper"
          ref={ref}
          style={{ maxHeight: "42.5%" }}
        >
          <Accordeon title="ХАРАКТЕРИСТИКИ" data={data.characteristics} />
          <Accordeon title="ОПИСАНИЕ" data={data.description} />
        </div>
      </div>
    </div>
  );
};
export default MainOptProductCard;
