import "./Popup.css";
import { useState, useEffect, useRef } from "react";
import PopupSection from "./PopupTableBlock";

import PopupTwooLevel from "./PopupTwooLevel";
import GroupFinderViewer from "./GroupFinderViewer";
import Jwt_proxy from "../../API/Api";
// import config from "../../../config/config";
import TreeFirstItem from "../MainLeftToolbar/TreeFirstItem";
import { useNavigate } from "react-router-dom";
import Tree from "../MainLeftToolbar/Tree/Tree";

const PopupTree = ({
  popupActive,
  getSections,
  isRedaction,
  setIsRedaction,
  getLeftArr,
  setpopupActive,
  sectionWay,
  getSectionWay,
  historyPopup,
  changeName,
  formData,
  setFormData,
  setHistoryPopup,
  WayString,
  setWayString,
  GetsechionName,
  popupLewelTwooTreeSelected,
  popupLewelTwooTreeWay,
  showPopup,
  setShowPopup,
  getLeftTree,
  setItsarticleHistory,
  getFirstItemForTree,
  parentData,
  getThreePathData,
  toggleSelect,
  changePid,
  pidChanged,
  usrFoldTreeItem
}) => {
  const [groupsSel, setGroupsSel] = useState([]);

  let Arr = [];
  function noAccessed() {
    setpopupActive(false);
    alert("Отказано в доступе");
  }
  function addToFormData(e) {
    setFormData((formData) => ({
      ...formData,
      [e.target.name]: e.target.value,
    }));
  }

  let navigate = useNavigate();
  function changeSettings(e) {
    if (
      e.target.name === "parent_settings" &&
      formData.parent_settings == false
    ) {
      setFormData((formData) => ({ ...formData, group_restrictions: false }));
    }
    setFormData((formData) => ({
      ...formData,
      [e.target.name]: !formData[e.target.name],
    }));
  }

  let popupformdata = { id: formData.id, olddate: null };
  useEffect(() => {
    if (formData.id !== null && historyPopup == false) {
      Jwt_proxy(getSection);
    } else if (formData.id !== null && historyPopup !== false) {
      popupformdata = { id: historyPopup.id, olddate: historyPopup.date };
      Jwt_proxy(getSection);
    }
  }, []);
  useEffect(() => {}, [formData.parentSettings]);
  async function getSection() {
    let ip = localStorage.getItem("server_ip") + "/api/section/get";
    let token = localStorage.getItem("token");
    let res = await fetch(ip, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-token": token,
      },
      body: JSON.stringify(popupformdata),
    });
    if (res.ok) {
      let r = await res.json();
      setFormData({
        ...r[0],
        description: r[0].description === null ? "" : r[0].description,
      });

      if (r[1] !== null) {
        setGroupsSel(r[1]);
      }
      if (r[2].length > 0) {
        setWayString("/" + r[2][0]);
      }
      if (
        r[3] === true ||
        popupformdata.id === "10000000-0000-0000-0000-000000000000"
      ) {
        if (formData.id !== "10000000-0000-0000-0000-000000000000") {
          Jwt_proxy(() => getLeftArr(formData.id));
        } else {
          var event = new MouseEvent("dblclick", {
            view: window,
            bubbles: true,
            cancelable: true,
          });

          document.getElementById(formData.id).dispatchEvent(event);
          document.getElementById(formData.id).click();
        }
        noAccessed();
      }
    } else if (res.status === 401) {
      noAccessed();
    }
  }
  function onShow(o) {
    setShowPopup((showPopup) => ({
      ...showPopup,
      show: !showPopup.show,
      child: o,
    }));
  }
  const [oldpid, setoldpid] = useState(formData.pid);
  const [general, setIsGeneral] = useState(true);
  const oldref = useRef();

  useEffect(() => {
    if (!pidChanged) {
      setoldpid(formData.pid);
    }
  }, [formData]);
  const curoldpid = oldpid;
  async function popupPost() {
    let ip;
    let section_data = { section: formData, rights: groupsSel };
    if (isRedaction) {
      ip = localStorage.getItem("server_ip") + "/api/wiki/section/update";
    } else {
      ip = localStorage.getItem("server_ip") + "/api/wiki/section/create";
    }

    let token = localStorage.getItem("token");
    let res = await fetch(ip, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-token": token,
      },
      body: JSON.stringify(section_data),
    });
    if (res.ok) {
      let r = await res.json();

      var event = new MouseEvent("dblclick", {
        view: window,
        bubbles: true,
        cancelable: true,
      });

      if (
        curoldpid !== "00000000-0000-0000-0000-000000000000" &&
        curoldpid !== formData.pid
      ) {
        document.getElementById(curoldpid).dispatchEvent(event);
      }

      setoldpid(formData.pid);
      setIsRedaction(true);
      setFormData((formData) => ({ ...formData, id: r.id }));
      getFirstItemForTree();
      section_data = { section: formData, rights: groupsSel };
      setTimeout(() => {
        Jwt_proxy(() => getLeftArr(r.id));
      }, 150);
      getThreePathData(formData);

      navigate("/wiki/section/" + r.id);
    } else if (res.status === 400) {
      alert("Группа с таким названием уже существует в данном разделе");
    } else if (res.status === 402) {
      alert("У вас нет прав на создание разделов и статей в данной секции");
    } else if (res.status === 403) {
      alert("У вас нет прав на редактирование разделов в данной секции");
    } else if (res.status === 405) {
      alert("У вас нет прав на перенос данного раздела");
    } else if (res.status === 406) {
      alert("У вас нет прав в перенос данный раздел");
    } else if (res.status === 444) {
      alert("Установите ограничения");
    } else {
      alert("Ошибка");
    }
  }

  function createSection() {
    Jwt_proxy(popupPost);
  }

  let copy = Object.assign([], groupsSel);

  const FormTitle = ({ Title }) => {
    return <h2>{Title}</h2>;
  };

  let Title = "";
  if (isRedaction) {
    Title = " Редактирование группы статей";
  } else {
    Title = " Создание группы статей";
  }

  async function sectionUndelete() {
    let ip = localStorage.getItem("server_ip") + "/api/wiki/section/undelete";

    let token = localStorage.getItem("token");
    let res = await fetch(ip, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-token": token,
      },
      body: JSON.stringify({ id: formData.id }),
    });
    if (res.ok) {
      setFormData((formData) => ({ ...formData, deleted: false }));
      Jwt_proxy(() => getLeftArr(formData.id));
    }
  }

  return (
    <>
      <div className="grayfone">
        <div
          className="popupTree"
          style={popupActive ? { display: "block" } : { display: "none" }}
        >
          <form
            className="popupTreeBody"
            onSubmit={(e) => {
              e.preventDefault();
              createSection();
              setpopupActive(false);
              e.target.reset();
              setWayString([...[{ name: "", id: null }].map((i) => i.name)]);
            }}
          >
            <FormTitle Title={Title} />
            <div className="popupColumn">
              <div className="popupColumnSpan">
                <input
                  disabled={historyPopup !== false ? "disabled" : ""}
                  required="required"
                  className="popupSpan"
                  placeholder="Название"
                  name="name"
                  value={formData.name}
                  onChange={addToFormData}
                />
              </div>
              <div className="PopupPanelMenu">
                <span
                  className={general ? "ListBtn selectedList" : "ListBtn"}
                  onClick={() => setIsGeneral(true)}
                >
                  Общее
                </span>
                <span
                  className={!general ? "ListBtn selectedList" : "ListBtn"}
                  onClick={() => setIsGeneral(false)}
                >
                  Доступ
                </span>
              </div>
              <div
                style={{ display: general ? "block" : "none" }}
                className="popupPage"
              >
                <div className="popupColumnSpan">
                  <input
                    disabled={historyPopup !== false ? "disabled" : ""}
                    className="popupSpan Btn"
                    placeholder="Положение в иерархии"
                    name="pid"
                    value={WayString.replace("//", "/")}
                  />
                  <button
                    disabled={historyPopup !== false ? "disabled" : ""}
                    type="button"
                    className="popupBtn"
                    onClick={() => {
                      onShow(
                        <div className="TreeWrapper TreeWrapperSection">
                          <div
                            style={{
                              minHeight: "30vh",
                              display: "flex",
                              justifyContent: "space-between",
                              flexDirection: "column",
                            }}
                          >
                            {
                              <>
                                <span>Выберите родительскую группу</span>
                                <TreeFirstItem
                                  setItsarticleHistory={setItsarticleHistory}
                                  changePid={changePid}
                                  chuldren={popupLewelTwooTreeSelected}
                                  parentData={parentData}
                                  getThreePathData={getThreePathData}
                                  toggleSelect={toggleSelect}
                                />
                                {
                                  usrFoldTreeItem
                                  
                                }
                              </>
                            }
                            <span>Кликните дважды чтобы выбрать</span>
                          </div>
                        </div>
                      );
                      getSectionWay([
                        ...[{ name: "", id: null }].map((i) => i.name),
                      ]);
                    }}
                  >
                    В
                  </button>
                </div>

                <div className="popupColumn">
                  {formData.deleted ? (
                    <div className="h3check">
                      <input
                        type="checkbox"
                        disabled={historyPopup !== false ? "disabled" : ""}
                        name="deleted"
                        checked={formData.deleted ? "checked" : ""}
                        onClick={() => Jwt_proxy(sectionUndelete)}
                      />
                      <h3>Удалено</h3>
                    </div>
                  ) : null}
                  <div
                    className="h3check"
                    style={{
                      width: "30%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <h3 style={{ margin: "0 5px 0 5px" }}>Код</h3>
                    <input
                      maxlength="30"
                      disabled={historyPopup !== false ? "disabled" : ""}
                      className="popupSpan"
                      placeholder="Код"
                      name="code"
                      value={formData.code}
                      onChange={addToFormData}
                      type="text"
                    />
                  </div>
                  <div
                    className="h3check"
                    style={{
                      width: "30%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <h3 style={{ margin: "0 5px 0 5px" }}>Цвет в дереве</h3>
                    <input
                      disabled={historyPopup !== false ? "disabled" : ""}
                      className=""
                      name="title_font_color"
                      value={formData.title_font_color}
                      onChange={addToFormData}
                      type="color"
                    />
                  </div>

                  <div className="popupColumn">
                    <h3>Описание</h3>
                    <textarea
                      disabled={historyPopup !== false ? "disabled" : ""}
                      name="description"
                      id=""
                      value={formData.description}
                      cols="112"
                      rows="10"
                      onChange={addToFormData}
                    ></textarea>
                  </div>
                </div>
              </div>
              <div
                style={{ display: !general ? "block" : "none" }}
                className="popupPage"
              >
                {formData.pid !== null ? (
                  <div className="h3check" style={{ marginLeft: "5px" }}>
                    <input
                      type="checkbox"
                      disabled={historyPopup !== false ? "disabled" : ""}
                      name="parent_settings"
                      checked={formData.parent_settings ? "checked" : ""}
                      onClick={changeSettings}
                    />
                    <h3>Использовать родительские настройки прав</h3>
                  </div>
                ) : null}
                <PopupSelectsBody
                  setShowPopup={setShowPopup}
                  historyPopup={historyPopup}
                  changeSettings={changeSettings}
                  formData={formData}
                  parentSettings={formData.parent_settings}
                  onShow={onShow}
                  showPopup={showPopup}
                  copy={copy}
                  Arr={Arr}
                  groupsSel={groupsSel}
                  setGroupsSel={setGroupsSel}
                />
              </div>
            </div>
            {showPopup.show === true ? (
              <PopupTwooLevel onShow={onShow} child={showPopup.child} />
            ) : null}
            <div className="buttonsWrapper">
              <button
                className="wikiButton"
                type="submit"
                disabled={historyPopup !== false ? "disabled" : ""}
                onClick={(e) => {
                  e.preventDefault();
                  createSection();
                  setpopupActive(false);
                }}
              >
                Записать и закрыть
              </button>
              <button
                className="wikiButton"
                disabled={historyPopup !== false ? "disabled" : ""}
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  createSection();
                }}
              >
                Записать
              </button>
              <button
                className="wikiButton"
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  setpopupActive(false);
                  setHistoryPopup(false);
                }}
              >
                Закрыть
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

const PopupSelectsBody = ({
  parentSettings,
  Arr,
  onShow,
  groupsSel,
  setGroupsSel,
  showPopup,
  copy,
  changeSettings,
  formData,
  historyPopup,
  setShowPopup,
}) => {
  const group_restrictions = {
    name: "group_restrictions",
    title: "Ограничения по группам",
    func: changeSettings,
  };

  const [grouplist, setGrouplist] = useState([]);

  return (
    <div
      PopupSection
      style={!parentSettings ? { display: "block" } : { display: "none" }}
    >
      <PopupSection
        setShowPopup={setShowPopup}
        data={group_restrictions}
        formData={formData}
        historyPopup={historyPopup}
        setGrouplist={setGrouplist}
      >
        <GroupFinderViewer
          copy={copy}
          historyPopup={historyPopup}
          setGrouplist={setGrouplist}
          showPopup={showPopup}
          setShowPopup={setShowPopup}
          onShow={onShow}
          setGroupsSel={setGroupsSel}
          groupsSel={groupsSel}
          Arr={Arr}
          grouplist={grouplist}
        />
      </PopupSection>
      {/* <PopupSection data={popup2}>
        <UniversalTable data={tableData[1]} />
      </PopupSection> */}
    </div>
  );
};

export default PopupTree;
