import { useState, useRef } from "react";

const TestTableStr = ({ data, selected, setselected, onChange }) => {
  return (
    <tr
      className="TestTableStr"
      id={data.u}
      style={selected == data.id ? { backgroundColor: "#e5f4ff" } : {}}
      onClick={() => setselected(data.id)}
    >
      {data.d.map((n) => {
        return (
          <td
            trid={data.u}
            onBlur={onChange}
            contentEditable={selected === data.u ? "true" : "false"}
            name="v"
            style={{ fontSize: "30px" }}
          >
            {n.v}
          </td>
        );
      })}
    </tr>
  );
};
export default TestTableStr;
