import "./ThreeItem.css";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import Jwt_proxy from "src/components/API/Api";
import './ThreeItem.css'
const TreeItemFile = ({
  data,
  getParentId,
  toggleSelectArticle,
  getHistFile,
  setFirstArticleLoad,
  getThreePathData,
  setCoords,
  context,
  setStowContext
}) => {
 

  function showContextClick(e) {
    if (context) {
      e.preventDefault();
      
      if (document.getElementById(data.id).classList.contains('select')) {
        const x = e.clientX;
        const y = e.clientY;
        setCoords([x, y]);
        setStowContext(true);

        
      }
    }
  }
  return (
    <Link to={"file/" + data.id}>
      <div
        onClick={() => {
              getParentId(data.section_id);
              toggleSelectArticle(data.id)
              getThreePathData(data, true, 2)
              
        }}
        onContextMenu={showContextClick}
        className="TreeGroupItem"
        style={{color:data.title_font_color}}>
        <div className="TreeItem" id={data.id} >
          {"—"}
          {data.deleted ? (
            <img src="/static/img/Group 210_deleted.svg" alt="" />
          ) : (
            <img className="filesvg" src="/static/img/file.svg" alt="" />
          )}

          <span classList="strong">{data.name}</span>
        </div>
      </div>
    </Link>
  );
};

export default TreeItemFile;
