import "../LookBook/OptLookbook.jsx";
import capsul from "../capsul.js";
import Optlookbook from "../LookBook/OptLookbook.jsx";
import Jwt_proxy from "src/components/API/Api.jsx";
import OptPopup from "../opt_popup/OptPopup";
import Spinner from "./spinner.jsx";
import { useEffect, useRef, useState } from "react";

const LookBookPage = ({ setUrlPage, setPopupActive, popupActive,   setScrollId }) => {
  const [data, setData] = useState(capsul);
  const [isLoading, setIsLoading] = useState(true);
  async function getImages() {
    let ip = localStorage.getItem("server_ip") + "/api/opt/get_images";
    let token = localStorage.getItem("token");
    let res = await fetch(ip, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-token": token,
      },
    });
    if (res.ok) {
      let r = await res.json();
      setData(r);
    }
  }

  async function getPopupData(data, obj) {
    let ip = localStorage.getItem("server_ip") + "/api/opt/get_popup_data";
    let token = localStorage.getItem("token");
    let res = await fetch(ip, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-token": token,
      },
      body:JSON.stringify({ids:data.itemsids.map((i)=>(i.id))})
    });
    if (res.ok) {
      let r = await res.json();
      setPopupData(<OptPopup
      slidePopup={slidePopup}
      obj ={obj}
        setScrollId={setScrollId}
        popupActive={popupActive}
        setPopupActive={setPopupActive}
        data={{...data, itemsids:r}}
      />)
    }
  }


  function slidePopup(item, n){
    let index = data.findIndex(obj => obj.fullcapsulimg === item.fullcapsulimg);
    
    if (index+n>=data.length){
      index = -1
    }
    if (index+n<0){
      index= data.length
    }
  
    Jwt_proxy(()=>getPopupData(data[index+n], {i:data.length, n:index+n}))
  }
  const popupRef=useRef(null)

  const [popupData, setPopupData]=useState()
  
  useEffect(() => {
    setUrlPage("lookbook");
    Jwt_proxy(()=> getImages().then(() => {
      setIsLoading(false);
    }))
  }, []);
  return (
    
    <div className="CapsulWrapperPage">
      <div class="contentFlexWrapper  capsul">
        <div className="LookBookPageWrapper">
           {isLoading ? (
        <Spinner />
      ) : (data
            .sort((a, b) => b.itemsids.length - a.itemsids.length)
            .map((i, n) => (
              <Optlookbook
              getPopupData={getPopupData}
              obj = {{n:n, i: data.length}}
                setScrollId={setScrollId}
                popupActive={popupActive}
                setPopupActive={setPopupActive}
                data={i}
                ref={popupRef}
                setPopupData={setPopupData}
              />
            )))}
        </div>
      </div>
      {popupActive ? (
        popupData
      ) : null}
    </div>
  );
};
export default LookBookPage;
