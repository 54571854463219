const MainOptFooter = () =>{
    return(
        <footer>

<div class="navMenu bottom"><ul>
	<li class="navItem bottom" id="profile"><img src="/static/img/Asset 52.svg"/>Профиль</li>
	<li class="navItem  bottom"><img src="/static/img/Layer 2.svg"/>Выход</li>
</ul>
</div>
</footer>
    )
}
export default MainOptFooter