import WikiPage from "./components/wiki//Pages/wiki";
import "./App.css";
import Auth from "./components/AutorizacionForm/Auth.jsx";
import { useEffect, useState } from "react";
import { Router, Routes, Route, Navigate } from "react-router-dom";
import Loyout from "./components/wiki/Pages/Loyout";
import AdminPage from "./components/AdminPage/AdminPage";
import PrivetRoutes from "./components/PrivetRouters.jsx";
import Qrscanner from "./components/Qrcode/Qrcode";
import ApplicationForPersonnel from "./components/HRTable/HRTable";
import ApplicationForPersonelForm from "./components/HRTable/ApplicatuonForPersonalForm/ApplicatuonForPersonalForm";
import OptMainWidjet from "./components/opt_MReason/Opt_Main_Widjet/Opt_Main_Widjet";
import PersonFilesPage from "./components/PersonalFiles/Pages/PersonFilesPage";
import TestTables from "./components/testTables/testTables";
import ParserPage from "./components/Parser/Parser";




function App() {

  const [applicationData, setApplicationData] = useState();
  const [applicationId, setApplicationId] = useState();
  const [formData, setFormData] = useState({
    date: new Date(),
    city_id: null,
    status_id: null,
    desired_deadline: null,
    actual_execution_applicant: null,
    actual_execution_hr: null,
    applicant: null,
    job_position_id: null,
    department_id: null,
    department: null,
    vacancy_name: null,
    desired_quantity: 1,
    education_specialty_description: null,
    years_of_experience: null,
    age_from: null,
    age_to: null,
    computer_skills: null,
    subordination:null,
    material_liability:false,
    fare_payment:false,
    quantity_of_employees_under_supervision:0,
    communication_payment:false,
    business_trips_percentage_of_working_time:0,
    job_description_attached:false,
    base_vacancy_opening_id:1,
    interview_start_with:null,
    sex: "Не важен",
    salary_for_probation: 0,
    salary_after_probation: 0,
    job_description: null,
    work_schedule_id: null,
    work_schedule_description: null,
    interview_description: null,
    interview_convenient_time: null,
    mandatory_requirements: null,
    desirable_requirements: null,
    business_personal_qualities: null,
    driver_license: false,
    driver_license_category: null,
    languages: false,
    additional_requirements: null,
    workplace_availability: null,
    workplace_missing_description: null,
    vacancies_to_work:0
  });

  const data = {
    columns: [
      {
        id: 1,
        name: "id",
        accessor: "id",
        display_name: "id",
        desc: "id of the current string",
        head_color: "red",
        header_font_color: "black",
        cell_color: "white",
        cell_font_color: { R: 123, B: 255, G: 0 },
      },
      {
        id: 2,
        name: "FullName",
        accessor: "name",
        display_name: "Full name",
        desc: "Name of vacancy",
        header_color: "blue",
        header_font_color: "black",
        cell_color: "lightgreen",
        cell_font_color: { R: 123, B: 0, G: 0 },
      },
      {
        id: 3,
        name: "NetSalary",
        accessor: "salary",
        display_name: "Net salary",
        desc: "Value of salary",
        header_color: "blue",
        header_font_color: "black",
        cell_color: "lightgreen",
        cell_font_color: { R: 123, B: 0, G: 0 },
      },
    ],
    data: [
      { id: 123, name: "Заправщик картриджей", salary: 123456.0 },
      { id: 123, name: "Заправщик картриджей", salary: 123456.0 },
    ],
  };
  
const [link, setLink]=useState(localStorage.getItem('link')===null?'/login':localStorage.getItem('link'))

  return (
    <Routes>
    <Route
        path="/employees"
        element={
            <TestTables/>
        }
      />
    <Route
        path="/login"
        element={
            <Auth link={link} setLink={setLink}/>
        }
      />
      <Route path="/" element={<Navigate to={localStorage.getItem('link')===null?'/login':localStorage.getItem('link')} />} />
      <Route
        path="/wiki/*"
        element={
          <PrivetRoutes link={link}>
            <WikiPage />
          </PrivetRoutes>
        }
      />
      {/* <Route
        path="/person_files/*"
        element={
          <PrivetRoutes link={link}>
            <PersonFilesPage />
          </PrivetRoutes>
        }
      /> */}
      <Route
        path="/admin"
        element={
          <PrivetRoutes link={link}>
            <AdminPage />
          </PrivetRoutes>
        }
      />
      <Route
        path="/opt*"
        element={
          <PrivetRoutes link={link}>
           <OptMainWidjet link={link}/>
          </PrivetRoutes>
        }
      />
      <Route
        path="/qrcode"
        element={
          <PrivetRoutes link={link}>
            <Qrscanner />
          </PrivetRoutes>
        }
      />
      <Route
        path="/hr/*"
        element={
          <PrivetRoutes link={link}>
            <ApplicationForPersonnel />
          </PrivetRoutes>
        }
      />
      <Route
        path="/hr/list/*"
        element={
          <PrivetRoutes link={link}>
            <ApplicationForPersonelForm data={formData} />
          </PrivetRoutes>
        }
      />
      <Route
        path="/hr/list/:Id*"
        element={
          <PrivetRoutes link={link}>
            <ApplicationForPersonelForm data={[]} />
          </PrivetRoutes>
        }
      />
      <Route
        path="/parser/*"
        element={
          <PrivetRoutes link={link}>
            <ParserPage />
          </PrivetRoutes>
        }
      />
    </Routes>
  );
}

export default App;
