// let socket = new WebSocket("wss://10.65.1.1");

// socket.onopen = function(e) {
//   alert("[open] Соединение установлено");
//   alert("Отправляем данные на сервер");
//   socket.send("Меня зовут Джон");
// };

// socket.onmessage = function(event) {
//   alert(`[message] Данные получены с сервера: ${event.data}`);
// };

// socket.onclose = function(event) {
//   if (event.wasClean) {
//     alert(`[close] Соединение закрыто чисто, код=${event.code} причина=${event.reason}`);
//   } else {
//     // например, сервер убил процесс или сеть недоступна
//     // обычно в этом случае event.code 1006
//     alert('[close] Соединение прервано');
//   }
// };

// socket.onerror = function(error) {
//   alert(`[error]`);
// };










let refresh=localStorage.getItem('refresh_token');
const config ={
   "ip":"http://10.65.102.127:5000",
  //  "ip":"http://intranet.m-reason.net:88",
	// 'ip': process.env.REACT_APP_BACKEND_URL,
    "ReRefresh":{
        "method": "GET",
        "headers":{
          "Content-Type": 'application/json',
         "x-token": `${refresh}`
        }
      },


}
export default config
