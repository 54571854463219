import { useEffect, useRef, useState } from "react";
// import config from "../../../config/config";
import { useParams } from "react-router-dom";
import { Document, Page } from "react-pdf";
import "../WikiArticteViever/WikiArticteViever.css";
import { pdfjs } from "react-pdf";
import Jwt_proxy from "../../API/Api";
import { useNavigate } from "react-router-dom";
import Datefunc from "../DateFunc";

import ArticleToolbar from "../articleToolbar/articleToolbar";
pdfjs.GlobalWorkerOptions.workerSrc = `pdfjs-dist/build/pdf.worker.min.js`;

const Articlepage = ({
  firstArticleLoad,
  setFirstArticleLoad,
  filePdf,
  setFilePdf,
  setArticleData,
  sectionWay,
  RigtMenuShow,
  getLeftArr,
  toggleEdit,
  toggleSelectArticle,
  setHistoryArticleData,
  ArticleData,
  LeftMenuShow,
  ishistory,
  setItsarticleHistory,
  getSections,
  redArticle,
  editOpen,
  itsArticleHistory,
  setRedArticle,
  onRedact,
  GoArticleToRedact,
  getLeftTree,
  expanded_toolbar,
  red,
  setPidChanged,
  pidChanged,
}) => {
  const [toolbar, settoolbar] = useState(null);
  const { Id, date } = useParams();

  function article_resize(a) {
    let html = document.querySelector(".article_block");
    let pdf = document.querySelector(".react-pdf__Page__canvas");
    let article;
    let height = 0;
    if (html !== null) {
      article = html;
    } else {
      article = pdf;
      height = article.offsetHeight;
      pdf = document.querySelector(".react-pdf__Document");
    }
    let width = article.offsetWidth;
    if (
      (!RigtMenuShow && width >= 1600 && a > 0) ||
      (!LeftMenuShow && width >= 1060 && a > 0)
    ) {
      return;
    } else {
      if (width + a > 800) {
        article.style.width = width + a + "px";
        if (pdf !== null) {
          pdf.style.width = width + a + "px";
        }
      }
      article.style.height = height + a * 2 + "px";
      var allElements = article.querySelectorAll("*");
      for (let i = 0; i < allElements.length; i++) {
        var fontSize = window
          .getComputedStyle(allElements[i])
          .getPropertyValue("font-size");
        var currentSize = parseFloat(fontSize);

        var newSize;
        if (a < 0) {
          if (currentSize >= 10) {
            newSize = currentSize - 2;
          } else {
            break;
          }
        } else if (currentSize >= 8) {
          newSize = currentSize + 2;
        }

        allElements[i].style.fontSize = newSize + "px";
      }
    }
  }
  let daT = new Datefunc();
  function MyPDF() {
    const [numPages, setNumPages] = useState(null);

    function onDocumentLoadSuccess({ numPages }) {
      setNumPages(numPages);
    }
    let wd = (window.innerWidth / 100) * 50;

    return (
      <div
        ref={ref}
        style={{
          width: "100%",
          margin: "10px 5px",
          display: "flex",
          justifyContent: "center",
          backgroundColor: "rgb(236, 236, 236)",
          borderRadius: "7px",
          height: "100%",
        }}
      >
        <Document file={filePdf} onLoadSuccess={onDocumentLoadSuccess}>
          {Array(numPages)
            .fill()
            .map((_, i) => (
              <Page width={wd} pageNumber={i + 1}></Page>
            ))}
        </Document>
      </div>
    );
  }
  let string = [];
  for (let i = 0; i < sectionWay.length; i++) {
    string.push(" " + sectionWay[i] + " ");
    string.push(<img src="/static/img/Rectangle 899.svg" />);
  }

  let navigate = useNavigate();
  const goWiki = () => {
    navigate("/wiki");
  };
  let formdata;
  if (ishistory === true) {
    formdata = { id: Id, historydate: date };
  } else if (ishistory === false) {
    formdata = { id: Id, historydate: null };
  }
  const GetArticleBodyType = async () => {
    let token = localStorage.getItem("token");

    let res = await fetch(
      localStorage.getItem("server_ip") + "/api/wiki/get_article_body_type",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-token": token,
        },
        body: JSON.stringify(formdata),
      }
    );
    if (res.ok) {
      let r = await res.json();
      setArticleData(r);

      if (ishistory === false && !r.deleted) {
        settoolbar(
          <ArticleToolbar
            firstArticleLoad={firstArticleLoad}
            setFirstArticleLoad={setFirstArticleLoad}
            setPidChanged={setPidChanged}
            pidChanged={pidChanged}
            red={red}
            expanded_toolbar={false}
            getSections={getSections}
            redArticle={true}
            ArticleData={r}
            getLeftArr={getLeftArr}
            setArticleData={setArticleData}
            editOpen={editOpen}
            itsArticleHistory={itsArticleHistory}
            setRedArticle={setRedArticle}
            onRedact={onRedact}
            GoArticleToRedact={GoArticleToRedact}
            reff={ref}
          />
        );
      } else {
        settoolbar(null);
      }
      if (r.section_id !== null && firstArticleLoad) {
        if (!ishistory) {
          Jwt_proxy(() => getLeftArr(r.section_id, Id));
        } else {
          Jwt_proxy(() => getLeftArr(r.section_id, Id, true));
        }
      }

      if (r.body_type === 1) {
        let result = await fetch(
          localStorage.getItem("server_ip") + "/api/wiki/get_article_pdf",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "x-token": token,
            },
            body: JSON.stringify(formdata),
          }
        );
        if (result.ok) {
          await result.blob().then((blob) => {
            let file = window.URL.createObjectURL(blob);
            setTimeout(() => window.URL.revokeObjectURL(file), 10000);
            setFilePdf((filePdf = file));
            setData(<MyPDF />);
          });
        } else if (result.status === 401) {
          alert("отказано в доступе");
          goWiki();
        }
      }
      if (r.body_type === 2) {
        let resultHtml = await fetch(
          localStorage.getItem("server_ip") + "/api/wiki/get_article_html",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "x-token": token,
            },
            body: JSON.stringify(formdata),
          }
        );
        if (resultHtml.ok) {
          let html = await resultHtml.json();
          setData(
            <div
              style={{ width: "50vw", minHeight: "120vh" }}
              className="article_block"
            >
              <div
                ref={ref}
                className="HTMLPage"
                style={{ userSelect: "none" }}
              >
                <h1>{html.title}</h1>
                <h4 style={{ textAlign: "center" }}>
                  {"Автор: " + html.author}
                </h4>
                <h4 style={{ textAlign: "center" }}>
                  {"Идентификатор: " + html.id}
                </h4>
                <h4 style={{ textAlign: "center" }}>
                  {"Версия от " + daT.nowDateTime(html.date)}
                </h4>
                <h4 style={{ textAlign: "center" }}>
                  {"Тип: " + html.wiki_article_type}
                </h4>
               {html.code!==null? <h4 style={{ textAlign: "center" }}>
                  {"Код: " + html.code}
                </h4>:null}
                <p>{html.abstruct}</p>
                {
                  <div
                    dangerouslySetInnerHTML={{ __html: html.tex_data }}
                  ></div>
                }
              </div>
            </div>
          );
        } else if (resultHtml.status === 401 || resultHtml.status === 404) {
          alert("отказано в доступе");
          goWiki();
        }
      }
    } else if (res.status === 404) {
      alert("Статья не найдена");
      Jwt_proxy(getLeftTree);
      navigate("/wiki");
    }
  };

  async function articleUndelete() {
    let ip = localStorage.getItem("server_ip") + "/api/wiki/article/undelete";

    let token = localStorage.getItem("token");
    let res = await fetch(ip, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-token": token,
      },
      body: JSON.stringify({ id: ArticleData.id }),
    });
    if (res.ok) {
      setArticleData((ArticleData) => ({ ...ArticleData, deleted: false }));
      Jwt_proxy(() => getLeftArr(ArticleData.section_id, ArticleData.id));
      toggleSelectArticle(Id);
      settoolbar(
        <ArticleToolbar
          firstArticleLoad={firstArticleLoad}
          setFirstArticleLoad={setFirstArticleLoad}
          setPidChanged={setPidChanged}
          pidChanged={pidChanged}
          expanded_toolbar={false}
          getSections={getSections}
          redArticle={true}
          ArticleData={ArticleData}
          getLeftArr={getLeftArr}
          setArticleData={setArticleData}
          editOpen={editOpen}
          itsArticleHistory={itsArticleHistory}
          setRedArticle={setRedArticle}
          onRedact={onRedact}
          GoArticleToRedact={GoArticleToRedact}
          reff={ref}
        />
      );
    }
  }
  const [Data, setData] = useState();

  useEffect(() => {
    if (ishistory === false) {
      setHistoryArticleData(null);
      setItsarticleHistory(false);
    } else {
      setItsarticleHistory(true);
    }
    toggleEdit(true);
    Jwt_proxy(GetArticleBodyType);
  }, [Id, date]);

  let ref = useRef();
  return (
    <>
      <div
        style={{ maxHeight: "83vh" }}
        className="article_wrapper_block"
      >
        {ArticleData.deleted ? (
          <div className="h3check">
            <input
              type="checkbox"
              name="deleted"
              checked={ArticleData.deleted ? "checked" : ""}
              onChange={() => Jwt_proxy(articleUndelete)}
            />
            <h3>Удалено</h3>
          </div>
        ) : null}
        {itsArticleHistory === false ? (
          <h6> {[...string.slice(0, -3)]}</h6>
        ) : <h6>{''}</h6>}
        {toolbar !== null ? (
          toolbar
        ) : (
          <div
            className="ArticleToolbar"
            style={{
              height: "calc(10px + 20 * (100vw / 1920))",
              padding: "20px 0",
              border: "none",
              width: "100%",
            }}
          ></div>
        )}
        <div
          style={{
            position: "absolute",
            top: "2.5%",
            left: "50%",
            transform: "translate(-50%)",
          }}
        >
          <button onClick={() => article_resize(50)} className="btnIcrement">
            +
          </button>
          <button onClick={() => article_resize(-50)} className="btnIcrement">
            -
          </button>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            overflowX: "scroll",
            backgroundColor: "rgb(236, 236, 236)",
            borderRadius: "7px",
            height: "100%",
          }}
        >
          {Data}
        </div>
      </div>
    </>
  );
};

export default Articlepage;
