import Jwt_proxy from "../API/Api";

const PopupSelect = ({ children, OnClickFetch, setPopupActive }) => {
    return (
      <div className="PopupSelect">
        <div style={{ maxHeight: "30vh", overflow: "scroll" }}>{children}</div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            margin: "20px",
          }}
        >
          <button
            className="wikiButton min"
            onClick={() => Jwt_proxy(OnClickFetch)}
          >
            Записать
          </button>
          <button
            className="wikiButton min"
            onClick={() => {
              setPopupActive(false);
              Jwt_proxy(OnClickFetch);
            }}
          >
            Записать и закрыть
          </button>
          <button
            className="wikiButton min"
            onClick={() => {
              setPopupActive(false);
            }}
          >
            Закрыть
          </button>
        </div>
      </div>
    );
  };
export default PopupSelect