const capsuls = [
  [
    {
      id: 1,
      name: "Бежевая",
      images: [
        "/static/img/winter2024/Rectangle 551.png",
        "/static/img/winter2024/Rectangle 1171.png",
        "/static/img/winter2024/Rectangle 1172.png",
        "/static/img/winter2024/Rectangle 1171.png",
        "/static/img/winter2024/Rectangle 1173.png",
        "/static/img/winter2024/Rectangle 1174.png",

        "/static/img/winter2024/Rectangle 551.png",
        "/static/img/winter2024/Rectangle 1171.png",
        "/static/img/winter2024/Rectangle 1172.png",
        "/static/img/winter2024/Rectangle 1171.png",
        "/static/img/winter2024/Rectangle 1173.png",
        "/static/img/winter2024/Rectangle 1174.png",
      ],
    },
    {
      id: 2,
      name: "Бежевая",
      images: [
        "/static/img/winter2024/Rectangle 551.png",
        "/static/img/winter2024/Rectangle 1171.png",
        "/static/img/winter2024/Rectangle 1172.png",
        "/static/img/winter2024/Rectangle 1171.png",
        "/static/img/winter2024/Rectangle 1173.png",
        "/static/img/winter2024/Rectangle 1174.png",

        "/static/img/winter2024/Rectangle 551.png",
        "/static/img/winter2024/Rectangle 1171.png",
        "/static/img/winter2024/Rectangle 1172.png",
        "/static/img/winter2024/Rectangle 1171.png",
        "/static/img/winter2024/Rectangle 1173.png",
        "/static/img/winter2024/Rectangle 1174.png",
      ],
    },
    {
      id: 3,
      name: "Бежевая",
      images: [
        "/static/img/winter2024/Rectangle 551.png",
        "/static/img/winter2024/Rectangle 1171.png",
        "/static/img/winter2024/Rectangle 1172.png",
        "/static/img/winter2024/Rectangle 1171.png",
        "/static/img/winter2024/Rectangle 1173.png",
        "/static/img/winter2024/Rectangle 1174.png",

        "/static/img/winter2024/Rectangle 551.png",
        "/static/img/winter2024/Rectangle 1171.png",
        "/static/img/winter2024/Rectangle 1172.png",
        "/static/img/winter2024/Rectangle 1171.png",
        "/static/img/winter2024/Rectangle 1173.png",
        "/static/img/winter2024/Rectangle 1174.png",
      ],
    },
    {
      id: 4,
      name: "Бежевая",
      images: [
        "/static/img/winter2024/Rectangle 551.png",
        "/static/img/winter2024/Rectangle 1171.png",
        "/static/img/winter2024/Rectangle 1172.png",
        "/static/img/winter2024/Rectangle 1171.png",
        "/static/img/winter2024/Rectangle 1173.png",
        "/static/img/winter2024/Rectangle 1174.png",

        "/static/img/winter2024/Rectangle 551.png",
        "/static/img/winter2024/Rectangle 1171.png",
        "/static/img/winter2024/Rectangle 1172.png",
        "/static/img/winter2024/Rectangle 1171.png",
        "/static/img/winter2024/Rectangle 1173.png",
        "/static/img/winter2024/Rectangle 1174.png",
      ],
    },
    {
      id: 5,
      name: "Бежевая",
      images: [
        "/static/img/winter2024/Rectangle 551.png",
        "/static/img/winter2024/Rectangle 1171.png",
        "/static/img/winter2024/Rectangle 1172.png",
        "/static/img/winter2024/Rectangle 1171.png",
        "/static/img/winter2024/Rectangle 1173.png",
        "/static/img/winter2024/Rectangle 1174.png",

        "/static/img/winter2024/Rectangle 551.png",
        "/static/img/winter2024/Rectangle 1171.png",
        "/static/img/winter2024/Rectangle 1172.png",
        "/static/img/winter2024/Rectangle 1171.png",
        "/static/img/winter2024/Rectangle 1173.png",
        "/static/img/winter2024/Rectangle 1174.png",
      ],
    },
  ],
  [
    {
      id: 1,
      name: "Бежевая",
      images: [
        "/static/img/winter2024/pipl/Rectangle 1171.png",
        "/static/img/winter2024/pipl/Rectangle 1172.png",
        "/static/img/winter2024/pipl/Rectangle 1171.png",
        "/static/img/winter2024/pipl/Rectangle 1173.png",
        "/static/img/winter2024/pipl/Rectangle 1174.png",

        "/static/img/winter2024/pipl/Rectangle 1171.png",
        "/static/img/winter2024/pipl/Rectangle 1172.png",
        "/static/img/winter2024/pipl/Rectangle 1171.png",
        "/static/img/winter2024/pipl/Rectangle 1173.png",
        "/static/img/winter2024/pipl/Rectangle 1174.png",
      ],
    },
    {
      id: 2,
      name: "Бежевая",
      images: [
        "/static/img/winter2024/pipl/Rectangle 1171.png",
        "/static/img/winter2024/pipl/Rectangle 1172.png",
        "/static/img/winter2024/pipl/Rectangle 1171.png",
        "/static/img/winter2024/pipl/Rectangle 1173.png",
        "/static/img/winter2024/pipl/Rectangle 1174.png",

        "/static/img/winter2024/pipl/Rectangle 1171.png",
        "/static/img/winter2024/pipl/Rectangle 1172.png",
        "/static/img/winter2024/pipl/Rectangle 1171.png",
        "/static/img/winter2024/pipl/Rectangle 1173.png",
        "/static/img/winter2024/pipl/Rectangle 1174.png",
      ],
    },
    {
      id: 3,
      name: "Бежевая",
      images: [
        "/static/img/winter2024/pipl/Rectangle 1171.png",
        "/static/img/winter2024/pipl/Rectangle 1172.png",
        "/static/img/winter2024/pipl/Rectangle 1171.png",
        "/static/img/winter2024/pipl/Rectangle 1173.png",
        "/static/img/winter2024/pipl/Rectangle 1174.png",

        "/static/img/winter2024/pipl/Rectangle 1171.png",
        "/static/img/winter2024/pipl/Rectangle 1172.png",
        "/static/img/winter2024/pipl/Rectangle 1171.png",
        "/static/img/winter2024/pipl/Rectangle 1173.png",
        "/static/img/winter2024/pipl/Rectangle 1174.png",
      ],
    },
    {
      id: 4,
      name: "Бежевая",
      images: [
        "/static/img/winter2024/pipl/Rectangle 1171.png",
        "/static/img/winter2024/pipl/Rectangle 1172.png",
        "/static/img/winter2024/pipl/Rectangle 1171.png",
        "/static/img/winter2024/pipl/Rectangle 1173.png",
        "/static/img/winter2024/pipl/Rectangle 1174.png",

        "/static/img/winter2024/pipl/Rectangle 1171.png",
        "/static/img/winter2024/pipl/Rectangle 1172.png",
        "/static/img/winter2024/pipl/Rectangle 1171.png",
        "/static/img/winter2024/pipl/Rectangle 1173.png",
        "/static/img/winter2024/pipl/Rectangle 1174.png",
      ],
    },
    {
      id: 5,
      name: "Бежевая",
      images: [
        "/static/img/winter2024/pipl/Rectangle 1171.png",
        "/static/img/winter2024/pipl/Rectangle 1172.png",
        "/static/img/winter2024/pipl/Rectangle 1171.png",
        "/static/img/winter2024/pipl/Rectangle 1173.png",
        "/static/img/winter2024/pipl/Rectangle 1174.png",

        "/static/img/winter2024/pipl/Rectangle 1171.png",
        "/static/img/winter2024/pipl/Rectangle 1172.png",
        "/static/img/winter2024/pipl/Rectangle 1171.png",
        "/static/img/winter2024/pipl/Rectangle 1173.png",
        "/static/img/winter2024/pipl/Rectangle 1174.png",
      ],
    },
  ],
];

export default capsuls;
