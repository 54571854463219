



class Datefunc {
     nowDate(dat) {
      
        let today = new Date(dat);
        let d = today.getDate();
        let m = today.getMonth() + 1;
        let y = today.getFullYear();
        d = d > 9 ? d : "0" + d;
        m = m > 9 ? m : "0" + m;
     
        let r = `${+y}-${+m}-${+d}`;
        return r;
      }

      nowDateI(dat) {
        let today = new Date(dat);
        let d = today.getDate();
        let m = today.getMonth() + 1;
        let y = today.getFullYear();
        d = d > 9 ? d : "0" + d;
        m = m > 9 ? m : "0" + m;
        let r = `${y}-${m}-${d}`;
        return r;
      }
      
       nowDateInput(dat) {
        let today = new Date(dat);
        let d = today.getDate();
        let m = today.getMonth() + 1;
        let y = today.getFullYear();
        d = d > 9 ? d : "0" + d;
        m = m > 9 ? m : "0" + m;
        let r = `${+d}.${+m}.${+y}`;
        return r;
      }
      nowDateTime(dat) {
        let today = new Date(dat);
        let d = today.getDate();
        let m = today.getMonth() + 1;
        let y = today.getFullYear();
        let h = today.getHours();
        let min = today.getMinutes();
        let sec = today.getSeconds();
        d = d > 9 ? d : "0" + d;
        m = m > 9 ? m : "0" + m;
        h = h > 9 ? h : "0" + h;
        min = min > 9 ? min : "0" + min;
        sec = sec > 9 ? sec : "0" + sec;
        let r = `${y}-${m}-${d}  ${h}:${min}:${sec}`;
        return r;
    }
    nowDateTimeToUrl(dat) {
      let today = new Date(dat);
      let d = today.getDate();
      let m = today.getMonth() + 1;
      let y = today.getFullYear();
      let h = today.getHours();
      let min = today.getMinutes();
      let sec = today.getSeconds();
      let mill = today.getMilliseconds()
      d = d > 9 ? d : "0" + d;
      m = m > 9 ? m : "0" + m;
      h = h > 9 ? h : "0" + h;
      min = min > 9 ? min : "0" + min;
      sec = sec > 9 ? sec : "0" + sec;
      mill = mill > 9 ? mill : "0" + sec;
      let r = `${y}-${m}-${d}.${h}:${min}:${sec}:${mill}`;
      return r;
  }
    stringToDate(str) {
      
        // Разделим строку на дату и время
        const [datePart, timePart] = str.split('.');
    
        // Разделим дату на год, месяц и день
        const [year, month, day] = datePart.split('-').map(Number);
    
        // Разделим время на часы, минуты и секунды
        const [hours, minutes, seconds, milliseconds] = timePart.split(':').map(Number);
    
        // Месяцы в JavaScript начинаются с 0, поэтому вычтем 1 из месяца
        const jsMonth = month - 1;
    
        // Создадим объект Date
        const resultDate = new Date(year, jsMonth, day, hours, minutes, seconds, milliseconds);
    
        return new Date(resultDate);
    
    }
}
export default Datefunc