import SectionPageItem from "./sectionPageItem";
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Jwt_proxy from "../../../API/Api";

const FilePage = ({
  getLeftArr,
  firstArticleLoad,
  setFirstArticleLoad,
  toggleSelect,
  setItsarticleHistory,
  toggleEdit,
  nameAndPathCreate,
  seteditOpen,
  secundefined,
  ishistory,
  getHistFile,
}) => {
  const { id, date } = useParams();
  let navigate = useNavigate();
  const goWiki = () => {
    navigate("/wiki");
  };
  const [data, setData] = useState({});

  async function getfile() {
    let token = localStorage.getItem("token");
    let ip = localStorage.getItem("server_ip");
    let res = await fetch(ip + "/api/wiki/file_get/for_section", {
      headers: {
        "Content-Type": "application/json",
        "x-token": token,
      },
      method: "POST",
      body: JSON.stringify({ id: id, date: date }),
    });
    if (res.ok) {
      let r = await res.json();
      setData(r);
      if (r.section_id !== null && firstArticleLoad) {
        if (date===undefined) {
          Jwt_proxy(() => getLeftArr(r.section_id, id));
        } else {
          Jwt_proxy(() => getLeftArr(r.section_id, id, true));
        }
      }
      Jwt_proxy(() => getHistFile(id));
      setFirstArticleLoad(false);
    }
    else if (res.status===404){
      navigate('/wiki')
    }
  }

  seteditOpen(true);
  useEffect(() => {
      Jwt_proxy(getfile);
  }, [id, date]);

  return (
    <div style={{ width: "40vw", display: "flex", justifyContent: "center" }}>
      {
        <SectionPageItem
          date={date}
          firstArticleLoad={firstArticleLoad}
          setFirstArticleLoad={setFirstArticleLoad}
          getLeftArr={getLeftArr}
          toggleSelect={toggleSelect}
          content_type="file"
          data={data}
        />
      }
    </div>
  );
};
export default FilePage;
