import React, { useState } from "react";
import HeaderMenu from "./MainHeaderMenu/MainHeaderMenu";
import "./MainHeader.css";
import { useNavigate } from "react-router-dom";
// import config from "/config/config";

const Header = ({}) => {
  let [menu, setMenu] = useState(false);
  function activMenu() {
    setMenu((true));
    setTimeout(() => {
      setMenu((false));
    }, 1000);
  }
 async function Logout(){
  localStorage.setItem('token', 'null');
  localStorage.setItem('refresh_token', 'null'); 
  localStorage.setItem('username', 'null'); 
  localStorage.setItem('logged', 'false'); 
  let ip = localStorage.getItem('server_ip')
  let token =localStorage.getItem('token')
    let res =await fetch( ip+'/users/logout',{
        method: "GET",
        headers: {
          "Content-Type": "application/json",
         "x-token": token,
        }
    }
    )
    if(res.ok){
      goEdit()
    }
    
  }
  let navigate = useNavigate();
  const goEdit = () => {
    navigate("/login");
  };
  return (
    <>
      <header>
        <div className="headernavbar">
          <div className="hedLeft">
          
            <div className="brg" onClick={activMenu}>
              <img src="/static/img/Frame 1194.svg" alt="" />
              {<HeaderMenu menu={menu} />}
              МЕНЮ
            </div>
            
          
            <div className="wiki logo">
              <img src="/static/img/logo-mreason.svg" alt="" />
            </div>
          </div>
          <div className="navMenu top wiki">
            <ul>
              <li className="navItem" id="profile">
                <img src="/static/img/Asset 52.svg" alt="" />
                {localStorage.getItem('username')}
              </li>
              <li className="navItem" onClick={Logout}>
                <img src="/static/img/Layer 2.svg" alt="" />
                Выход
              </li>
            </ul>
          </div>
          <div className="collectionMobile">
            <h1>ЛЕТО 2023</h1>
          </div>
        </div>
      </header>
    </>
  );
};
export default Header;
