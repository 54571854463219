import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";


// let width = window.innerWidth;
// let body = document.querySelector('body')
// let scrinWidth = document.querySelectorAll("meta");
// if (width > 900) {
//   console.log(width)
//   scrinWidth.forEach((i) => {
//     if (i.name === "viewport") {
//       i.content = "width=1920";
//     }
//     body.style.minWidth='1920px'
//   });
// }

//
const root = ReactDOM.createRoot(document.querySelector(".root"));
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

reportWebVitals();
