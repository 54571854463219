let capsul = [
  {
    fullcapsulimg: "/static/img/winter2024/Rectangle 1173(1).png",
    itemsids: [
      {
        id: "1",
       name: "14.9316.T1862.16 A2.22 Платье",
        img: [
          "/static/img/winter2024/Rectangle 1199.png",
          "/static/img/winter2024/Rectangle 394.png",
          "/static/img/winter2024/Rectangle 523.png",
          "/static/img/winter2024/Rectangle 1171.png",
        ],
        sizes: ["42", "44", "46", "48", "50", "52"],
        characteristics: "Основная ткань: 100% полиэстер",
        product_label: "041",
        price: "10000",
      },
      {
        id: "2",
       name: "14.9316.T1862.16 A2.22 Платье",
        img: [
          "/static/img/winter2024/Rectangle 1206.png",
          "/static/img/winter2024/Rectangle 378.png",
          "/static/img/winter2024/Rectangle 523.png",
          "/static/img/winter2024/Rectangle 394.png",
        ],
        sizes: ["42", "44", "46", "48", "50", "52"],
        characteristics: "Основная ткань: 100% полиэстер",
        product_label: "041",
        price: "10000",
      },
      {
        id: "3",
       name: "14.9316.T1862.16 A2.22 Платье",
        img: [
          "/static/img/winter2024/Rectangle 394.png",
          "/static/img/winter2024/Rectangle 523.png",
          "/static/img/winter2024/Rectangle 378.png",
          "/static/img/winter2024/Rectangle 1171.png",
        ],
        sizes: ["42", "44", "46", "48", "50", "52"],
        characteristics: "Основная ткань: 100% полиэстер",
        product_label: "041",
        price: "10000",
      },
    ],
  },
  {
    fullcapsulimg: "/static/img/winter2024/cpsl/Rectangle 1172.png",
    itemsids: [
      {
        id: "1",
       name: "14.9316.T1862.16 A2.22 Платье",
        img: [
          "/static/img/Rectangle 378.png",
          "/static/img/Rectangle 394.png",
          "/static/img/Rectangle 410.png",
        ],
        sizes: ["42", "44", "46", "48", "50", "52"],
        characteristics: "Основная ткань: 100% полиэстер",
        product_label: "041",
        price: "10000",
      },
      {
        id: "2",
       name: "14.9316.T1862.16 A2.22 Платье",
        img: [
          "/static/img/winter2024/Rectangle 378.png",
          "/static/img/winter2024/Rectangle 394.png",
          "/static/img/winter2024/Rectangle 523.png",
          "/static/img/winter2024/Rectangle 1171.png",
        ],
        sizes: ["42", "44", "46", "48", "50", "52"],
        characteristics: "Основная ткань: 100% полиэстер",
        product_label: "041",
        price: "10000",
      },
      {
        id: "3",
       name: "14.9316.T1862.16 A2.22 Платье",
        img: [
          "/static/img/winter2024/Rectangle 378.png",
          "/static/img/winter2024/Rectangle 394.png",
          "/static/img/winter2024/Rectangle 523.png",
          "/static/img/winter2024/Rectangle 1171.png",
        ],
        sizes: ["42", "44", "46", "48", "50", "52"],
        characteristics: "Основная ткань: 100% полиэстер",
        product_label: "041",
        price: "10000",
      },
    ],
  },
  {
    fullcapsulimg: "/static/img/winter2024/cpsl/Rectangle 1171.png",
    itemsids: [
      {
        id: "1",
       name: "14.9316.T1862.16 A2.22 Платье",
        img: [
          "/static/img/winter2024/Rectangle 378.png",
          "/static/img/winter2024/Rectangle 394.png",
          "/static/img/winter2024/Rectangle 523.png",
          "/static/img/winter2024/Rectangle 1171.png",
        ],
        sizes: ["42", "44", "46", "48", "50", "52"],
        characteristics: "Основная ткань: 100% полиэстер",
        product_label: "041",
        price: "10000",
      },
      {
        id: "2",
       name: "14.9316.T1862.16 A2.22 Платье",
        img: [
          "/static/img/winter2024/Rectangle 378.png",
          "/static/img/winter2024/Rectangle 394.png",
          "/static/img/winter2024/Rectangle 523.png",
          "/static/img/winter2024/Rectangle 1171.png",
        ],
        sizes: ["42", "44", "46", "48", "50", "52"],
        characteristics: "Основная ткань: 100% полиэстер",
        product_label: "041",
        price: "10000",
      },
      {
        id: "3",
       name: "14.9316.T1862.16 A2.22 Платье",
        img: [
          "/static/img/winter2024/Rectangle 378.png",
          "/static/img/winter2024/Rectangle 394.png",
          "/static/img/winter2024/Rectangle 523.png",
          "/static/img/winter2024/Rectangle 1171.png",
        ],
        sizes: ["42", "44", "46", "48", "50", "52"],
        characteristics: "Основная ткань: 100% полиэстер",
        product_label: "041",
        price: "10000",
      },
    ],
  },
  {
    fullcapsulimg: "/static/img/winter2024/cpsl/Rectangle 1174.png",
    itemsids: [
      {
        id: "1",
       name: "14.9316.T1862.16 A2.22 Платье",
        img: [
          "/static/img/winter2024/Rectangle 378.png",
          "/static/img/winter2024/Rectangle 394.png",
          "/static/img/winter2024/Rectangle 523.png",
          "/static/img/winter2024/Rectangle 1171.png",
        ],
        sizes: ["42", "44", "46", "48", "50", "52"],
        characteristics: "Основная ткань: 100% полиэстер",
        product_label: "041",
        price: "10000",
      },
      {
        id: "2",
       name: "14.9316.T1862.16 A2.22 Платье",
        img: [
          "/static/img/winter2024/Rectangle 378.png",
          "/static/img/winter2024/Rectangle 394.png",
          "/static/img/winter2024/Rectangle 523.png",
          "/static/img/winter2024/Rectangle 1171.png",
        ],
        sizes: ["42", "44", "46", "48", "50", "52"],
        characteristics: "Основная ткань: 100% полиэстер",
        product_label: "041",
        price: "10000",
      },
      {
        id: "3",
       name: "14.9316.T1862.16 A2.22 Платье",
        img: [
          "/static/img/winter2024/Rectangle 378.png",
          "/static/img/winter2024/Rectangle 394.png",
          "/static/img/winter2024/Rectangle 523.png",
          "/static/img/winter2024/Rectangle 1171.png",
        ],
        sizes: ["42", "44", "46", "48", "50", "52"],
        characteristics: "Основная ткань: 100% полиэстер",
        product_label: "041",
        price: "10000",
      },
    ],
  },
  {
    fullcapsulimg: "/static/img/winter2024/cpsl/Rectangle 1175.png",
    itemsids: [
      {
        id: "1",
       name: "14.9316.T1862.16 A2.22 Платье",
        img: [
          "/static/img/winter2024/Rectangle 378.png",
          "/static/img/winter2024/Rectangle 394.png",
          "/static/img/winter2024/Rectangle 523.png",
          "/static/img/winter2024/Rectangle 1171.png",
        ],
        sizes: ["42", "44", "46", "48", "50", "52"],
        characteristics: "Основная ткань: 100% полиэстер",
        product_label: "041",
        price: "10000",
      },
      {
        id: "2",
       name: "14.9316.T1862.16 A2.22 Платье",
        img: [
          "/static/img/winter2024/Rectangle 378.png",
          "/static/img/winter2024/Rectangle 394.png",
          "/static/img/winter2024/Rectangle 523.png",
          "/static/img/winter2024/Rectangle 1171.png",
        ],
        sizes: ["42", "44", "46", "48", "50", "52"],
        characteristics: "Основная ткань: 100% полиэстер",
        product_label: "041",
        price: "10000",
      },
      {
        id: "3",
       name: "14.9316.T1862.16 A2.22 Платье",
        img: [
          "/static/img/winter2024/Rectangle 378.png",
          "/static/img/winter2024/Rectangle 394.png",
          "/static/img/winter2024/Rectangle 523.png",
          "/static/img/winter2024/Rectangle 1171.png",
        ],
        sizes: ["42", "44", "46", "48", "50", "52"],
        characteristics: "Основная ткань: 100% полиэстер",
        product_label: "041",
        price: "10000",
      },
    ],
  },
  {
    fullcapsulimg: "/static/img/winter2024/cpsl/Rectangle 1176.png",
    itemsids: [
      {
        id: "1",
       name: "14.9316.T1862.16 A2.22 Платье",
        img: [
          "/static/img/winter2024/Rectangle 378.png",
          "/static/img/winter2024/Rectangle 394.png",
          "/static/img/winter2024/Rectangle 523.png",
          "/static/img/winter2024/Rectangle 1171.png",
        ],
        sizes: ["42", "44", "46", "48", "50", "52"],
        characteristics: "Основная ткань: 100% полиэстер",
        product_label: "041",
        price: "10000",
      },
      {
        id: "2",
       name: "14.9316.T1862.16 A2.22 Платье",
        img: [
          "/static/img/winter2024/Rectangle 378.png",
          "/static/img/winter2024/Rectangle 394.png",
          "/static/img/winter2024/Rectangle 523.png",
          "/static/img/winter2024/Rectangle 1171.png",
        ],
        sizes: ["42", "44", "46", "48", "50", "52"],
        characteristics: "Основная ткань: 100% полиэстер",
        product_label: "041",
        price: "10000",
      },
      {
        id: "3",
       name: "14.9316.T1862.16 A2.22 Платье",
        img: [
          "/static/img/winter2024/Rectangle 378.png",
          "/static/img/winter2024/Rectangle 394.png",
          "/static/img/winter2024/Rectangle 523.png",
          "/static/img/winter2024/Rectangle 1171.png",
        ],
        sizes: ["42", "44", "46", "48", "50", "52"],
        characteristics: "Основная ткань: 100% полиэстер",
        product_label: "041",
        price: "10000",
      },
    ],
  },
  {
    fullcapsulimg: "/static/img/winter2024/cpsl/Rectangle 1171.png",
    itemsids: [
      {
        id: "1",
       name: "14.9316.T1862.16 A2.22 Платье",
        img: [
          "/static/img/winter2024/Rectangle 1199.png",
          "/static/img/winter2024/Rectangle 394.png",
          "/static/img/winter2024/Rectangle 523.png",
          "/static/img/winter2024/Rectangle 1171.png",
        ],
        sizes: ["42", "44", "46", "48", "50", "52"],
        characteristics: "Основная ткань: 100% полиэстер",
        product_label: "041",
        price: "10000",
      },
      {
        id: "2",
       name: "14.9316.T1862.16 A2.22 Платье",
        img: [
          "/static/img/winter2024/Rectangle 1206.png",
          "/static/img/winter2024/Rectangle 378.png",
          "/static/img/winter2024/Rectangle 523.png",
          "/static/img/winter2024/Rectangle 394.png",
        ],
        sizes: ["42", "44", "46", "48", "50", "52"],
        characteristics: "Основная ткань: 100% полиэстер",
        product_label: "041",
        price: "10000",
      },
      {
        id: "3",
       name: "14.9316.T1862.16 A2.22 Платье",
        img: [
          "/static/img/winter2024/Rectangle 394.png",
          "/static/img/winter2024/Rectangle 523.png",
          "/static/img/winter2024/Rectangle 378.png",
          "/static/img/winter2024/Rectangle 1171.png",
        ],
        sizes: ["42", "44", "46", "48", "50", "52"],
        characteristics: "Основная ткань: 100% полиэстер",
        product_label: "041",
        price: "10000",
      },
    ],
  },
  {
    fullcapsulimg: "/static/img/winter2024/cpsl/Rectangle 1172.png",
    itemsids: [
      {
        id: "1",
       name: "14.9316.T1862.16 A2.22 Платье",
        img: [
          "/static/img/Rectangle 378.png",
          "/static/img/Rectangle 394.png",
          "/static/img/Rectangle 410.png",
        ],
        sizes: ["42", "44", "46", "48", "50", "52"],
        characteristics: "Основная ткань: 100% полиэстер",
        product_label: "041",
        price: "10000",
      },
      {
        id: "2",
       name: "14.9316.T1862.16 A2.22 Платье",
        img: [
          "/static/img/winter2024/Rectangle 378.png",
          "/static/img/winter2024/Rectangle 394.png",
          "/static/img/winter2024/Rectangle 523.png",
          "/static/img/winter2024/Rectangle 1171.png",
        ],
        sizes: ["42", "44", "46", "48", "50", "52"],
        characteristics: "Основная ткань: 100% полиэстер",
        product_label: "041",
        price: "10000",
      },
      {
        id: "3",
       name: "14.9316.T1862.16 A2.22 Платье",
        img: [
          "/static/img/winter2024/Rectangle 378.png",
          "/static/img/winter2024/Rectangle 394.png",
          "/static/img/winter2024/Rectangle 523.png",
          "/static/img/winter2024/Rectangle 1171.png",
        ],
        sizes: ["42", "44", "46", "48", "50", "52"],
        characteristics: "Основная ткань: 100% полиэстер",
        product_label: "041",
        price: "10000",
      },
    ],
  },
  {
    fullcapsulimg: "/static/img/winter2024/cpsl/Rectangle 1173.png",
    itemsids: [
      {
        id: "1",
       name: "14.9316.T1862.16 A2.22 Платье",
        img: [
          "/static/img/winter2024/Rectangle 378.png",
          "/static/img/winter2024/Rectangle 394.png",
          "/static/img/winter2024/Rectangle 523.png",
          "/static/img/winter2024/Rectangle 1171.png",
        ],
        sizes: ["42", "44", "46", "48", "50", "52"],
        characteristics: "Основная ткань: 100% полиэстер",
        product_label: "041",
        price: "10000",
      },
      {
        id: "2",
       name: "14.9316.T1862.16 A2.22 Платье",
        img: [
          "/static/img/winter2024/Rectangle 378.png",
          "/static/img/winter2024/Rectangle 394.png",
          "/static/img/winter2024/Rectangle 523.png",
          "/static/img/winter2024/Rectangle 1171.png",
        ],
        sizes: ["42", "44", "46", "48", "50", "52"],
        characteristics: "Основная ткань: 100% полиэстер",
        product_label: "041",
        price: "10000",
      },
      {
        id: "3",
       name: "14.9316.T1862.16 A2.22 Платье",
        img: [
          "/static/img/winter2024/Rectangle 378.png",
          "/static/img/winter2024/Rectangle 394.png",
          "/static/img/winter2024/Rectangle 523.png",
          "/static/img/winter2024/Rectangle 1171.png",
        ],
        sizes: ["42", "44", "46", "48", "50", "52"],
        characteristics: "Основная ткань: 100% полиэстер",
        product_label: "041",
        price: "10000",
      },
    ],
  },
  {
    fullcapsulimg: "/static/img/winter2024/cpsl/Rectangle 1174.png",
    itemsids: [
      {
        id: "1",
       name: "14.9316.T1862.16 A2.22 Платье",
        img: [
          "/static/img/winter2024/Rectangle 378.png",
          "/static/img/winter2024/Rectangle 394.png",
          "/static/img/winter2024/Rectangle 523.png",
          "/static/img/winter2024/Rectangle 1171.png",
        ],
        sizes: ["42", "44", "46", "48", "50", "52"],
        characteristics: "Основная ткань: 100% полиэстер",
        product_label: "041",
        price: "10000",
      },
      {
        id: "2",
       name: "14.9316.T1862.16 A2.22 Платье",
        img: [
          "/static/img/winter2024/Rectangle 378.png",
          "/static/img/winter2024/Rectangle 394.png",
          "/static/img/winter2024/Rectangle 523.png",
          "/static/img/winter2024/Rectangle 1171.png",
        ],
        sizes: ["42", "44", "46", "48", "50", "52"],
        characteristics: "Основная ткань: 100% полиэстер",
        product_label: "041",
        price: "10000",
      },
      {
        id: "3",
       name: "14.9316.T1862.16 A2.22 Платье",
        img: [
          "/static/img/winter2024/Rectangle 378.png",
          "/static/img/winter2024/Rectangle 394.png",
          "/static/img/winter2024/Rectangle 523.png",
          "/static/img/winter2024/Rectangle 1171.png",
        ],
        sizes: ["42", "44", "46", "48", "50", "52"],
        characteristics: "Основная ткань: 100% полиэстер",
        product_label: "041",
        price: "10000",
      },
    ],
  },
  {
    fullcapsulimg: "/static/img/winter2024/cpsl/Rectangle 1175.png",
    itemsids: [
      {
        id: "1",
       name: "14.9316.T1862.16 A2.22 Платье",
        img: [
          "/static/img/winter2024/Rectangle 378.png",
          "/static/img/winter2024/Rectangle 394.png",
          "/static/img/winter2024/Rectangle 523.png",
          "/static/img/winter2024/Rectangle 1171.png",
        ],
        sizes: ["42", "44", "46", "48", "50", "52"],
        characteristics: "Основная ткань: 100% полиэстер",
        product_label: "041",
        price: "10000",
      },
      {
        id: "2",
       name: "14.9316.T1862.16 A2.22 Платье",
        img: [
          "/static/img/winter2024/Rectangle 378.png",
          "/static/img/winter2024/Rectangle 394.png",
          "/static/img/winter2024/Rectangle 523.png",
          "/static/img/winter2024/Rectangle 1171.png",
        ],
        sizes: ["42", "44", "46", "48", "50", "52"],
        characteristics: "Основная ткань: 100% полиэстер",
        product_label: "041",
        price: "10000",
      },
      {
        id: "3",
       name: "14.9316.T1862.16 A2.22 Платье",
        img: [
          "/static/img/winter2024/Rectangle 378.png",
          "/static/img/winter2024/Rectangle 394.png",
          "/static/img/winter2024/Rectangle 523.png",
          "/static/img/winter2024/Rectangle 1171.png",
        ],
        sizes: ["42", "44", "46", "48", "50", "52"],
        characteristics: "Основная ткань: 100% полиэстер",
        product_label: "041",
        price: "10000",
      },
    ],
  },
  {
    fullcapsulimg: "/static/img/winter2024/cpsl/Rectangle 1176.png",
    itemsids: [
      {
        id: "1",
       name: "14.9316.T1862.16 A2.22 Платье",
        img: [
          "/static/img/winter2024/Rectangle 378.png",
          "/static/img/winter2024/Rectangle 394.png",
          "/static/img/winter2024/Rectangle 523.png",
          "/static/img/winter2024/Rectangle 1171.png",
        ],
        sizes: ["42", "44", "46", "48", "50", "52"],
        characteristics: "Основная ткань: 100% полиэстер",
        product_label: "041",
        price: "10000",
      },
      {
        id: "2",
       name: "14.9316.T1862.16 A2.22 Платье",
        img: [
          "/static/img/winter2024/Rectangle 378.png",
          "/static/img/winter2024/Rectangle 394.png",
          "/static/img/winter2024/Rectangle 523.png",
          "/static/img/winter2024/Rectangle 1171.png",
        ],
        sizes: ["42", "44", "46", "48", "50", "52"],
        characteristics: "Основная ткань: 100% полиэстер",
        product_label: "041",
        price: "10000",
      },
      {
        id: "3",
       name: "14.9316.T1862.16 A2.22 Платье",
        img: [
          "/static/img/winter2024/Rectangle 378.png",
          "/static/img/winter2024/Rectangle 394.png",
          "/static/img/winter2024/Rectangle 523.png",
          "/static/img/winter2024/Rectangle 1171.png",
        ],
        sizes: ["42", "44", "46", "48", "50", "52"],
        characteristics: "Основная ткань: 100% полиэстер",
        product_label: "041",
        price: "10000",
      },
    ],
  },
];
export default capsul;
