import "./LookBook.css";
import productsDb from "../testdb";
import { useState } from "react";
import OptPopup from "../opt_popup/OptPopup";
import Jwt_proxy from "src/components/API/Api";
import { Button } from "@react-pdf-viewer/core";
const Optlookbook = ({
  data,
  popupActive,
  setPopupData,
  setPopupActive,
  setScrollId,
  getPopupData,
  obj,
}) => {
  function tabPrice(str) {
    if (str.length > 3) {
      let f = str.slice(-4, str.length - 1);
      let t = str.slice(0, -3);
      return t + " " + f;
    }
  }
  const [showHide, setShovHide] = useState(false);

  return (
    <div
      className="LookbookWrapper"
      onClick={() => {
        Jwt_proxy(() => getPopupData(data, obj));
        setTimeout(() => {
          setPopupActive(true);
        }, 100);
      }}
      onMouseEnter={() => setShovHide(true)}
      onMouseLeave={() => setShovHide(false)}
    >
      <span>{data.name}</span>
      <div className="LookbookBody">
        <img src={data.fullcapsulimg} alt="" />
      </div>
      <div
        style={{
          position: "absolute",
          backgroundColor: "rgba(128, 128, 128, 0.7)",
          width: "100%",
          height: "100%",
          left: 0,
          top: 0,
          display: showHide ? "flex" : "none",
          alignItems: "center",
          justifyContent:"center"
        }}
      >
        <button className="catrtBtn white"
        style={{width:'70%'}}>
          Добавить в заказ
          </button>
      </div>
    </div>
  );
};
export default Optlookbook;
