import productsDb from "../testdb.js";
import MainOptProductCard from "../MainOptProduct/ProductCard";
import { useEffect, useState } from "react";
import Jwt_proxy from "src/components/API/Api.jsx";
import Spinner from "./spinner.jsx";
const ProductPage = ({
  setUrlPage,
  popupActive,
  Collection,
  setPopupActive,
  selectedLineId,
  setSelectedLineId,
  setScrollId,
}) => {
  const [data, setData] = useState([]);
  async function getProducts() {
    let ip = localStorage.getItem("server_ip") + "/api/opt/get_products";
    let token = localStorage.getItem("token");
    let res = await fetch(ip, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-token": token,
      },
    });
    if (res.ok) {
      let r = await res.json();
      setData(
        r.map((i) => ({
          ...i
        }))
      );
    }
  }
 
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    setUrlPage("product_line");
    Jwt_proxy(()=>getProducts().then(() => {
      setIsLoading(false);
    }))
  }, []);

  return (
    <div class="contentFlexWrapper flex" style={isLoading ?{backgroundColor:'white'}:{}}>
      {isLoading ? (
        <Spinner />
      ) : (
        data.map((i) => (
          <MainOptProductCard
            setScrollId={setScrollId}
            selectedLineId={selectedLineId}
            setSelectedLineId={setSelectedLineId}
            popupActive={popupActive}
            setPopupActive={setPopupActive}
            data={i}
          />
        ))
      )}
    </div>
  );
};

export default ProductPage;
