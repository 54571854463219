import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./MainOptHeader.css";
import Jwt_proxy from "src/components/API/Api";

const MainOptHeader = ({ urlPage, setUrlPage, Collection }) => {
  let navigate = useNavigate();

  function toProduct() {
    navigate("product_line");
  }
  function toCapsul() {
    navigate("capsul_line");
  }
  function toLookbook() {
    navigate("lookbook");
  }

  async function Logout() {
    localStorage.setItem("token", "null");
    localStorage.setItem("refresh_token", "null");
    localStorage.setItem("username", "null");
    localStorage.setItem("logged", "false");
    let ip = localStorage.getItem("server_ip");
    let token = localStorage.getItem("token");
    let res = await fetch(ip + "/users/logout", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-token": token,
      },
    });
    if (res.ok) {
      navigate("/login");
    }
  }
  
  return (
    <>
      <header>
        <div className="headernavbar">
          <div className="logo">
            <img src="/static/img/logo-mreason.svg" />
          </div>
          <div className="navMenu top">
            <ul>
              <li className="navItem" id="profile">
                <img src="/static/img/Asset 52.svg" />
                Профиль
              </li>
              <li className="navItem" onClick={Logout}>
                <img src="/static/img/Layer 2.svg" />
                Выход
              </li>
            </ul>
          </div>
          <div className="collectionMobile">
            <h1>{Collection.name.toUpperCase()}</h1>
          </div>
        </div>
        <div className="headLine"></div>
        <div className="collectionTitle">
          <div className="collection">
            <h1>{Collection.name.toUpperCase()}</h1>
          </div>
        </div>
        <div className="contentBtns">
          <button
            className={
              urlPage === "product_line" ? "contentBtn active" : "contentBtn"
            }
            onClick={() => {
              toProduct();
            }}
            id="productPhotoBtn"
          >
            Фотографии изделий
          </button>
          <button
            className={
              urlPage === "capsul_line" ? "contentBtn active" : "contentBtn"
            }
            onClick={() => {
              toCapsul();
            }}
            id="lineBtn"
          >
            Капсулы
          </button>
          <button
            className={
              urlPage === "lookbook" ? "contentBtn active" : "contentBtn"
            }
            onClick={() => {
              toLookbook();
            }}
          >
            Оптовый лукбук
          </button>
        </div>
      </header>
    </>
  );
};
export default MainOptHeader;
