import MainOptFooter from "../MainOptFooter/MainOptFooter";
import MainOptHeader from "../MainOptHeader/MainOptHeader";
import { Routes, Route, useNavigate } from "react-router-dom";
import "../capsul";
import OptPopup from "../opt_popup/OptPopup";
import LookBookPage from "../pages/LookBookPage";
import "./OptMainWidjet.css";
import CapsulPage from "../pages/CapsulPage.jsx";
import ProductPage from "../pages/ProductPage.jsx";
import Jwt_proxy from "src/components/API/Api";
import capsuls from "../capsuls";

import capsul from "../capsul";
import { useEffect, useState } from "react";
import React, { useRef } from "react";

const OptMainWidjet = ({link}) => {
  let navigate = useNavigate();
  const [urlPage, setUrlPage] = useState("product_line");
  function load() {
    navigate(urlPage);
  }
  const [Collection, setCollection] = useState({ name: "ИМЯ", id: null });
  
  async function getCollection() {
    let ip = localStorage.getItem("server_ip") + "/api/opt/get_collection";
    let token = localStorage.getItem("token");
    let res = await fetch(ip, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-token": token,
      },
    });
    if (res.ok) {
      let r = await res.json();
      setCollection(r);
    }
  }
  useEffect(() => {
    Jwt_proxy(getCollection);
  }, []);
  const [popupActive, setPopupActive] = useState(false);
  const [selectedLineId, setSelectedLineId] = useState(null);
  useEffect(() => {
    load();
  }, [link]);
  const scrollRef = useRef(null);
  const [scrollId, setScrollId] = useState(null);

  return (
    <>
      <MainOptHeader
        urlPage={urlPage}
        setUrlPage={setUrlPage}
        Collection={Collection}
      />
      <div class="container">
        <Routes>
          <Route
            path="product_line"
            element={
              <ProductPage
                setScrollId={setScrollId}
                Collection={Collection}
                popupActive={popupActive}
                setPopupActive={setPopupActive}
                setUrlPage={setUrlPage}
                selectedLineId={selectedLineId}
                setSelectedLineId={setSelectedLineId}
              />
            }
          />
          <Route
            path="capsul_line"
            element={
              <CapsulPage
              setScrollId={setScrollId}
                scrollId={scrollId}
                scrollRef={scrollRef}
                setUrlPage={setUrlPage}
                dat={capsuls}
                setPopupActive={setPopupActive}
              />
            }
          />
          <Route
            path="lookbook"
            element={
              <LookBookPage
              setScrollId={setScrollId}
                popupActive={popupActive}
                setPopupActive={setPopupActive}
                setUrlPage={setUrlPage}
              />
            }
          />
        </Routes>
      </div>
      <MainOptFooter />
      
    </>
  );
};

export default OptMainWidjet;
