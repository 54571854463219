import Datefunc from "../../DateFunc";
import "./sectionPage.css";
import { useNavigate } from "react-router-dom";
import Jwt_proxy from "../../../API/Api";

const SectionPageItem = ({
  data,
  content_type,
  firstArticleLoad,
  setFirstArticleLoad,
  getLeftArr,
  toggleSelect,
  getThreePathData,
  nameAndPathCreate,
  date,
}) => {
  let NowDate = new Datefunc();

 

  let navigate = useNavigate();
  function tosection() {
    Jwt_proxy(() => getLeftArr(data.id));
    setTimeout(() => {
      var event = new MouseEvent("dblclick", {
        view: window,
        bubbles: true,
        cancelable: true,
      });
  
      if (data.id !== "00000000-0000-0000-0000-000000000000") {
        document.getElementById(data.id).dispatchEvent(event);
      }
    }, 200);
    navigate('/wiki/section/'+data.id)
  }

  function toarticle() {
    setFirstArticleLoad(true);
    navigate("article/" + data.id);
  }


  async function fileUndelete() {
    let ip = localStorage.getItem("server_ip") + "/api/wiki/file/undelete";

    let token = localStorage.getItem("token");
    let res = await fetch(ip, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-token": token,
      },
      body: JSON.stringify({ id: data.id }),
    });
    if (res.ok) {
      Jwt_proxy(() => getLeftArr(data.section_id, data.id));
      navigate("/wiki/file/" + data.id);
      data.deleted=false
    }
  }


  let description;
  let formattedDescription = '';
  if (data.description !== null && data.description !== undefined&&data.description.length>0) {
    description = data.description.slice(0, 50);
    formattedDescription = description.match(/.{1,45}/g).join("\n");
  }
  else{
    formattedDescription = ''
  }
  let body;
  if (data.body !== null && data.body !== undefined) {
   if (data.body.length>20){
    body = data.body.match(/.{1,45}/g).join("\n");
   }
   else{
    body = data.body;
   }
  }

  const handleDownload = () => {
    const url =
      localStorage.getItem("server_ip") +
      "/wiki/storage/" +
      data.file_id +
      "_" +
      data.filename;
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", data.filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  if (content_type === "section") {
    return (
      <div className="sectionItemWrapper" style={{color:data.title_font_color}}>
        <div className="sectionItemAuthor">
          <span className="smallItemText">
            {NowDate.nowDateTime(data.creation_date)}:{" "}
          </span>
          <span className="smallItemText">Автор: {data.author}</span>
        </div>
        <div className="sectionItemTitle">
          <img src="/static/img/Group 187.svg" alt="" />
          <h4>
            {data.name?data.name.length > 50
              ? data.name
                  .match(/.{1,45}/g)
                  .join("\n")
                  .slice(0, 50) + "..."
              : data.name.match(/.{1,45}/g).join("\n"):null}
          </h4>
        </div>
        <div className="sectionItemDescription">
          <span>
            {data.description !== null && data.description !== undefined
              ? formattedDescription + "..."
              : null}
          </span>
        </div>
        <div className="sectionItemButtons">
          <span className="toLink" onClick={tosection}>
            Читать полностью
          </span>
        </div>
        <span className="smallItemText">id: {data.id}</span>
      </div>
    );
  }
  if (content_type === "article") {
    return (
      <div className="sectionItemWrapper"  style={{color:data.title_font_color}}>
        <div className="sectionItemAuthor">
          <span className="smallItemText">
            {NowDate.nowDateTime(data.creation_date)}:{" "}
          </span>
          <span className="smallItemText">Автор: {data.author}</span>
        </div>
        <div className="sectionItemTitle">
          <img src="/static/img/Group 210.svg" alt="" />
          <h4>
            {data.name?data.name.length > 20
              ? data.name
                  .match(/.{1,45}/g)
                  .join("\n")
                  .slice(0, 50) + "..."
              : data.name.match(/.{1,45}/g).join("\n"):null}
          </h4>
        </div>
        <div className="sectionItemDescription">
          <span>
            {data.body !== null && data.body !== undefined
              ? body
                  .replace(/<[^>]+>|&nbsp;|&amp;|&lt;|&gt;/gi, "")
                  .slice(0, 50) + "..."
              : null}
          </span>
        </div>
        <div className="sectionItemButtons">
          <span className="toLink" onClick={toarticle}>
            Читать полностью
          </span>
        </div>
        <span className="smallItemText">id: {data.id}</span>
      </div>
    );
  }
  if (content_type === "file") {
    return (
      <div
        className="sectionItemWrapper"
        style={{ width: "100%", maxHeight: "20vh" }}
      >
        <div className="sectionItemAuthor">
          <span className="smallItemText">
            {NowDate.nowDateTime(data.modification_date)}:{" "}
          </span>
          <span className="smallItemText">Автор: {data.author}</span>
        </div>
        <div className="sectionItemTitle">
          <img src="/static/img/file.svg" alt="" style={{ height: "32px" }} />
          <h4>
            {data.name?data.name.length > 50
              ? data.name
                  .match(/.{1,45}/g)
                  .join("\n")
                  .slice(0, 50) + "..."
              : data.name.match(/.{1,45}/g).join("\n"):null}
          </h4>
        </div>
        <div className="sectionItemDescription">
          <span>
            {data.description !== null && data.description !== undefined
              ? formattedDescription
                  .replace(/<[^>]+>|&nbsp;|&amp;|&lt;|&gt;/gi, "")
                  .slice(0, 50) + "..."
              : null}
          </span>
        </div>
        <span className="smallItemText">id файла: {data.file_id}</span>
        <div
          className="sectionItemButtons"
          style={{ justifyContent: "space-between" }}
        >
          <span className="toLink" onClick={handleDownload}>
            Скачать
          </span>
          {date === undefined &&data.deleted===false? (
            <span
              className="toLink"
              onClick={() => navigate("/wiki/file/update/" + data.id)}
            >
              Редактировать
            </span>
          ) : null}
          {data.deleted !== undefined &&data.deleted===true? (
            <span
              className="toLink"
              onClick={() => Jwt_proxy( fileUndelete)}
            >
              Восстановить
            </span>
          ) : null}
          
        </div>
      </div>
    );
  }
};

export default SectionPageItem;
