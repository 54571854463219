import { DrawIoEmbed, DrawIoEmbedRef } from "../react-drawio";
import { useRef, useState, useEffect } from "react";
import Jwt_proxy from "src/components/API/Api";
import PopupTwooLevel from "../Popup/PopupTwooLevel";
import { useParams, useNavigate } from "react-router-dom";
import "./diagram_page/diagram_page.css";

const Draw = ({
  seteditOpen,
  nameAndPathCreate,
  getLeftArr,
  getSections,
  ArticleData,
  getRedactDiagramm,
  imgData,
  dtyperef,
  drawioRef,
  diagramRef,
  setFirstArticleLoad,
  sectionRef,
  descriptionRef,
  sectionOldRef,
  coderef,
  fontcolorref,
  Id,
  fullscreen,
}) => {
  const navigate = useNavigate();

  let id = Id;

  async function SaveDiagramm(data) {
    drawioRef.current = data;
    console.log(data);
    let token = localStorage.getItem("token");
    let ip = localStorage.getItem("server_ip");

    let res = await fetch(
      Id === undefined
        ? ip + "/api/wiki/diagram/create"
        : ip + "/api/wiki/diagram/update",
      {
        headers: {
          "Content-Type": "application/json",
          "x-token": token,
        },
        method: "POST",
        body: JSON.stringify({
          data: data,
          name: nameRef.current,
          section_id: sectionRef.current,
          description: descriptionRef.current,
          id: Id,
          diagram_type: dtyperef.current,
          code: coderef.current,
          title_font_color: fontcolorref.current,
        }),
      }
    );
    if (res.ok) {
      let r = await res.json();
      if (sectionOldRef.current !== sectionRef.current) {
        var event = new MouseEvent("dblclick", {
          view: window,
          bubbles: true,
          cancelable: true,
        });

        let cl = document.getElementById(sectionOldRef.current);
        cl.dispatchEvent(event);
      }
      Jwt_proxy(() => getLeftArr(sectionRef.current, r.id));
      navigate("/wiki/diagram/" + r.id);
    }
  }

  const [name, setname] = useState(null);

  let [section_id, setSectionId] = useState(nameAndPathCreate.id);

  const [showHTMLData, setShowHTMLData] = useState([]);

  const [ShowPopup, setShowPopup] = useState({
    show: false,
  });

  function onShowPopup() {
    setShowPopup((ShowPopup) => ({ ...ShowPopup, show: !ShowPopup.show }));
  }

  function getParentId(data) {
    setSectionId(data.id);
  }

  const [test, setTest] = useState();

  seteditOpen(true);

  const nameRef = useRef("");

  const cfg = {
    hr: "–",
    red: getParentId,
    showPopup: ShowPopup,
    setShowPopup: onShowPopup,
  };

  function getForPopup() {
    Jwt_proxy(() =>
      getSections("00000000-0000-0000-0000-000000000000", setShowHTMLData, cfg)
    );
  }

  useEffect(() => {
    nameRef.current = ArticleData.name;
  }, [ArticleData.name]);
  useEffect(() => {
    descriptionRef.current = ArticleData.abstruct;
  }, [ArticleData.abstruct]);

  useEffect(() => {
    drawioRef.current = imgData;
  }, [imgData]);

  useEffect(() => {
    if (Id !== undefined) {
      Jwt_proxy(getRedactDiagramm);
    } else {
      sectionRef.current = ArticleData.section_id;
      sectionOldRef.current = ArticleData.section_id;
    }
  }, []);

  useEffect(() => {
    sectionRef.current = ArticleData.section_id;
  }, [ArticleData.section_id]);

  function onChange(e) {
    setname(e.target.value);
  }

  const config = {
    css: `.geMenubarContainer { display:none !important; height:0!important;} 
    
      .geButton, .geBigButton { display:none !important; } 
      .geToolbar .geLabel:first-child, .geToolbar .geLabel:last-child, .geToolbar .geSeparator { display:none !important; } 
      .geEditor{top:-30px; height:90%;}
      `,
  };

  return (
    <div
      style={{
        width: "100%",
        height: !fullscreen ? "66vh" : "98vh",
        border: !fullscreen ? "1px solid black" : "none",
        position: !fullscreen ? "relative" : "static",
        top: -21,
        paddingTop: 0,
      }}
    >
      <DrawIoEmbed
        ref={diagramRef}
        config={config}
        className="hide-menu"
        menubarEnabled={false}
        xml={drawioRef.current}
        onExport={(data) => SaveDiagramm(data.data)}
        configuration={config}
      />
    </div>
  );
};

export default Draw;
