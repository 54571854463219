import "./ThreeItem.css";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import Jwt_proxy from "src/components/API/Api";
const TreeItem = ({
  setArticleId,
  data,
  setFilePdf,
  setArticleData,
  firstArticleLoad,
  setFirstArticleLoad,
  ArticleData,
  setArticleWiew,
  ArticleId,
  sectionWay,
  getSectionWay,
  WayString,
  setWayString,
  article_id,
  getHist,
  getParentId,
  toggleSelectArticle,
  setArticleHistory,
  setHistoryPopup,
  setHistoryArticleData,
  getThreePathData,
  setCoords,
  context,
  setStowContext
}) => {
  function fu(el, s) {
    let r = el.parentElement.querySelector(".strong");
    let f =
      r.parentElement.parentElement.parentElement.parentElement.parentElement;
    let y = f.querySelector(".strong");
    let l = r.innerHTML;
    let str = [...s];
    str.push(l);
    if (!f.classList.contains("TreeGroupItem")) {
      return str;
    } else {
      let st = fu(y, str);
      return st;
    }
  }
 
  function getWay(i) {
    let el = document.getElementById(i.id);
    let e = el.parentElement.parentElement.parentElement;
    let str = [""];
    str = fu(e, str);
    let string = String([...str.reverse()]).replaceAll(",", "/");
    setWayString(string);
    getSectionWay((sectionWay) => (sectionWay = str));
  }
  if (setArticleHistory !== undefined) {
      if (article_id !== undefined&&data.id===article_id) {
      Jwt_proxy(() => getHist(article_id));
      getParentId(article_id);
      toggleSelectArticle(article_id)
    }
  }
  // useEffect(() => {
  //   if (ArticleData.id === data.id) {
  //     toggleSelectArticle(data.id);
  //   }
  // }, []);
  function showContextClick(e) {
    if (context) {
      e.preventDefault();
      
      if (document.getElementById(data.id).classList.contains('select')) {
        const x = e.clientX;
        const y = e.clientY;
        setCoords([x, y]);
        setStowContext(true);

       
      }
    }
  }

  return (
    <Link to={"article/" + data.id}>
      <div
        onClick={() => {
          getParentId(data.section_id)
          setFirstArticleLoad(false)
          getWay(data);
          setArticleId(data.id);
          toggleSelectArticle(data.id);
          setArticleWiew(true);
          setArticleData((ArticleData) => ({
            ...data,
          }));
          Jwt_proxy(() => getHist(data.id));
          setHistoryArticleData(null);
          setHistoryPopup(false);
          getThreePathData(data, true, 1)
         
        }}
        onContextMenu={showContextClick}
        className="TreeGroupItem"
      >
        <div className="TreeItem"  id={data.id} style={{color:data.title_font_color}}>
          {"—"}
          {data.deleted ? (
            <img src="/static/img/Group 210_deleted.svg" alt="" />
          ) : (
            <img src="/static/img/Group 210.svg" alt="" />
          )}

          <span classList="strong">{data.name}</span>
        </div>
      </div>
    </Link>
  );
};

export default TreeItem;
