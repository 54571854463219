import capsuls from "../../capsuls.js";
import { useState } from "react";
import Jwt_proxy from "src/components/API/Api.jsx";

const Switch = ({data, setData, setview, view, getLines}) => {
    const [isOn, setIsOn] = useState(false);
  
    const toggleSwitch = () => {
      
      if (view===10){
        setview(8)
      }
      else{
        setview(10)
      }
    };
  
    return (
      <div style={{margin:'0 2%', dispay:'flex', alignItems:'center'}}>
        <label className="switch">
          <input type="checkbox" checked={view === 10} onChange={toggleSwitch} />
          <span className="slider_swich round"></span>
        </label>
      </div>
    );
  };
export default Switch