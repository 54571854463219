
import { useEffect, useState } from "react";
import "./HRTable.css";
import Header from "../MainWikiHeader/MainHeader";
import Datefunc from "../wiki/DateFunc";

import {

  Link,
  useNavigate,
} from "react-router-dom";

// import config from "../../config/config.js";
import Jwt_proxy from "../API/Api";

const ApplicationForPersonnel = ({}) => {
  let Datefunction = new Datefunc()
  const [tabledata, setTabledata] = useState([]);
  async function getApplication() {
    const ip = localStorage.getItem('server_ip');
    const token = localStorage.getItem("token");
    let res = await fetch(ip + "/api/hr/list/get_all_applications", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-token": token,
      },
      body: JSON.stringify({
        username: localStorage.getItem("username"),
        sort_columns: columnsForSort,
      }),
    });
    if (res.ok) {
      let r = await res.json();
      setTabledata(r);
      
    }
  }

  function newAppl() {
    localStorage.setItem("applicationId", null);
  }
  let navigate = useNavigate();
  const goEdit = () => {
    navigate("/hr/list/" + localStorage.getItem("applicationId"));
  };
  useEffect(() => {
    Jwt_proxy(getApplication);
  }, []);
  const [columnsForSelectToSort, setColumnsForSelectToSort] = useState([
    {
      name: "Подразделение",
      column: "departments",
      value: "name",
      desc: false,
    },
    { name: "Заказчик", column: "persons", value: "name", desc: false },
    {
      name: "Должность",
      column: "recruitment_applications",
      value: "vacancy_name",
      desc: false,
    },
    {
      name: "Должность по классификатору",
      column: "job_positions",
      value: "name",
      desc: false,
    },
    {
      name: "Исполнитель",
      column: "recruitment_applications",
      value: "executor",
      desc: false,
    },
    {
      name: "Общее количество вакансий",
      column: "recruitment_applications",
      value: "desired_quantity",
      desc: false,
    },
    {
      name: "Вакансий закрыто",
      column: "recruitment_applications",
      value: "vacancies_closed",
      desc: false,
    },
    {
      name: "Дата поступления заявки",
      column: "recruitment_applications",
      value: "date",
      desc: false,
    },
     {name:'Продолжительность закрытия заявки',
      column: null,
       value:'time_of_closed_application',
        desc:false },
    {
      name: "Примечание",
      column: "recruitment_applications",
      value: "note",
      desc: false,
    },
  ]);
  const [columnsForSort, setColumnsForSort] = useState([]);
  const [selectedColumnFrom, setSelectedColumnFrom] = useState([]);
  const [selectedColumnTo, setSelectedColumnTo] = useState([]);

  function addColumns() {
    if (selectedColumnFrom.length > 0) {
      const updatedColumnsForSelectToSort = columnsForSelectToSort.filter(
        (column) => !selectedColumnFrom.includes(column)
      );
      const updatedColumnsForSort = [...columnsForSort, ...selectedColumnFrom];
      setColumnsForSelectToSort(updatedColumnsForSelectToSort);
      setColumnsForSort(updatedColumnsForSort);
      setSelectedColumnFrom([]);
    }
  }
  function check(e) {
    setColumnsForSort(
      (columnsForSort)=>
      columnsForSort.map((i) =>
        i.name === e.name ? { ...i, desc: !i.desc } : i
      )
    );
    setColumnsForSelectToSort(columnsForSelectToSort.map((i) =>
    i.name === e.name ? { ...i, desc: !i.desc } : i
  ))
  }
  const [Reversed, setReversed] = useState(0);
  function Revers() {
    if (columnsForSort.length > 0) {
      if (Reversed === 0) {
        setColumnsForSort(columnsForSort.reverse());
        setReversed(1);
      }
    }
  }
  function reRevers() {
    if (columnsForSort.length > 0) {
      if (Reversed === 1) {
        setColumnsForSort(columnsForSort.reverse());
        setReversed(0);
      }
    }
  }
  function removeColumns() {
    if (selectedColumnTo.length > 0) {
      const updatedColumnsForSort = columnsForSort.filter(
        (column) => !selectedColumnTo.some((selectedColumn) => selectedColumn.name === column.name)
      );
      const updatedColumnsForSelectToSort = [
        ...columnsForSelectToSort,
        ...selectedColumnTo,
      ];
      setColumnsForSelectToSort(updatedColumnsForSelectToSort);
      setColumnsForSort(updatedColumnsForSort);
      setSelectedColumnTo([]);
    }
  }
  function AddToArr(arr, setArr, el) {
    
     if (!arr.some((selectedColumn) => selectedColumn.name === el.name)) {
      setArr([...arr, el]);
    } else {
      const newArr = arr.filter((column) => column.name !== el.name);
      setArr(newArr);
    }
  }
  
  const HRTable = ({ data }) => {
    return (
      <div style={{ display: "flex", width: "90%" }}>
        <div className="LeftTable" >
          <table style={{width:'100%'}}>
            <thead>
              <tr>
                <th>Подразделение </th>
                <th>Заказчик</th>
                <th>Должность</th>
                <th>Должность по классификатору</th>
              </tr>
            </thead>
            <tbody>
              {data.map((dat) => (
                <tr
                  onClick={() => localStorage.setItem("applicationId", dat.id)}
                  key={dat.id}
                  id={dat.id}
                  onDoubleClick={goEdit}
                >
                {/* <td  contentEditable={true}  name = 'Имя'
                onBlur={(e) => console.log(e.target.textContent)}>{dat.department}</td> */}
                  {" "}
                  <td>{dat.department}</td>
                  <td>{dat.applicant}</td>
                  <td>{dat.vacancy_name}</td>
                  <td>{dat.job_position_id}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className='RightTable'>
          <table style={{ width: "1500px" }}>
            <thead>
              <tr>
                <th>Исполнитель</th>
                <th>Общее количество вакансий</th>
                <th>Вакансий закрыто</th>
                <th>Вакансий в работе</th>
                <th>Дата поступления заявки</th>
                <th>Продолжительность закрытия заявки</th>
                <th>Примечание</th>
              </tr>
            </thead>
            <tbody>
              {data.map((dat) => (
                <tr
                  onClick={() => localStorage.setItem("applicationId", dat.id)}
                  key={dat.id}
                  id={dat.id}
                  onDoubleClick={goEdit}
                >
                  <td>{dat.executor}</td>
                  <td>{dat.desired_quantity}</td>
                  <td>{dat.vacancies_closed}</td>
                  <td>{dat.vacancies_to_work}</td>
                  <td>{Datefunction.nowDateInput(dat.date)}</td>
                  <td>
                    {dat.time_of_closed_application===0
                      ? dat.time_of_closed_application + " (дней)"
                      : 'В работе'}
                      
                  </td>
                  <td>
                    {dat.note !== null ? dat.note.substr(0, 20) + "..." : null}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );

    // const COLUMNS = tabledata["columns"];
    // const TABLEDATA = tabledata["data"];

    // const columns = useMemo(() => COLUMNS, []);
    // const data = useMemo(() => TABLEDATA, []);
    // const tableInstance = useTable({
    //   columns,
    //   data,
    // });
    // const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    //   tableInstance;

    // return (
    //   <table className="bigtable" {...getTableProps()}>
    //     <thead>
    //       {headerGroups.map((headerGroup) => (
    //         <tr {...headerGroup.getFooterGroupProps()}>
    //           {headerGroup.headers.map((column) => (
    //             <th {...column.getHeaderProps()}>{column.render("name")}</th>
    //           ))}
    //         </tr>
    //       ))}
    //     </thead>
    //     <tbody {...getTableBodyProps()}>
    //       {rows.map((row) => {
    //         prepareRow(row);
    //         return (
    //           <tr {...row.getRowProps()}>
    //             {row.cells.map((cell) => {
    //               return (
    //                 <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
    //               );
    //             })}
    //           </tr>
    //         );
    //       })}
    //     </tbody>
    //   </table>
    // );
  };

  const ApplicationForPersonelTable = () => {
    return (
      <div className="ApplicationForPersonnelTable">
        <div className="ApplicationForPersonnelButtons">
          <button 
            onClick={newAppl}
            className="ApplicationForPersonnelButtonsBtn"
          >
            <Link to="list">Новая</Link>
          </button>
          <button
            className="ApplicationForPersonnelButtonsBtn"
            onClick={() => Jwt_proxy(getApplication)}
          >
            Обновить
          </button>
        </div>
        <HRTable data={tabledata} />
      </div>
    );
  };

  const ApplicationForPersonelSort = ({
    columnsForSelectToSort,
    columnsForSort,
  }) => {
    return (
      <div className="ApplicationForPersonelSortWrapper">
        <div className="ApplicationForPersonelSortBtns">
          <button
            className="ApplicationForPersonnelButtonsBtn"
            onClick={reRevers}
          >
            А-Я
          </button>
          <button
            className="ApplicationForPersonnelButtonsBtn big"
            onClick={Revers}
          >
            Я-А
          </button>
        </div>
        <div className="ApplicationForPersonelSort">
          <div className="ApplicationForPersonelSortSelected">
            <table>
              <thead>
                <tr>
                  <th>Колонка для сортировки</th>
                  <th>УБЫВ</th>
                </tr>
              </thead>
              <tbody>
                {columnsForSort.map((i) => (
                  <tr
                    key={i.value}
                    onClick={() =>
                      AddToArr(selectedColumnTo, setSelectedColumnTo, i)
                    }
                    style={
                      selectedColumnTo.includes(i)
                        ? { backgroundColor: "gray" }
                        : {}
                    }
                  >
                    <td>{i.name}</td>
                    <td>
                      <input
                        type="checkbox"
                        checked={i.desc ? "checked" : ""}
                        onChange={() => check(i)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="ApplicationForPersonelSortSelected btns">
            <button
              className="ApplicationForPersonnelButtonsBtn small"
              onClick={addColumns}
            >
              {"<-"}
            </button>
            <button
              className="ApplicationForPersonnelButtonsBtn small"
              onClick={removeColumns}
            >
              {"->"}
            </button>
          </div>
          <div className="ApplicationForPersonelSortSelected">
            <table>
              <thead>
                <tr>
                  <th>Колонка</th>
                </tr>
              </thead>
              <tbody>
                {columnsForSelectToSort.map((n) => (
                  <tr
                    key={n.value}
                    onClick={() =>
                      AddToArr(selectedColumnFrom, setSelectedColumnFrom, n)
                    }
                    style={
                      selectedColumnFrom.includes(n)
                        ? { backgroundColor: "gray" }
                        : {}
                    }
                  >
                    <td>{n.name}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  };
  const [sorted, setSorted] = useState(true);
  
  function togglePages() {
    let ar = document.querySelectorAll(".ApplicationForPersonnelNavBarBtn");
    setSorted(!sorted);
    for (let i = 0; i < ar.length; i++) {
      ar[i].classList.toggle("Active");
    }
  }

  return (
    <>
      <Header />

      <div className="ApplicationForPersonnelContent">
        <div className="ApplicationForPersonnelNavBar">
          <button
            onClick={togglePages}
            className="ApplicationForPersonnelNavBarBtn "
          >
            Заявки на подбор
          </button>
          <button
            onClick={togglePages}
            className="ApplicationForPersonnelNavBarBtn Active"
          >
            Сортировка
          </button>
        </div>

        {sorted === false ? (
          <ApplicationForPersonelSort
            columnsForSelectToSort={columnsForSelectToSort}
            columnsForSort={columnsForSort}
          />
        ) : (
          <ApplicationForPersonelTable />
        )}
      </div>
    </>
  );
};

export default ApplicationForPersonnel;
