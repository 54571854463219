import "./OptPopup.css";
import MainOptProductCard from "../MainOptProduct/ProductCard";
import { useEffect } from "react";

const OptPopup = ({
  data,
  popupActive,
  setPopupActive,
  selectedLineId,
  setSelectedLineId,
  setScrollId,
  slidePopup,
  obj,
}) => {
  // async function gelCapsul() {
  //   let ip = localStorage.getItem("server_ip") + "/api/opt/get_capsul";
  //   let token = localStorage.getItem("token");
  //   let res = await fetch(ip, {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //       "x-token": token,
  //     },
  //     body:JSON.s
  //   });
  //   if (res.ok) {
  //     let r = await res.json();
  //   }
  // }
  

  useEffect(() => {}, [selectedLineId]);
  return (
    <div className="grayfone">
      <div className="opt_popup_wrapper">
      <div
        style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
      >
        <div
          className="popupClose"
          onClick={() => {
            setPopupActive(false);
          }}
        >
          <div className="leftHide"></div>
          <h1>ЗИМА 2024</h1>
          <img src="/static/img/Group 125.svg" alt="" />
          <p>ЗАКРЫТЬ</p>
        </div>
      </div>
      <div className="opt_popup_wrapper_flex">
        <div className="capsulImg">
          <img className="bigImg" src={data.fullcapsulimg} alt="" />
          <div style={{display:'flex', justifyContent:'space-between', width:'70%', alignItems:'center', paddingLeft:'15%'}}>
          <button onClick={() => slidePopup(data, -1)} className="sliderPrew sliderBtn">  
            <img src="/static/img/toleft.svg" alt="sliderPrew" />
          </button>
          {`Образ ${obj.n + 1}/${obj.i}`}
          <button onClick={() => slidePopup(data, 1)} className="sliderNext sliderBtn">
            <img src="/static/img/toright.svg" alt="sliderNext" />
          </button>
          </div>
        </div>
        <div className="products">
          {data.itemsids.map((i) => (
            <MainOptProductCard
              setScrollId={setScrollId}
              popupActive={popupActive}
              setPopupActive={setPopupActive}
              data={i}
            />
          ))}
        </div>
      </div>
    </div>
    </div>
  );
};
export default OptPopup;
