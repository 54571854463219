import React, { useEffect, useState } from "react";
import { Document, Page } from "react-pdf";
import "./WikiArticteViever.css";
import { pdfjs } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const Article = ({ setFilePdf, filePdf }) => {
  useEffect(() => {
    setFilePdf(filePdf);
  }, [filePdf, setFilePdf]);
  function MyPDF() {
    const [numPages, setNumPages] = useState(null);

    function onDocumentLoadSuccess({ numPages }) {
      setNumPages(numPages);
    }
    let wd = (window.innerWidth / 100) * 50;

    return (
      <div
        style={{
          width: "100%",
          margin: "0 5px",
          display: "flex",
          justifyContent: "center",
          overflowX: "scroll",
          backgroundColor: "rgb(236, 236, 236)",
          borderRadius: "7px",
          height: "100%",
        }}
      >
        <Document file={filePdf} onLoadSuccess={onDocumentLoadSuccess}>
          {Array(numPages)
            .fill()
            .map((_, i) => (
              <Page width={wd} pageNumber={i + 1}></Page>
            ))}
        </Document>
      </div>
    );
  }

 

  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          overflowX: "scroll",
          backgroundColor: "rgb(236, 236, 236)",
          borderRadius: "7px",
          height: "100%",
        }}
      >
        <MyPDF></MyPDF>
      </div>
    </>
  );
};
export default Article;
