import Jwt_proxy from "src/components/API/Api";
import ReactToPrint from "react-to-print";
import { useState, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PopupTwooLevel from "../Popup/PopupTwooLevel";
import Tree from "../MainLeftToolbar/Tree/Tree";
const ArticleToolbar = ({
  redArticle,
  exportD,
  itsArticleHistory,
  setFirstArticleLoad,
  setRedArticle,
  onRedact,
  file_id,
  deleteFile,
  deleteDiagram,
  fileId,
  GoArticleToRedact,
  reff,
  expanded_toolbar,
  pidChanged,
  setPidChanged,
  red,
  fullscreen,
  setFullscreen,
  setHistoryArticleData,
  firstArticleLoad,
  setArticleHistory,
  setSeectionArticlesPageList,
  setHistoryPopup,
  getHist,
  toggleSelectArticle,
  getLeftArr,
  setSecUndefined,
  toggleSelect,
  setNameAndPathUpdate,
  nameAndPathUpdate,
  getThreePathData,
  parentData,
  setParentData,
  setArticleData,
  ArticleData,
  setArticleWiew,
  getSections,
  selected,
  setSelected,
  getParentId,
  treeUserSection,
  setTreeUserSection

}) => {
 
  const goedit = () => {
    navigate("/wiki/article/update/" + ArticleData.id);
  };
  const [oldpid, setoldpid] = useState(ArticleData.section_id);
 
  useEffect(() => {
    if (!pidChanged) {
      setoldpid(ArticleData.section_id);
    }
  }, [ArticleData.section_id]);
  const curoldpid = oldpid;

  let navigate = useNavigate();
  const postArticle = useCallback(async () => {
    let ip;
    if (redArticle !== true) {
      ip = localStorage.getItem("server_ip") + "/api/wiki/article/create";
    } else {
      ip = localStorage.getItem("server_ip") + "/api/wiki/article/update";
    }
    let token = localStorage.getItem("token");
    let res = await fetch(ip, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-token": token,
      },
      body: JSON.stringify(ArticleData),
    });

    if (res.ok) {
      let r = await res.json();
      setFirstArticleLoad(true);

      var event = new MouseEvent("dblclick", {
        view: window,
        bubbles: true,
        cancelable: true,
      });

      if (pidChanged !== undefined && curoldpid !== r.section_id) {
        if (curoldpid !== "00000000-0000-0000-0000-000000000000") {
          document.getElementById(curoldpid).dispatchEvent(event);
        }
        getLeftArr(ArticleData.section_id, r.id);
        if (toggleSelectArticle !== undefined) {
          toggleSelectArticle(ArticleData.id);
        }
      }
      navigate("/wiki/article/" + r.id);
      if (redArticle !== true) {
        alert("Статья успешно создана");
      } else {
        alert("Статья успешно отредактирована");
      }
    } else if (res.status === 401) {
      alert("Статья с данным названием уже есть в этом разделе");
    } else {
      alert(
        "У вас нет прав на создание/редактирование статей в данном разделе"
      );
    }
  }, [ArticleData, redArticle]);
  const [print, setprint] = useState(false);
  useEffect(() => {
    async function printAccess(id) {
      let ip = localStorage.getItem("server_ip");
      let token = localStorage.getItem("token");
      let result;
      let res = await fetch(ip + "/api/wiki/print_access", {
        headers: {
          "Content-Type": "application/json",
          "x-token": token,
        },
        method: "POST",
        body: JSON.stringify({ id: id }),
      });
      if (res.ok) {
        result = true;
      } else {
        result = false;
      }

      setprint(result);
    }
    if (redArticle === true && file_id === undefined) {
      Jwt_proxy(() => printAccess(ArticleData.section_id));
    }
  });

  async function deleteArticle() {
    let ip = localStorage.getItem("server_ip");
    let token = localStorage.getItem("token");
    let res = await fetch(ip + "/api/wiki/article/delete", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-token": token,
      },
      body: JSON.stringify({ id: ArticleData.id }),
    });

    if (res.ok) {
      navigate("/wiki/section/" + ArticleData.section_id);
      alert("Статья удалена");
      Jwt_proxy(() => getLeftArr(ArticleData.section_id));
    } else {
      alert(
        "У вас нет прав на создание/редактирование статей в данном разделе"
      );
    }
  }

  const [showHTMLData, setShowHTMLData] = useState([]);
  const [ShowPopup, setShowPopup] = useState({
    show: false,
  });

  async function uploadFile() {
    let ip = localStorage.getItem("server_ip");
    let token = localStorage.getItem("token");
    if (
      ArticleData.name !== null &&
      ArticleData.name.length > 0 &&
      ArticleData.section_id !== null &&
      fileId !== null
    ) {
      let res = await fetch(ip + "/api/wiki/file/upload", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-token": token,
        },
        body: JSON.stringify({
          name: ArticleData.name,
          pid: ArticleData.section_id,
          description: ArticleData.abstruct,
          id: fileId,
          code: ArticleData.code,
          title_font_color: ArticleData.title_font_color,
        }),
      });

      if (res.ok) {
        let r = await res.json();
        navigate("/wiki/section/" + ArticleData.section_id);
        Jwt_proxy(() => getLeftArr(ArticleData.section_id, r.id));
      } else if (res.status === 402) {
        alert("Файл уже добавлен в данный раздел");
      } else {
        alert(
          "У вас нет прав на создание/редактирование файлов в данном разделе"
        );
      }
    } else {
    }
  }

  async function uploadFileUpdate() {
    let ip = localStorage.getItem("server_ip");
    let token = localStorage.getItem("token");
    if (
      ArticleData.name !== null &&
      ArticleData.name.length > 0 &&
      ArticleData.section_id !== null &&
      fileId !== null
    ) {
      let res = await fetch(ip + "/api/wiki/upload_file/update", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-token": token,
        },
        body: JSON.stringify({
          name: ArticleData.name,
          pid: ArticleData.section_id,
          description: ArticleData.abstruct,
          file_id: fileId,
          id: file_id,
          code: ArticleData.code,
          title_font_color: ArticleData.title_font_color,
        }),
      });

      if (res.ok) {
        let r = await res.json();
        navigate("/wiki/file/" + r.id);
        Jwt_proxy(() => getLeftArr(ArticleData.section_id, r.id));
        var event = new MouseEvent("dblclick", {
          view: window,
          bubbles: true,
          cancelable: true,
        });

        if (pidChanged !== undefined && curoldpid !== ArticleData.section_id) {
          if (curoldpid !== "00000000-0000-0000-0000-000000000000") {
            document.getElementById(curoldpid).dispatchEvent(event);
          }
        }
      } else {
        alert(
          "У вас нет прав на создание/редактирование файлов в данном разделе"
        );
      }
    } else {
    }
  }

  function onShowPopup() {
    setShowPopup((ShowPopup) => ({ ...ShowPopup, show: !ShowPopup.show }));
  }
  function setParentId(i) {
    setArticleData((ArticleData) => ({ ...ArticleData, section_id: i.id }));
    alert("Раздел изменен");
    setRedArticle(true);
  }
  const [isprint, setisprint] = useState(print);
  const cfg = {
    hr: "–",
    red: setParentId,
    showPopup: ShowPopup,
    setShowPopup: onShowPopup,
  };

  async function getLeftArrPopup() {
    let ip = localStorage.getItem("server_ip") + "/api/wiki/get_way";
    let token = localStorage.getItem("token");
    let res = await fetch(ip, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-token": token,
      },
      body: JSON.stringify({ id: ArticleData.section_id }),
    });
    if (res.ok) {
      let r = await res.json();
      if (ArticleData.id !== "00000000-0000-0000-0000-000000000000") {
        if (r.includes("00000000-0000-0000-0000-000000000000")) {
          Jwt_proxy(() =>
            getSections(
              "00000000-0000-0000-0000-000000000000",
              setShowHTMLData,
              cfg,
              null,
              true,
              r,
             
            )
          );
        } else {
          Jwt_proxy(() =>
            getSections(
              "00000000-0000-0000-0000-000000000000",
              setShowHTMLData,
              cfg,
              null,
              true,
            )
          );
          setTreeUserSection((treeUserSection) => ({
            ...treeUserSection,
            ids_list: r,
            haveFolderInDb: true,
          }));
        }
      }
    }
  }
  function getForPopup() {
    // Jwt_proxy(() =>
    //   getSections("00000000-0000-0000-0000-000000000000", setShowHTMLData, cfg)
    // );
    getLeftArrPopup();
  }
  function ftc() {
    setRedArticle(true);
    Jwt_proxy(() => GoArticleToRedact(ArticleData.id));
    onRedact();
  }

  return (
    <>
      <div className="ArticleToolbar">
        {/* {!red ? (
          <>
            <button
              className="articleToolbarBtn r"
              title="Редактировать"
              style={{ lineHeight: "calc(8px + 20 * (100vw / 1920))" }}
              onClick={() => {
                if (ArticleData.id !== null) {
                  if (itsArticleHistory === false) {
                    ftc();
                    goedit();
                  }
                }
              }}
            >
              <img src="/static/img/Group 227.svg" alt="" />
            </button>
          </>
        ) : null} */}

        {expanded_toolbar ? (
          <>
            <button
              className="articleToolbarBtn"
              title="Сохранить"
              style={{ lineHeight: "calc(8px + 20 * (100vw / 1920))" }}
              onClick={() => {
                if (
                  ArticleData.body_type !== 3 &&
                  ArticleData.body_type !== 4
                ) {
                  if (itsArticleHistory === false) {
                    Jwt_proxy(postArticle);
                    onRedact();
                  }
                } else if (ArticleData.body_type === 3) {
                  if (file_id !== undefined) {
                    Jwt_proxy(uploadFileUpdate);
                  } else {
                    Jwt_proxy(uploadFile);
                  }
                } else if (ArticleData.body_type === 4) {
                  Jwt_proxy(exportD);
                }
                setTimeout(() => {
                  setArticleData((ArticleData) => ({
                    ...ArticleData,
                    abstruct: null,
                    wiki_article_type_id: 1,
                    create_date: new Date(),
                    code: null,
                    title_font_color: null,
                  }));
                }, 500);
              }}
            >
              <img src="/static/img/Group 222.svg" alt="" />
            </button>
          </>
        ) : null}
        {red ? (
          <button
            className="articleToolbarBtn"
            title="Переместить"
            onClick={() => {
              if (itsArticleHistory === false) {
                setRedArticle(true);
                getForPopup();
                onShowPopup();
              }
            }}
          >
            <img src="/static/img/Group 228.svg" alt="" />
          </button>
        ) : null}
        {/* <button
          className="articleToolbarBtn"
          title="Удалить"
          style={{ lineHeight: "calc(8px + 20 * (100vw / 1920))" }}
          onClick={() => {
            if (itsArticleHistory === false) {
              if (file_id !== undefined) {
                Jwt_proxy(deleteFile)
              } else if(ArticleData.body_type===1||ArticleData.body_type===2) {
                Jwt_proxy(deleteArticle);
              }
              else if (ArticleData.body_type===4){
                Jwt_proxy(deleteDiagram)
              }
            }
          }}
        >
          <img src="/static/img/Group 252.svg" alt="" />
        </button> */}
        {print === true && !red ? (
          <ReactToPrint
            trigger={() => {
              return (
                <button className="articleToolbarBtn" title="Печать">
                  {" "}
                  <img src="/static/img/Group 251.svg" alt="" />
                </button>
              );
            }}
            content={() => reff.current}
            documentTitle={""}
          />
        ) : null}
        {ArticleData.body_type === 4 ? (
          <button
            className="articleToolbarBtn"
            title="Весь экран"
            style={{ lineHeight: "calc(8px + 20 * (100vw / 1920))" }}
            onClick={() => {
              setFullscreen(!fullscreen);
            }}
          >
            <img src="/static/img/fscreen.svg" alt="" />
          </button>
        ) : null}
      </div>
      {ShowPopup.show ? (
        <PopupTwooLevel
          child={
            <div className="TreeWrapper TreeWrapperSection" id="TreePopupRight">
              {showHTMLData}
              {<Tree
                 setHistoryArticleData={setHistoryArticleData}
                 firstArticleLoad={firstArticleLoad}
                 setArticleHistory={setArticleHistory}
                 article_id={treeUserSection.article_id}
                  ids_list={treeUserSection.ids_list}
                  setFirstArticleLoad={setFirstArticleLoad}
                  setSeectionArticlesPageList={setSeectionArticlesPageList}
                  setHistoryPopup={setHistoryPopup}
                  getHist={getHist}
                  toggleSelectArticle={toggleSelectArticle}
                  getLeftArr={getLeftArr}
                  setSecUndefined={setSecUndefined}
                  toggleSelect={toggleSelect}
                  setNameAndPathUpdate={setNameAndPathUpdate}
                  nameAndPathUpdate={nameAndPathUpdate}
                  getThreePathData={getThreePathData}
                  parentData={parentData}
                  setParentData={setParentData}
                  setArticleData={setArticleData}
                  ArticleData={ArticleData}
                  setArticleWiew={setArticleWiew}
                  getSections={getSections}
                  selected={selected}
                  setSelected={setSelected}
                  getParentId={getParentId}
                  cfg={cfg}
                  data={treeUserSection}
                  have_user_section={true}
                  is_user_folder={true}
                />}
            </div>
          }
          onShow={onShowPopup}
        />
      ) : null}
    </>
  );
};

export default ArticleToolbar;
