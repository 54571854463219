import Jwt_proxy from "../API/Api";
// import config from "../../config/config";
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
// import CandidatsList from "./candidatList";
import Datefunc from "../wiki/DateFunc";
const TiketForm = ({
  setCandidatsList,
  formData,
  languages,
  setFormData,
  inputData,
  setInputData,
  applicationId,
  setlanguages,
  candidatsList,
  acceptetCandidats,
  setAcceptetCandidats,
}) => {
  const { Id } = useParams();
  let Datefunction = new Datefunc();
  async function getApplicationonId() {
    const ip = localStorage.getItem("server_ip");
    const token = localStorage.getItem("token");
    let res = await fetch(ip + "/api/hr/list/get_application", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-token": token,
      },
      body: JSON.stringify({
        id: applicationId,
        username: localStorage.getItem("username"),
      }),
    });
    if (res.ok) {
      let r = await res.json();
      setFormData({
        ...r[0],
        desired_deadline:
          r[0].desired_deadline !== null
            ? Datefunction.nowDateI(r[0].desired_deadline)
            : null,
        actual_execution_applicant:
          r[0].actual_execution_applicant !== null
            ? Datefunction.nowDateI(r[0].actual_execution_applicant)
            : null,
        actual_execution_hr:
          r[0].actual_execution_hr !== null
            ? Datefunction.nowDateI(r[0].actual_execution_hr)
            : null,
      });
      setInputData(r[1]);
      setlanguages(r[2]);
      setCandidatsList(r[3]);
      setAcceptetCandidats(r[3].filter((i) => i.accepted));
      setIsHr(r[4]);
      Jwt_proxy(() => getPerson(r[0]["applicant"]));
    }
  }

  useEffect(() => {
    if (Id !== undefined) {
      Jwt_proxy(() => getApplicationonId(Id));
    } else {
      Jwt_proxy(getPerson);
    }
  }, []);

  async function getPerson(user_id) {
    const username = localStorage.getItem("username");
    const ip = localStorage.getItem("server_ip");
    const token = localStorage.getItem("token");
    let data;
    if (Id !== undefined) {
      data = { user_id: user_id, username: null };
    } else {
      data = { username: username, user_id: null };
    }
    let res = await fetch(ip + "/api/hr/list/get_person", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-token": token,
      },
      body: JSON.stringify(data),
    });
    if (res.ok) {
      let r = await res.json();
      setFormData((formData) => ({
        ...formData,
        applicant: r[0].id,
      }));
      setInputData((inputData) => ({ ...inputData, applicant: r[0].name }));
      if (r[1] !== undefined) {
        setIsHr(r[1]);
      }
    }
  }
  function checked(e) {
    setFormData((formData) => ({
      ...formData,
      [e.target.name]: !formData[e.target.name],
    }));
  }
  function driverlicensechecked() {
    setFormData((formData) => ({
      ...formData,
      driver_license: !formData.driver_license,
    }));
    if (formData.driver_license_category === null) {
      setFormData((formData) => ({
        ...formData,
        driver_license_category: 1,
      }));
    } else {
      setFormData((formData) => ({
        ...formData,
        driver_license_category: null,
      }));
    }
  }
  function languageschecked() {
    setFormData((formData) => ({
      ...formData,
      languages: !formData.languages,
    }));
  }

  function onChangeApp(e) {
    setFormData((formData) => ({
      ...formData,
      [e.target.name]: e.target.value,
    }));
  }
  function onChangeAppNumber(e) {
    e.target.value = e.target.value.replace(/[^+\d]/g, "");
    setFormData((formData) => ({
      ...formData,
      [e.target.name]: e.target.value,
    }));

    if (e.target.name === "desired_quantity") {
      let vac_closed = formData.vacancies_closed;
      let vac_to_work = +e.target.value - +vac_closed;
      setFormData((formData) => ({
        ...formData,
        vacancies_to_work: vac_to_work,
      }));
    }
  }
  function onChangelang(e) {
    setlanguages((languages) => ({
      ...languages,
      [e.target.name]: e.target.value,
    }));
  }
  function onChangeCheck(e) {
    setFormData((formData) => ({
      ...formData,
      [e.target.name]: !formData[e.target.name],
    }));
  }
  let navigate = useNavigate();
  const goEdit = () => {
    window.location.reload();
  };
  const goHr = () => {
    navigate("/hr");
  };

  const Tree = ({ data, oldata, namev, hr }) => {
    const { name, id } = data;
    const [active, setActive] = useState(false);
    let classLists = "TreeBody";
    if (active) {
      classLists += " acActive";
    }

    let dat = [
      ...oldata.map((i, n) =>
        i.pid === id ? (
          <Tree
            data={i}
            id={i.id}
            pid={i.pid}
            oldata={oldata}
            namev={namev}
            hr={"—"}
          />
        ) : null
      ),
    ];
    let value = namev.replace("_id", "");
    function sel() {
      setInputData((inputData) => ({
        ...inputData,
        [value]: name,
      }));
      setFormData((formData) => ({
        ...formData,
        [namev]: id,
      }));
      setPopupActive(false);
    }

    let [buttonToggle, setButtonToggle] = useState("+");
    function ButtonToggleOpen() {
      if (buttonToggle === "+") {
        setButtonToggle((buttonToggle = "-"));
      } else {
        setButtonToggle((buttonToggle = "+"));
      }
    }

    return (
      <>
        <div className={"TreeGroupItem"}>
          <div className={"TreeGroupItemTitle"} id={id}>
            {hr}{" "}
            <button
              type="button"
              className="TreeOpenButton"
              onClick={() => {
                ButtonToggleOpen();
                setActive(!active);
              }}
            >
              {buttonToggle}
            </button>
            <img src="/static/img/Group 187.svg" alt=""></img>
            <span
              onClick={() => {
                sel();
              }}
              className="strong"
            >
              {name}
            </span>
          </div>
          <div className={classLists}>{dat}</div>
        </div>
      </>
    );
  };

  useEffect(() => {
    setFormData((formData) => ({
      ...formData,
      vacancies_closed: candidatsList.filter((i) => i.accepted).length,
    }));
  }, [candidatsList]);

  const [popupActive, setPopupActive] = useState(false);
  const [popupData, setpopupData] = useState({
    child: "",
    click: "",
  });
  function GenPopup(e, n) {
    setpopupData((popupData) => ({ ...popupData, child: e, click: n }));
  }
  const AcceptedCandidatsTable = ({
    data,
    candidatsList,
    setCandidatsList,
  }) => {
    return (
      <div
        style={{
          border: "1px solid black",
          height: "100px",
          overflowY: "scroll",
          width: "100%",
        }}
      >
        <table>
          <thead>
            <tr>
              <th> ФИО кандидата</th>
              <th>Дата приема</th>
            </tr>
          </thead>
          <tbody>
            {data.map((i) =>
              i.accepted ? (
                <tr>
                  <td>{i.full_name_of_candidat}</td>
                  <td>
                    {Datefunction.nowDateInput(i.candidate_admission_date)}
                  </td>
                </tr>
              ) : null
            )}
          </tbody>
        </table>
      </div>
    );
  };
  const PopupTable = ({ data, name, toggle }) => {
    const [tabledata, settabledata] = useState(data);
    let value = name.replace("_id", "");
    let [select, setSelect] = useState();
    const handleClick = (dat) => {
      setSelect((select = dat.id));
      setInputData((inputData) => ({
        ...inputData,
        [value]: dat.name,
      }));
      setFormData((formData) => ({
        ...formData,
        [name]: dat.id,
      }));
      setPopupActive(false);
    };
    function filter(items, term) {
      if (term.length === 0) {
        return items;
      }
      return items.filter((item) => {
        return item.name.toLowerCase().includes(term.toLowerCase());
      });
    }
    function onFilterChange(e) {
      settabledata(filter(data, e.target.value));
    }
    return (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "80%",
            alignItems: "center",
            top: "2%",
            position: "fixed",
          }}
        >
          <input placeholder="Поиск" onChange={onFilterChange} />
        </div>
        <table>
          <tbody className="appPopup">
            {tabledata.map((dat) => (
              <tr
                key={dat.id}
                name={name}
                style={select === dat.id ? { backgroundColor: "gray" } : {}}
                id={dat.id}
                onClick={() => handleClick(dat)}
              >
                {typeof dat.id === "number" ? <td>{dat.id}</td> : null}
                <td>{dat.name}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </>
    );
  };
  async function getDataFromPopup(path, name) {
    const ip = localStorage.getItem("server_ip");
    const token = localStorage.getItem("token");
    let res = await fetch(ip + path, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-token": token,
      },
      body: JSON.stringify({}),
    });
    if (res.ok) {
      let r = await res.json();
      GenPopup(<PopupTable data={r} name={name} hr={"—"} toggle={false} />);
    }
  }
  async function getDataFromPopupTree(path, name) {
    const ip = localStorage.getItem("server_ip");
    const token = localStorage.getItem("token");
    let res = await fetch(ip + path, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-token": token,
      },
      body: JSON.stringify({}),
    });
    if (res.ok) {
      let r = await res.json();
      r = [
        ...r.map((i, n) =>
          i.pid == null ? (
            <Tree
              data={i}
              id={i.id}
              pid={i.pid}
              oldata={r}
              namev={name}
              hr={"—"}
            />
          ) : null
        ),
      ];
      GenPopup(
        <div className="scroll" style={{ maxHeight: "30vh" }}>
          {r}
        </div>
      );
    }
  }
  const [isHr, setIsHr] = useState(false);
  const PopupSelect = ({ children, OnClickFetch, popupActive }) => {
    return (
      <div
        className="PopupSelect"
        style={popupActive ? { display: "block" } : { display: "none" }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            margin: "-20px  0 20px 20px",
            zIndex: "10",
            width: "100%",
            posicion: "absolute",
          }}
        >
          <button
            style={{ border: "none", backgroundColor: "white" }}
            onClick={() => {
              setPopupActive(false);
            }}
          >
            X
          </button>
        </div>
        <div style={{ width: "100%", maxHeight: "200px", overflow: "scroll" }}>
          <div style={{ paddingBottom: "20px" }}>{children}</div>
        </div>
      </div>
    );
  };

  async function postApplication() {
    let ip = localStorage.getItem("server_ip");
    if (applicationId !== "null") {
      ip += "/api/hr/list/update";
    } else {
      ip += "/api/hr/list/create";
    }

    let application = Object.assign(formData, {});
    for (let i in application) {
      if (application[i] === "") {
        application[i] = null;
      }
    }

    const token = localStorage.getItem("token");
    let res = await fetch(ip, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-token": token,
      },
      body: JSON.stringify({
        app: application,
        lang: languages,
        candidats: candidatsList,
      }),
    });
    if (res.ok) {
      goHr();
    }
  }

  return (
    <div style={{ height: "100vh", width: "100%" }}>
      <div className="PersonelFormWrapper">
        <div
          style={{
            width: "50%",
            height: "50px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <button
            className="wikiButton"
            onClick={() => {
              Jwt_proxy(postApplication);
            }}
          >
            Записать
          </button>
          <button className="wikiButton" onClick={goEdit}>
            Обновить
          </button>
          <button className="wikiButton" onClick={goHr}>
            Отменить
          </button>
        </div>
        <div className="TitleBlock row ThreeItem">
          <h1>ЗАЯВКА НА ПОДБОР ПЕРСОНАЛА</h1> <span>{formData.id}</span> от{" "}
          <span>{Datefunction.nowDateInput(formData.date)}</span>
          <br />
        </div>
        <div className="headhrbuttons"></div>
        <div className="OneItemMin row">
          <div className="inputblock">
            <span>Заявитель</span>
            <input type="text" value={inputData.applicant} />
          </div>
        </div>
        <div className="PersonalFormBodyBody">
          <div className="row ThreeItemLastMin">
            <div className="inputblock">
              <span>Должность</span>
              <input
                name="vacancy_name"
                type="text"
                value={formData.vacancy_name}
                onChange={onChangeApp}
              />
            </div>
            <div className="inputblock">
              <span>Должность по классификатору</span>
              <div className="HaveBtn">
                <input type="text" value={inputData.job_position} />
                <button
                  className="popupBtn"
                  onClick={() => {
                    Jwt_proxy(() =>
                      getDataFromPopup(
                        "/api/hr/list/get_job_positions",
                        "job_position_id"
                      )
                    );
                    setPopupActive(!popupActive);
                  }}
                >
                  ...
                </button>
              </div>
            </div>
            <div className="inputblock">
              <span>Требуемое количество</span>
              <input
                type="text"
                value={formData.desired_quantity}
                name="desired_quantity"
                onChange={onChangeAppNumber}
              />
            </div>
          </div>
          <div className="row TwoItemMin">
            <div className="inputblock">
              <span>Отдел</span>
              <div className="HaveBtn">
                <input type="text" value={inputData.department} />
                <button
                  className="popupBtn"
                  onClick={() => {
                    Jwt_proxy(() =>
                      getDataFromPopupTree(
                        "/api/hr/list/get_deportaments",
                        "department_id"
                      )
                    );
                    setPopupActive(!popupActive);
                  }}
                >
                  ...
                </button>
              </div>
            </div>
            <div className="inputblock">
              <span>Город</span>
              <div className="HaveBtn">
                <input type="text" value={inputData.city} />
                <button
                  className="popupBtn"
                  onClick={() => {
                    Jwt_proxy(() =>
                      getDataFromPopup("/api/hr/list/get_citys", "city_id")
                    );
                    setPopupActive(!popupActive);
                  }}
                >
                  ...
                </button>
              </div>
            </div>
          </div>
          <div className="row OneItem">
            <div className="inputblock">
              <span>Необходимое образование, специальность</span>
              <textarea
                value={formData.education_specialty_description}
                name="education_specialty_description"
                id=""
                cols="30"
                row
                s="10"
                onChange={onChangeApp}
              ></textarea>
            </div>
          </div>
          <div className="row ThreeItemMin">
            <div className="inputblock">
              <span>Опыт работы от</span>
              <input
                type="numler"
                value={formData.years_of_experience}
                name="years_of_experience"
                onChange={onChangeApp}
              />
            </div>
            <div className="inputblock">
              <span>Возраст от до</span>
              <div className="inputblockTwo row">
                <input
                  type="text"
                  name="age_from"
                  value={formData.age_from}
                  onChange={onChangeAppNumber}
                />
                <input
                  type="text"
                  name="age_to"
                  value={formData.age_to}
                  onChange={onChangeAppNumber}
                />
              </div>
            </div>
            <div className="inputblock">
              <span>пол</span>
              <div className="HaveBtn">
                <select
                  name="sex"
                  value={formData.sex}
                  onChange={onChangeApp}
                  id=""
                >
                  <option>Не важен</option>
                  <option>Мужской</option>
                  <option>Женский</option>
                </select>
              </div>
            </div>
          </div>
          <div className="row TwoItemMin">
            <div className="inputblock">
              <span>ЗП на ИС на руки</span>
              <input
                name="salary_for_probation"
                type="text"
                value={formData.salary_for_probation}
                onChange={onChangeAppNumber}
              />
            </div>
            <div className="inputblock">
              <span>ЗП после ИС на руки</span>
              <input
                value={formData.salary_after_probation}
                name="salary_after_probation"
                type="text"
                onChange={onChangeAppNumber}
              />
            </div>
          </div>

          <div className="row TwoItem">
            <div className="inputblock">
              <span>График работы</span>
              <div className="HaveBtn">
                <input type="text" value={inputData.work_schedule} />
                <button
                  className="popupBtn"
                  onClick={() => {
                    Jwt_proxy(() =>
                      getDataFromPopup(
                        "/api/hr/list/get_work_schedules",
                        "work_schedule_id"
                      )
                    );
                    setPopupActive(!popupActive);
                  }}
                >
                  ...
                </button>
              </div>
            </div>
            <div className="inputblock">
              <span>Комментарий к графику работы</span>
              <input
                value={formData.work_schedule_description}
                type="text"
                name="work_schedule_description"
                onChange={onChangeApp}
              />
            </div>
          </div>
          {/*  */}
          <div className="row ThreeItem">
            <div className="inputblock">
              <span>Осннование открытия вакансии</span>
              <select
                name="base_vacancy_opening_id"
                id=""
                value={formData.base_vacancy_opening_id}
                style={{ width: "100%" }}
                onChange={onChangeApp}
              >
                <option value="1">согласно плану развития</option>
                <option value="2">увольнение сотрудника</option>
                <option value="3">новая внеплановая вакансия</option>
              </select>
            </div>
            <div className="inputblock" style={{ width: "100%" }}>
              <span>
                Подчинение
                {" (должность)"}
              </span>
              <input
                type="text"
                value={formData.subordination}
                name="subordination"
                onChange={onChangeApp}
              />
            </div>
            <div className="inputblock" style={{ width: "100%" }}>
              <span>Телефон</span>
              <input
                type="text"
                value={formData.phone}
                name="phone"
                onChange={onChangeAppNumber}
              />
            </div>
          </div>
          <div className="row ThreeItem">
            <div className="inputblock haveCheckBox" style={{ width: "122%" }}>
              <span>Должностная инструкция прилогается</span>
              <input
                type="checkbox"
                checked={
                  formData.job_description_attached === true ? "checked" : ""
                }
                name="job_description_attached"
                onChange={onChangeCheck}
              />
            </div>
            <div className="inputblock haveCheckBox" style={{ width: "115%" }}>
              <span>
                Индивидуальная/коллективная материальная ответственность
              </span>
              <input
                type="checkbox"
                checked={formData.material_liability === true ? "checked" : ""}
                name="material_liability"
                onChange={onChangeCheck}
              />
            </div>
            <div className="inputblock haveCheckBox" style={{ width: "122%" }}>
              <span>Оплата проезда</span>
              <input
                type="checkbox"
                checked={formData.fare_payment === true ? "checked" : ""}
                name="fare_payment"
                onChange={onChangeCheck}
              />
            </div>
          </div>
          <div className="row ThreeItem" style={{alignItems:'end'}}>
            <div className="inputblock" style={{ width: "100%" }}>
              <span>Количество сотрудников в подчинении</span>
              <input
                type="text"
                value={formData.quantity_of_employees_under_supervision}
                name="quantity_of_employees_under_supervision"
                onChange={onChangeAppNumber}
              />
            </div>
            <div className="inputblock haveCheckBox" style={{ width: "127.5%" }}>
              <span>Оплата мобильного телефона</span>
              <input
                type="checkbox"
                checked={
                  formData.communication_payment === true ? "checked" : ""
                }
                name="communication_payment"
                onChange={onChangeCheck}
              />
            </div>
            <div className="inputblock" style={{ width: "100%" }}>
              <span>Командировки {' (% рабочего времени)'}</span>
              <input
                type="text"
                value={formData. business_trips_percentage_of_working_time}
                name="business_trips_percentage_of_working_time"
                onChange={onChangeAppNumber}
              />
            </div>
          </div>
          <div className="row OneItem">
            <div className="inputblock">
              <span>Описание работы</span>
              <textarea
                value={formData.job_description}
                name="job_description"
                id=""
                cols="30"
                row
                s="10"
                onChange={onChangeApp}
              ></textarea>
            </div>
          </div>
          <div className="row ThreeItem">
            <div className="inputblock">
              <span>Собеседование начать с</span>
              <input type="text" />
            </div>
            <div className="inputblock">
              <span>Комментарий к собеседованию</span>
              <input
                value={formData.interview_description}
                type="text"
                name="interview_description"
                onChange={onChangeApp}
              />
            </div>
            <div className="inputblock">
              <span>Удобное время для собеседований</span>
              <input
                value={formData.interview_convenient_time}
                type="time"
                name="interview_convenient_time"
                onChange={onChangeApp}
              />
            </div>
          </div>

          <div className="row OneItem">
            <div className="inputblock">
              <span>Требования к кандидату обязательные</span>
              <textarea
                value={formData.mandatory_requirements}
                name="mandatory_requirements"
                id=""
                cols="30"
                row
                s="10"
                onChange={onChangeApp}
              ></textarea>
            </div>
          </div>
          <div className="row OneItem">
            <div className="inputblock">
              <span>Требования к кандидату желательные</span>
              <textarea
                value={formData.desirable_requirements}
                name="desirable_requirements"
                id=""
                cols="30"
                row
                s="10"
                onChange={onChangeApp}
              ></textarea>
            </div>
          </div>
          <div className="row OneItem">
            <div className="inputblock">
              <span>Деловые и личностные качества</span>
              <textarea
                value={formData.business_personal_qualities}
                name="business_personal_qualities"
                id=""
                cols="30"
                row
                s="10"
                onChange={onChangeApp}
              ></textarea>
            </div>
          </div>
          <div className="row TwoItemhaveCheckBox">
            <div className="inputblock haveCheckBox">
              <span>Водительские права</span>
              <input
                type="checkbox"
                checked={formData.driver_license === true ? "checked" : ""}
                name="driver_license"
                onChange={driverlicensechecked}
              />
            </div>
            <div className="inputblock haveSelect">
              <span>Категория</span>
              <select
                disabled={formData.driver_license === false ? "disabled" : ""}
                name="driver_license_category_id"
                id=""
                style={{ width: "40px", marginLeft: "10px" }}
                value={formData.driver_license_category_id}
                onChange={onChangeApp}
              >
                <option value="1">M</option>
                <option value="2">A</option>
                <option value="3">B</option>
                <option value="4">BE</option>
                <option value="5">C</option>
                <option value="6">CE</option>
                <option value="7">D</option>
                <option value="8">DE</option>
              </select>
            </div>
          </div>
          <div className="row OneItemhaveCheckBox">
            <div className="inputblock haveCheckBox">
              <span>Иностранные языки</span>
              <input
                type="checkbox"
                onChange={languageschecked}
                checked={formData.languages === true ? "checked" : ""}
              />
            </div>
          </div>
          <div className="columnTwoItemMin">
            <div className="inputblock haveSelect">
              <span>Английский</span>
              <select
                name={1}
                id=""
                disabled={formData.languages === false ? "disabled" : ""}
                onChange={onChangelang}
                value={languages[1]}
              >
                <option value={null}>Не требуется</option>
                <option value="Со словарем">Со словарем</option>
                <option value="Средний">Средний</option>
                <option value="Продвинутый">Продвинутый</option>
              </select>
            </div>
            <div className="inputblock haveSelect">
              <span>Китайский</span>
              <select
                name={2}
                id=""
                disabled={formData.languages === false ? "disabled" : ""}
                onChange={onChangelang}
                value={languages[2]}
              >
                <option value={null}>Не требуется</option>
                <option value="Со словарем">Со словарем</option>
                <option value="Средний">Средний</option>
                <option value="Продвинутый">Продвинутый</option>
              </select>
            </div>
            <div className="inputblock haveSelect">
              <span>Турецкий</span>
              <select
                name={3}
                id=""
                disabled={formData.languages === false ? "disabled" : ""}
                onChange={onChangelang}
                value={languages[3]}
              >
                <option value={null}>Не требуется</option>
                <option value="Со словарем">Со словарем</option>
                <option value="Средний">Средний</option>
                <option value="Продвинутый">Продвинутый</option>
              </select>
            </div>
          </div>
          <div className="row OneItem">
            <div className="inputblock">
              <span>Навыки владения компьютером</span>
              <textarea
                value={formData.computer_skills}
                name="computer_skills"
                id=""
                cols="30"
                row
                s="10"
                onChange={onChangeApp}
              ></textarea>
            </div>
          </div>
          <div className="row OneItem">
            <div className="inputblock">
              <span>Дополнительные требования</span>
              <textarea
                value={formData.additional_requirements}
                name="additional_requirements"
                id=""
                cols="30"
                row
                s="10"
                onChange={onChangeApp}
              ></textarea>
            </div>
          </div>
          <div className="rowOneItemMin">
            <div className="inputblock row OneItemhaveCheckBoxLong">
              <span>Наличие укомплектованного места</span>
              <input
                type="checkbox"
                name="workplace_availability"
                checked={
                  formData.workplace_availability === true ? "checked" : ""
                }
                onChange={onChangeCheck}
              />
            </div>
          </div>
          <div className="row OneItem">
            <div className="inputblock">
              <span>Указать, чего не хватает</span>
              <textarea
                value={formData.workplace_missing_description}
                name="workplace_missing_description"
                id=""
                cols="30"
                row
                s="10"
                onChange={onChangeApp}
              ></textarea>
            </div>
          </div>
          <div className="row ThreeItemLastMin">
            <div className="inputblock">
              <span>Желаемый срок выполнения заявки</span>
              <input
                type="date"
                name="desired_deadline"
                value={formData.desired_deadline}
                onChange={onChangeApp}
              />
            </div>
            <div className="inputblock">
              <span>
                Фактическое выполнение заявки <br />
                {"(исполнитель)"}
              </span>
              <input
                type="date"
                disabled={!isHr ? "disablet" : ""}
                name="actual_execution_hr"
                value={formData.actual_execution_hr}
                onChange={onChangeApp}
              />
            </div>
            <div className="inputblock">
              <span>
                Фактическое выполнение заявки <br />
                {"(заявитель)"}
              </span>
              <input
                type="date"
                name="actual_execution_applicant"
                onChange={onChangeApp}
                value={formData.actual_execution_applicant}
              />
            </div>
          </div>
          <div className="OneItemMin row">
            <div className="inputblock">
              <span>Исполнитель</span>
              <div className="HaveBtn">
                <input
                  disabled={!isHr ? "disablet" : ""}
                  type="text"
                  value={inputData.executor}
                />
                <button
                  disabled={!isHr ? "disablet" : ""}
                  className="popupBtn"
                  onClick={() => {
                    Jwt_proxy(() =>
                      getDataFromPopup("/api/hr/list/get_hr_list", "executor")
                    );
                    setPopupActive(!popupActive);
                  }}
                >
                  ...
                </button>
              </div>
            </div>
          </div>
          {Id !== undefined ? (
            <div className="OneItemMin row">
              <div className="inputblock">
                <span>ФИО вышедшего сотрудника</span>
                <div className="HaveBtn">
                  <AcceptedCandidatsTable
                    candidats={candidatsList}
                    setcandidats={setCandidatsList}
                    data={candidatsList}
                  />
                </div>
              </div>
            </div>
          ) : null}
          <div className="TwoItemMin row">
            <div className="inputblock">
              <span>Вакансий закрыто</span>
              <input
                disabled="disablet"
                value={formData.vacancies_closed}
                name="vacancies_closed"
                type="text"
                onChange={onChangeApp}
              />
            </div>
            <div className="inputblock">
              <span>Вакансий в работе</span>
              <input
                disabled="disablet"
                value={formData.vacancies_to_work}
                name="vacancies_closed"
                type="text"
                onChange={onChangeAppNumber}
              />
            </div>
          </div>
          <div className="row OneItem">
            <div className="inputblock">
              <span>Примечание</span>
              <textarea
                disabled={!isHr ? "disablet" : ""}
                value={formData.note}
                name="note"
                id=""
                cols="30"
                row
                s="10"
                onChange={onChangeApp}
              ></textarea>
            </div>
          </div>
        </div>
      </div>
      <PopupSelect
        popupActive={popupActive}
        children={popupData.child}
        OnClickFetch={popupData.click}
      />
    </div>
  );
};
export default TiketForm;
