

const PopupSection = ({ data, children, formData, historyPopup }) => {


 
  return (
    <div className="popupColumn">
      <div className="h3check">
        {<input type="checkbox" name={data.name} checked={formData[data.name]?'checked':''} disabled={historyPopup !== false? "disabled" : "" } onChange={data.func} />}
        <h3>{data.title}</h3>
      </div>
      {formData[data.name]?  <div>{children}</div>:null  }
     
    </div>
  );
};
export default PopupSection;
