import React, { useState, useEffect } from "react";
import "./Tree.css";
import Jwt_proxy from "../../../API/Api";

import { Link } from "react-router-dom";
// import config from "../../../../config/config";
const Tree = ({
  data,
  getSections,
  selected,
  setSelected,
  cfg,
  setArticleWiew,
  setFirstArticleLoad,
  setArticleData,
  ArticleData,
  setNameAndPathUpdate,
  nameAndPathUpdate,
  toggleSelect,
  setArticleHistory,
  timeout,
  setHistoryArticleData,
  setHistoryPopup,
  article_id,
  ids_list,
  getParentId,
  have_user_section = true,
  is_user_folder = false,
  section_id,
  setSecUndefined,
  toggleSelectArticle,
  getHist,
  setSeectionArticlesPageList,
  setSeectionSectionsPageList,
  selid,
  hist,
  context = false,
  deleteBtn,
  createBtnArt,
  redactBtn,
  createSecBtn,
  setCoords,
  coords,
  setStowContext
  
}) => {
  const { name, id } = data;
  const [showHTMLData, setShowHTMLData] = useState([]);
  const [filesData, setfilesData] = useState([]);
  const [diagramsData, setdiagramsData] = useState([]);
  const [active, setActive] = useState(false);

  const [showArticles, setShowArticles] = useState([]);
  let flagUrl = cfg.flagUrl;
  let showPopup = cfg.showPopup;
  let articles = cfg.articles;
  let files = cfg.files;
  let diagrams = cfg.diagrams;

  let red = cfg.red;
  let search = cfg.search;
  let hr = !is_user_folder ? cfg.hr : "";
  let open_child_ids = cfg.open_child_ids;
  let open_child_id;
  let left = false;
  let setShowPopup = cfg.setShowPopup;
  if (cfg.left !== undefined) {
    left = true;
  }

  if (open_child_ids !== undefined) {
    open_child_id = [cfg.open_child_ids.length - 1];
  }

  let cfgChild = {
    flagUrl: cfg.flagUrl,
    hr: "—",
    red: cfg.red,
    search: cfg.search,
    files: cfg.files,
    showPopup: cfg.showPopup,
    setShowPopup: cfg.setShowPopup,
    changeId: cfg.changeId,
    diagrams: cfg.diagrams,
    articles: cfg.articles,
    open_child_id:
      open_child_ids !== undefined
        ? open_child_ids[open_child_ids.length - 1]
        : undefined,
    open_child_ids:
      open_child_ids !== undefined
        ? open_child_ids.slice(cfg.open_child_ids.indexOf(open_child_id))
        : undefined,
    left: left,
  };
  let classLists = "TreeBody";

  if (active) {
    classLists += " acActive";
  }

  async function getHistory() {
    let token = localStorage.getItem("token");
    let histres = await fetch(
      localStorage.getItem("server_ip") + "/api/wiki/get_section_history",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-token": token,
        },
        body: JSON.stringify({ id: id }),
      }
    );
    if (histres.ok) {
      let h = await histres.json();
      setArticleHistory(h);
    }
  }
  // if (ids_list !== undefined && ids_list.length > 0) {
  //   if (ids_list.includes(id)) {
  //     alert(1);
  //   }
  //     else {
  //       alert(0);
  //     }
  //   }

  
  
  let [buttonToggle, setButtonToggle] = useState("+");
  function showContextClick(e) {
    if (context) {
      e.preventDefault();
      
      if (document.getElementById(id).classList.contains('select')) {
        const x = e.clientX;
        const y = e.clientY;
        setCoords([x, y]);
        setStowContext(true);

        
      }
    }
  }
  function ButtonToggleOpen() {
    if (buttonToggle === "+") {
      setButtonToggle((buttonToggle = "-"));
    } else {
      setButtonToggle((buttonToggle = "+"));
    }
  }

  if (ids_list !== undefined) {
    delete ids_list[ids_list.indexOf("00000000-0000-0000-0000-000000000000")];

    if (ids_list.includes(id)) {
      if (id !== null) {
        Jwt_proxy(() =>
          getSections(
            id,
            setShowHTMLData,
            cfgChild,
            setShowArticles,
            left,
            ids_list,
            article_id,
            hist,
            setfilesData,
            setdiagramsData
          )
        );
      }
      ids_list.pop();
      if (buttonToggle === "+") {
        setActive(true);
      }
      setButtonToggle((buttonToggle = "-"));
      if (search !== undefined) {
        search(data, false);
      }
    } else {
      ids_list = undefined;
    }
  }

  useEffect(() => {
    if (buttonToggle === "-") {
      if (setArticleHistory !== undefined) {
        if (article_id === undefined) {
          toggleSelect(id);
          Jwt_proxy(getHistory);
          getParentId(id);
        } else {
          setTimeout(() => {
            if (hist) {
              getParentId(id);
              toggleSelectArticle(article_id);
            } else {
              var event = new MouseEvent("click", {
                view: window,
                bubbles: true,
                cancelable: true,
              });

              let cl = document.getElementById(article_id);
              if (cl !== null) {
                cl.dispatchEvent(event);
              }
            }
          }, 400);
        }
      }
      setFirstArticleLoad(false);
    }
  }, [buttonToggle, article_id]);

  return (
    <>
      {flagUrl !== undefined ? (
        <>
          <Link to={have_user_section ? "section/" + id : "/wiki"}>
            <div className={"TreeGroupItem"}>
              <div
                className={"TreeGroupItemTitle"}
                id={id}
                onDoubleClick={() => {
                  setHistoryArticleData(null);
                  getParentId(id);
                  setActive(!active);
                  ButtonToggleOpen();
                  setHistoryPopup(false);
                  setFirstArticleLoad(false);
                }}
                onContextMenu={showContextClick}
                onClick={
                  search !== undefined
                    ? (e) => {
                        if (have_user_section) {
                          Jwt_proxy(() =>
                            getSections(
                              id,
                              setShowHTMLData,
                              cfgChild,
                              setShowArticles,
                              left,
                              undefined,
                              undefined,
                              undefined,
                              setfilesData,
                              setdiagramsData
                            )
                          );
                        }
                        getParentId(id);
                        toggleSelect(id);
                        setArticleWiew(false);
                        search(data, false);
                        if (setArticleHistory !== undefined) {
                          Jwt_proxy(getHistory);
                        }
                        setHistoryArticleData(null);
                        setHistoryPopup(false);
                        setFirstArticleLoad(false);
                      }
                    : (e) => {
                        if (have_user_section) {
                          Jwt_proxy(() =>
                            getSections(
                              id,
                              setShowHTMLData,
                              cfgChild,
                              setShowArticles,
                              left,
                              undefined,
                              undefined,
                              undefined,
                              setfilesData,
                              setdiagramsData
                            )
                          );
                        }
                        getParentId(id);
                        toggleSelect(id);
                        setArticleWiew(false);
                        if (setArticleHistory !== undefined) {
                          Jwt_proxy(getHistory);
                        }
                        setHistoryArticleData(null);
                        setHistoryPopup(false);
                        setFirstArticleLoad(false);
                      }
                }
              >
                {hr}
                <button
                  type="button"
                  style={is_user_folder ? { marginLeft: "9px" } : {}}
                  onClick={
                    search !== undefined
                      ? () => {
                          setHistoryArticleData(null);
                          if (have_user_section) {
                            Jwt_proxy(() =>
                              getSections(
                                id,
                                setShowHTMLData,
                                cfgChild,
                                setShowArticles,
                                left,
                                undefined,
                                undefined,
                                undefined,
                                setfilesData,
                                setdiagramsData
                              )
                            );
                          }
                          getParentId(id);
                          setArticleWiew(false);
                          toggleSelect(id);
                          search(data, false);
                          setActive(!active);
                          ButtonToggleOpen();
                          setHistoryPopup(false);
                          setFirstArticleLoad(false);
                        }
                      : () => {
                          setHistoryArticleData(null);
                          getParentId(id);
                          if (have_user_section) {
                            Jwt_proxy(() =>
                              getSections(
                                id,
                                setShowHTMLData,
                                cfgChild,
                                setShowArticles,
                                left,
                                undefined,
                                undefined,
                                undefined,
                                setfilesData,
                                setdiagramsData
                              )
                            );
                          }
                          ButtonToggleOpen();
                          setActive(!active);
                          setArticleWiew(false);
                          setHistoryPopup(false);
                          setFirstArticleLoad(false);
                        }
                  }
                  className="TreeOpenButton"
                >
                  {buttonToggle}
                </button>
                {data.deleted ? (
                  <img src="/static/img/Group 187_deleted.svg" alt=""></img>
                ) : (
                  <img src="/static/img/Group 187.svg" alt=""></img>
                )}

                <span
                  className="strong"
                  style={{ color: data.title_font_color }}
                >
                  {name}
                </span>
              </div>
              <div className={classLists}>
                {showHTMLData}
                {articles === true ? showArticles : null}
                {files === true ? filesData : null}
                {diagrams === true ? diagramsData : null}
              </div>
            </div>
          </Link>
          
        </>
      ) : (
        <div className={"TreeGroupItem"}>
          <div
            className={"TreeGroupItemTitle"}
            id={id}
            onDoubleClick={() => {
              setHistoryArticleData(null);
              setHistoryPopup(false);
              ButtonToggleOpen();
              getParentId(id);
              setActive(!active);
              if (red !== undefined) {
                red(data, false);
                if (setShowPopup){
                  setShowPopup(!showPopup);
                }
                
              }
              setFirstArticleLoad(false);
            }}
            onClick={
              search !== undefined
                ? (e) => {
                    setHistoryArticleData(null);
                    Jwt_proxy(() =>
                      getSections(
                        id,
                        setShowHTMLData,
                        cfgChild,
                        setShowArticles,
                        left,
                        undefined,
                        undefined,
                        undefined,
                        setfilesData,
                        setdiagramsData
                      )
                    );
                    getParentId(id);
                    toggleSelect(id);
                    setArticleWiew(false);
                    setHistoryPopup(false);
                    search(data, false);
                    setFirstArticleLoad(false);
                  }
                : (e) => {
                    setHistoryArticleData(null);
                    Jwt_proxy(() =>
                      getSections(
                        id,
                        setShowHTMLData,
                        cfgChild,
                        setShowArticles,
                        left,
                        undefined,
                        undefined,
                        undefined,
                        setfilesData,
                        setdiagramsData
                      )
                    );
                    getParentId(id);
                    setHistoryArticleData(null);
                    toggleSelect(id);
                    setArticleWiew(false);
                    setHistoryPopup(false);
                    setFirstArticleLoad(false);
                  }
            }
          >
            {"—"}
            <button
              type="button"
              onClick={
                search !== undefined
                  ? () => {
                      setHistoryArticleData(null);
                      Jwt_proxy(() =>
                        getSections(
                          id,
                          setShowHTMLData,
                          cfgChild,
                          setShowArticles,
                          left,
                          undefined,
                          undefined,
                          undefined,
                          setfilesData,
                          setdiagramsData
                        )
                      );
                      setHistoryArticleData(null);
                      setHistoryPopup(false);
                      toggleSelect(id);
                      getParentId(id);
                      setArticleWiew(false);
                      search(data, false);
                      ButtonToggleOpen();
                      setActive(!active);
                      setFirstArticleLoad(false);
                    }
                  : () => {
                      setHistoryArticleData(null);
                      setHistoryPopup(false);
                      Jwt_proxy(() =>
                        getSections(
                          id,
                          setShowHTMLData,
                          cfgChild,
                          setShowArticles,
                          left,
                          undefined,
                          undefined,
                          undefined,
                          setfilesData,
                          setdiagramsData
                        )
                      );
                      setHistoryArticleData(null);
                      getParentId(id);
                      ButtonToggleOpen();
                      setActive(!active);
                      setArticleWiew(false);
                      setFirstArticleLoad(false);
                    }
              }
              className="TreeOpenButton"
            >
              {buttonToggle}
            </button>
            {data.deleted ? (
              <img src="/static/img/Group 187_deleted.svg" alt=""></img>
            ) : (
              <img src="/static/img/Group 187.svg" alt=""></img>
            )}

            <span className="strong">{name}</span>
          </div>
          <div className={classLists}>
            {showHTMLData}
            {articles === true ? showArticles : null}
            {files === true ? showArticles : null}
            {diagrams === true ? diagramsData : null}
          </div>
        </div>
      )}
    </>
  );
};

export default Tree;
