import { useEffect, useState } from "react";
import "./Capsul.css";
import CapsulItem from "./CapsulItem";

const Capsul = ({
  data,
  scrollId,
  scrollRef,
  view,
  setScrollId,
  prodPopupActive,
  setProdPopupActive,
  popupProdId,
  setPopupProdId,
}) => {
  const [pageLoaded, setPageLoaded] = useState(false);

  useEffect(() => {
    setPageLoaded(true);
    const scrollToElement = () => {
      if (scrollRef.current && pageLoaded) {
        scrollRef.current.scrollIntoView({ behavior: "smooth" });
      }
    };

    scrollToElement();
  }, [scrollId, data.id, scrollRef, pageLoaded, view]);
 
  return (
    <div
      className="CapsulWrapper"
      ref={scrollId === data.id ? scrollRef : null}
    >
      <span id={data.id}>
        {data.name.charAt(0).toUpperCase() + data.name.slice(1)}
      </span>
      <div className="capsulBody">
        {data.images.map((i) => 
          (<CapsulItem data={i}  setPopupProdId={setPopupProdId}
                setProdPopupActive={setProdPopupActive}/>)
        )}
      </div>
    </div>
  );
};

export default Capsul;
