import Datefunc from "../wiki/DateFunc";
import {
  useNavigate,
} from "react-router-dom";

const CandidatsList = ({
  data,
  candidatsList,
  setCandidatsList,
  formData,
  setFormData,
}) => {
  let Datefunction = new Datefunc()
  let navigate = useNavigate();
  const goCandidat = (id) => {
    navigate("candidat/" + id);
  };
  const CreateNewCandidat = () => {
    navigate("candidat");
  };

  let arr = candidatsList.filter((i) => i.accepted);

  function acceptCandidatTogle(n) {
    arr.push(n);
    if (
      arr.indexOf(n) + 1 <= formData.desired_quantity &&
      n.accepted === false
    ) {
      let date = new Date();
      setCandidatsList(
        candidatsList.map((i) =>
          i.id === n.id
            ? { ...i, accepted: true, candidate_admission_date: date }
            : i
        )
      );
      setFormData((formData) => ({
        ...formData,
        vacancies_closed: formData.vacancies_closed + 1,
        vacancies_to_work:
          formData.vacancies_to_work-1,
      }));
    } else if (
      arr.indexOf(n) + 1 > formData.desired_quantity &&
      n.accepted === false
    ) {
      setCandidatsList(
        candidatsList.map((i) =>
          i.id === n.id ? { ...i, accepted: false } : i
        )
      );
      alert("Необходимое количество кандидатов уже нанято");
    } else {
      setCandidatsList(
        candidatsList.map((i) =>
          i.id === n.id
            ? { ...i, accepted: false, candidate_admission_date: null }
            : i
        )
      );
      setFormData((formData) => ({
        ...formData,
        vacancies_closed: formData.vacancies_closed - 1,
        vacancies_to_work:
        formData.vacancies_to_work+1,
      }));
      arr.pop();
    }
   
  }

  return (
    <div className="PersonelFormWrapper">
      <table >
        <thead>
          <tr>
            <th>ФИО</th>
            <th>Резюме</th>
            <th>Принят</th>
            <th>Дата приема</th>
          </tr>
        </thead>
        <tbody>
          {data.map((i) => (
            <tr onDoubleClick={() => goCandidat(i.id)} key={i}>
              <td>{i.full_name_of_candidat}</td>
              <td>{i.cv_file_id !== null? "Есть" : "Отсутствует"}</td>
              <td>
                <input
                  type="checkbox"
                  checked={i.accepted === true ? "checked" : ""}
                  onChange={() => acceptCandidatTogle(i)}
                />
              </td>
              <td>
                {i.candidate_admission_date !== null
                  ? Datefunction.nowDateInput(i.candidate_admission_date)
                  : ""}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <button onClick={CreateNewCandidat} className="wikiButton" style={{marginTop:'30px', width:'15%'}}>Добавить</button>
    </div>
  );
};

export default CandidatsList;
