

import Header from "../../MainWikiHeader/MainHeader";
import MainWidget from "../MainWidget/MainWidget";

const WikiPage=({logged, setLogged})=>{
  
    return(
        <div className="App">
          <div className="container">
            <Header logged={logged} setLogged={setLogged} />
            <MainWidget logged={logged} setLogged={setLogged} />
         
          </div>
        </div>
    )
}
export default WikiPage