import React, { useState } from "react";

const GroupFinder = ({
  copy,
  setGroupsSel,
  grouplist,
  setShowPopup,
  remove,
  groupsSel,
  str,
}) => {
  const [thisData, setThisData]=useState(grouplist)
  const [selectedItems, setSelectedItems] = useState([]);
  const [select, setSelect] = useState([]);
  const addGroupToList = (group) => {
    if (!selectedItems.some((item) => item[str + "_id"] === group.id)) {
      setSelectedItems((prevSelectedItems) => [
        ...prevSelectedItems,
        {
          group_id: null,
          job_position_id: null,
          job_position_name: null,
          department_id: null,
          department_name: null,
          group_name: null,
          fl_sec_read: false,
          fl_sec_cont_change: false,
          fl_sec_write: false,
          fl_sec_admin: false,
          fl_art_read: false,
          fl_art_write: false,
          fl_art_cont_download: false,
          [str + "_name"]: group.name,
          [str + "_id"]: group.id,
          fl_art_cont_print: false,
          fl_art_cont_change: false,
          delete: false,
        },
      ]);
      setSelect([...select, group.id]);
    } else {
      setSelectedItems((prevSelectedItems) =>
        prevSelectedItems.filter((item) => item[str + "_id"] !== group.id)
      );
      setSelect(select.filter((n) => n !== group.id));
    }
  };

  const GroupsAdd = () => {
    if (remove) {
      let filteredGroups = groupsSel.filter(
        (group) =>
          !selectedItems.some(
            (item) =>
              item.group_id === group.group_id &&
              item.department_id === group.department_id &&
              item.job_position_id === group.job_position_id
          )
      );
      setGroupsSel([...filteredGroups]);
      setSelectedItems([]);
    } else {
      const uniqueGroupsSel = Array.from(
        new Map(
          [...groupsSel, ...selectedItems.filter((i) => !i.delete)].map(
            (item) => [
              `${item.group_id}-${item.department_id}-${item.job_position_id}`,
              item,
            ]
          )
        ).values()
      );
      setGroupsSel((groupsSel) => [...uniqueGroupsSel]);
      setSelectedItems([]);
    }
    setShowPopup(false);
  };

  function filter(items, term) {
    if (term.length === 0) {
      return items;
    }
    return items.filter((item) => {
      return Object.keys(item).some((key) => {
        if (item[key] !== null) {
          return item[key].toString().toLowerCase().includes(term.toLowerCase());
        }
        return false;
      });
    });
  }
  function onFilterChange(e) {
    setThisData(filter(grouplist, e.target.value));
  }

  const StringTable = ({ i }) => {
    return (
      <tr
        style={
          select.includes(i.id)
            ? { backgroundColor: "gray" }
            : { backgroundColor: "white" }
        }
        onClick={() => {
          addGroupToList(i);
        }}
      >
        <td>{i.name}</td>
      </tr>
    );
  };

  return (
    <>
    <input type="text" onChange={onFilterChange} placeholder='поиск' style={{margin:'3% 0'}}/>
    <div style={{ width: "100%", overflow: "scroll", minHeight:'25vh'}}>
      <table>
        {thisData.map((i) => (
          <StringTable key={i.id} i={i} />
        ))}
      </table>
      
    </div>
    <div style={{ marginTop: "10%" }}>
        <button onClick={GroupsAdd} className="wikiButton" type="button">
          {remove ? "Удалить" : "Добавить"}
        </button>
      </div>
    </>
  );
};

export default GroupFinder;
