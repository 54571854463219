import { tab } from "@testing-library/user-event/dist/tab";
import { useState, useEffect, useRef } from "react";
import Jwt_proxy from "../API/Api";
import TestTableStr from "./testTableStr";
const TestTables = () => {
  const [table, settable] = useState([]);
  const [load, setload] = useState(true);
  async function getTable(sort = null) {
    let ip = localStorage.getItem("server_ip") + "/api/test_tables/get";
    let token = localStorage.getItem("token");

    let res = await fetch(ip, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-token": token,
      },
      body: JSON.stringify({ sort: sort }),
    });

    if (res.ok) {
      let r = await res.json();
     
     
      settable(r);
      
    }
  }

  async function postTable() {
    let ip = localStorage.getItem("server_ip") + "/api/test_tables/update";
    let token = localStorage.getItem("token");

    let res = await fetch(ip, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-token": token,
      },
      body: JSON.stringify({ table: table }),
    });

    if (res.ok) {
      Jwt_proxy(getTable).then(setload(false));
    }
  }

  const ref = useRef([]);
  const [selected, setselected] = useState();

  function selectStrBtns(i) {
    let arr = document.querySelectorAll(".TestTableStr");
    for (let e = 0; e < arr.length; e++) {
      if (selected === undefined) {
        arr[0].click();
        return;
      }
      if (arr[e].id == selected) {
        if (arr[e + i] !== undefined) {
          arr[e + i].click();
          return;
        }
      }
    }
  }

  function removeSelection() {
    if (document.activeElement && document.activeElement.blur) {
      document.activeElement.blur();
    }
  }
  function sortByPropertyASC(property) {
 
   
    return {...table, rows:table.rows.sort((a, b) => {
        const valA = a.d[property].v;
        const valB = b.d[property].v;
        return valA > valB ? 1 : (valA < valB ? -1 : 0);
      })}
  }
  function sortByPropertyDESC(property) {
 
    console.log({...table, rows:table.rows.sort((a, b) => {
        const valA = a.d[property].v;
        const valB = b.d[property].v;
        return valA < valB ? 1 : (valA > valB ? -1 : 0);
      })})
    return {...table, rows:table.rows.sort((a, b) => {
        const valA = a.d[property].v;
        const valB = b.d[property].v;
        return valA < valB ? 1 : (valA > valB ? -1 : 0);
      })}
  }
  

  useEffect(() => {
    Jwt_proxy(getTable).then(() => {
      setload(false);
      
      
    });
    
  }, []);
//   useEffect(() => {
//    if (!load &&table.rows.length>0){
   
//      writeTableData(table)
//    }
   
  
//   }, [load, table]);
//   document.addEventListener("keydown", function (event) {
//     if (event.key === "Enter") {
//       removeSelection();
//     }
//   });
//   document.addEventListener("keydown", function (event) {
//     if (event.key === "ArrowUp") {
//       selectStrBtns(-1);
//     }
//   });

//   document.addEventListener("keydown", function (event) {
//     if (event.key === "ArrowDown") {
//       selectStrBtns(1);
//     }
//   });
 
//   function onChange(e) {
//     // let newtable = [];
//     // table.forEach((i) => {
//     //   let str = { ...i };
//     //   if (str.id === e.target.getAttribute("trid")) {
//     //     if (str.hasOwnProperty("changed")) {
//     //       delete str.changed;
//     //     }
//     //     str[e.target.getAttribute("name")] = e.target.innerText;
//     //     console.log(str);
//     //     console.log(ref.current[ref.current.indexOf(str)]);
//     //     if (
//     //       !ref.current.some((c) => JSON.stringify(c) === JSON.stringify(str))
//     //     ) {
//     //       str.changed = true;
//     //     }
//     //   }
//     //   newtable.push(str);
//     // });

//     // settable(newtable);
//   }
  



// function writeTableData(obj){
//     let tabl = document.querySelector('tbody')
//     let strings = tabl.querySelectorAll('tr')
//     for (let i = 0; i<5000; i++){
//         let items = strings[i].querySelectorAll('td')
//         for (let j = 0; j<obj.rows[i]['d'].length; j++){
//             items[j].innerHTML = table.rows[1]['d'][j]['v']
//         }
//     }
// }



// let t = []
// for (let a = 0; a<5000; a++){
//   t.push(<tr><td></td><td></td><td></td></tr>)
// }


  if (!load) {
    return (
        
        <>
        <table style={{ width: "100%" }}>
        {/* <thead>
        <tr>
  {table["column properties"].map((i, n) =>
    <th style={{ fontSize: "30px" }}>
      {i.name}
      <button
        onClick={() =>
            writeTableData(sortByPropertyASC(n))
        }
      >
        ^
      </button>
      <button
        onClick={() =>
            writeTableData(sortByPropertyDESC(n))
        }
      >
        v
      </button>
    </th>
  )}
</tr>
    </thead> */}
    <thead>
      <tr>
        <td>id Отдела</td>
        <td>Отдел</td>
        <td>id Должности</td>
        <td>Должность</td>
        <td>id Пользователя</td>
        <td>Логин</td>
        <td>id Персоны</td>
        <td>ФИО</td>
      </tr>
    </thead>
          <tbody>
          {/* {table['rows'].map((i) => 
          <tr
      className="TestTableStr"
      id={i.u}
      style={selected == i.u ? { backgroundColor: "#e5f4ff" } : {}}
      onClick={() => setselected(i.u)}
    >
        {table["column properties"].map((n) => (
          <td
            trid={i.u}
            onBlur={onChange}
            contentEditable={selected === i.u&&!i['read only']===true ? "true" : "false"}
            name="v"
            style={{ fontSize: "30px" }}
          >
            {}
          </td>
        ))}
    </tr>
          )} */
          table.map((i)=>(<tr><td>{i.department_id}</td><td>{i.department}</td>
          <td>{i.job_position_id}</td><td>{i.job_position}</td>
          <td>{i.user_id}</td><td>{i.login}</td>
          <td>{i.person_id}</td><td>{i.fullname}</td></tr>))}
          </tbody>
        </table>
        {/* <button
          style={{ margin: "2vh 0 0 2vh" }}
          className="wikiButton"
          onClick={() => Jwt_proxy(postTable)}
        >
          Отправить
        </button> */}
      </>
    );
  }
};
export default TestTables;
