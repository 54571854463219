import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Jwt_proxy from "src/components/API/Api";
import "./diagram_page.css";
import Datefunc from "../../DateFunc";

const DiagramPage = ({
  seteditOpen,
  getHistory_diagram,
  RigtMenuShow,
  LeftMenuShow,
  setFirstArticleLoad,
  getLeftArr,
  firstArticleLoad,
  setArticleData,
  ArticleData,
}) => {
  let dat = new Datefunc();
  const navigate = useNavigate();
  seteditOpen(true);
  const { id, date } = useParams();
  const [imageData, setImageData] = useState({ diagram_data: "", name: null });

  function redDiagram() {
    setArticleData((ArticleData) => ({ ...ArticleData, body_type: 4 }));
    navigate("/wiki/article/update/" + imageData.id);
  }
  async function getDiagram() {
    let token = localStorage.getItem("token");
    let ip = localStorage.getItem("server_ip");

    let res = await fetch(ip + "/api/wiki/diagram/get", {
      headers: {
        "Content-Type": "application/json",
        "x-token": token,
      },
      method: "POST",
      body: JSON.stringify({ id: id, date: date }),
    });
    if (res.ok) {
      let r = await res.json();
    
      setImageData(r);
      if (firstArticleLoad === true) {
        if (date !== undefined) {
          getLeftArr(r.section_id, id, true);
        } else {
          getLeftArr(r.section_id, id);
        }
        setFirstArticleLoad(false);
      }
    } else if (res.status === 404) {
      alert("Диаграма не найдена");
      navigate("/wiki");
    }
  }

  function diagram_resize_button(a) {
    let diagram = document.querySelector(".diagram_img");
    let width = diagram.offsetWidth;
    let height = diagram.offsetHeight;
    diagram.style.minWidth = width + a + "px";
    diagram.style.minHeight = width + a + "px";
    diagram.style.width = width + a + "px";
    diagram.style.height = height + a + "px";
   
    let img = imageData.diagram_data
    setImageData({...imageData, diagram_data:''})
    setTimeout(() => {
      setImageData({...imageData, diagram_data:img})
    }, 150);
  }
  async function diagramUndelete() {
    let ip = localStorage.getItem("server_ip") + "/api/wiki/diagram/undelete";

    let token = localStorage.getItem("token");
    let res = await fetch(ip, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-token": token,
      },
      body: JSON.stringify({ id: id }),
    });
    if (res.ok) {
      Jwt_proxy(() => getLeftArr(imageData.section_id, id));
      navigate("/wiki/diagram/" + imageData.id);
      imageData.deleted = false;
    }
  }
  function diagram_resize_range(e) {
    let diagram = document.querySelector(".diagram_img");
    const width = diagram.offsetWidth;
    diagram.style.minWidth = width + e.target.value + "px";
   
  }
  useEffect(() => {
    Jwt_proxy(getDiagram);
    Jwt_proxy(() => getHistory_diagram(id));
  }, [id, date]);
  const [fullscreen, setFullscreen] = useState(false);
  return (
    <>
      <div
        className="diagramm_wrapper"
        style={
          !fullscreen
            ? {}
            : {
                position: "absolute",
                zIndex: "15",
                minWidth: "99.5vw",
                minHeight: "93vh",
                maxHeight: "93vh",
                top: -50,
                left: -30,
                overflow: fullscreen ? "scroll" : "visible",
              }
        }
      >
        <div
          style={{ display: "flex", alignItems: "center" }}
          className="diagram_menu"
        >
          {
            <>
              <button
                onClick={() => diagram_resize_button(550)}
                className="btnIcrement"
              >
                +
              </button>
              <button
                onClick={() => diagram_resize_button(-550)}
                className="btnIcrement"
              >
                -
              </button>
            </>
          }
          {/* {date === undefined ? (
            <button
              className="articleToolbarBtn r"
              title="Редактировать"
              style={{ lineHeight: "calc(8px + 20 * (100vw / 1920))" }}
              onClick={redDiagram}
            >
              <img src="/static/img/Group 227.svg" alt="" />
            </button>
          ) : null} */}
          <input
            style={{ display: "none" }}
            type="range"
            min="-100"
            max="100"
            defaultValue={0}
            step="1"
            onChange={diagram_resize_range}
          />
          <button
            className="articleToolbarBtn"
            title="Весь экран"
            style={{ lineHeight: "calc(8px + 20 * (100vw / 1920))" }}
            onClick={() => {
              setFullscreen(!fullscreen);
            }}
          >
            <img src="/static/img/fscreen.svg" alt="" />
          </button>
          {imageData.deleted ? (
            <>
              <input
                type="checkbox"
                className="articleToolbarBtn"
                checked={imageData.deleted}
                onChange={() => Jwt_proxy(diagramUndelete)}
              />
              <span>Удалено</span>
            </>
          ) : null}
        </div>

        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <h1> {imageData.name}</h1>
          <h4>Автор: {imageData.author}</h4>
          <h4>Версия от: {dat.nowDate(imageData.modification_date)}</h4>
          <h4>Идентификатор: {imageData.id}</h4>
          <h4>Тип: {imageData.diagram_type}</h4>
          {imageData.code!==null?<h4>Код: {imageData.code}</h4>:null}
         
          
        </div>
          <div style={{width:'100%', textAlign:'center'}}><p>{imageData.description}</p></div>
        <img  
          src={imageData.diagram_data!==null?imageData.diagram_data:''}
          className="diagram_img"
          style={{
            borderTop: "1px solid gray",
            minWidth: "100%",
            paddingTop: "2vh",
            borderRight: "1px solid gray",
          }}
          alt=""
        />
      </div>
    </>
  );
};
export default DiagramPage;
