import "./ApplicatuonForPersonalForm.css";
import TiketForm from "../TiketForm";
import Header from "../../MainWikiHeader/MainHeader";
import { useState} from "react";
import { Routes, Route} from "react-router-dom";
import CandidatsList from "../candidatList";
import CandidatsForm from "./candidatsForm";
const ApplicationForPersonelForm = ({ data }) => {
  const [inputData, setInputData] = useState({
    city: null,
    department: null,
    work_schedule: null,
    job_position: null,
    applicant: null,
  });
  const [formData, setFormData] = useState(data);

  let applicationId = localStorage.getItem("applicationId");
  const [languages, setlanguages] = useState({
    1: null,
    2: null,
    3: null,
  });
  const [candidat, setCandidat] = useState({
    id: null,
    full_name_of_candidat: null,
    description: null,
    interwiew_date: null,
    cv_file_id: null,
    result_communication: null,
    application_id: localStorage.getItem("applicationId"),
  });

  const [candidatsList, setCandidatsList] = useState([]);

  const [page, setPage] = useState(1);
  function toggleTabs(e) {
    let btns = document.querySelectorAll(".TabButton");
    for (let i = 0; i < btns.length; i++) {
      btns[i].classList.remove("opn");
      if (e.target.id === btns[i].id) {
        btns[i].classList.add("opn");
      }
    }
  }
  const [acceptetCandidats, setAcceptetCandidats] = useState([]);

  return (
    <>
      <Header />
      <div className="wrapperHrApplication">
        <div
          className="buttonsTab"
          style={
            applicationId !== "null" ? { display: "flex" } : { display: "none" }
          }
        >
          <button
            id="1"
            onClick={(e) => {
              setPage(1);
              toggleTabs(e);
            }}
            className="TabButton opn"
          >
            Заявка
          </button>
          <button
            id="3"
            onClick={(e) => {
              setPage(3);
              toggleTabs(e);
            }}
            className="TabButton"
          >
            Список кандидатов
          </button>
        </div>
        <div
          className="page"
          style={page === 1 ? { display: "block" } : { display: "none" }}
        >
          <TiketForm
            acceptetCandidats={acceptetCandidats}
            setAcceptetCandidats={setAcceptetCandidats}
            setCandidatsList={setCandidatsList}
            candidatsList={candidatsList}
            formData={formData}
            languages={languages}
            setFormData={setFormData}
            inputData={inputData}
            setInputData={setInputData}
            applicationId={applicationId}
            setlanguages={setlanguages}
          />
        </div>
        <div
          className="page"
          style={
            page === 2
              ? { display: "block", position: "absolute", width: "100%" }
              : { display: "none" }
          }
        ></div>
        <div
          className="page"
          style={page === 3 ? { display: "block" } : { display: "none" }}
        >
          <CandidatsList
            setFormData={setFormData}
            formData={formData}
            data={candidatsList}
            candidatsList={candidatsList}
            setCandidatsList={setCandidatsList}
            setPage={setPage}
            setCandidat={setCandidat}
            toggleTabs={toggleTabs}
            acceptetCandidats={acceptetCandidats}
            setAcceptetCandidats={setAcceptetCandidats}
          />
        </div>
        <Routes>
          <Route
            path="candidat"
            element={
              <CandidatsForm
                candidat={candidat}
                setCandidat={setCandidat}
                setCandidatsList={setCandidatsList}
                setPage={setPage}
              />
            }
          />
        </Routes>
        <Routes>
          <Route
            path="candidat/:candidat_id"
            element={
              <CandidatsForm
                candidat={candidat}
                candidatsList={candidatsList}
                setCandidat={setCandidat}
                setCandidatsList={setCandidatsList}
                setPage={setPage}
              />
            }
          />
        </Routes>
      </div>
    </>
  );
};
export default ApplicationForPersonelForm;
