import { useEffect, useState } from "react";
import "./MainHeaderMenu.css";
import Tree from "./Tree/Tree";
import Jwt_proxy from "../../API/Api";
import { useNavigate } from "react-router-dom";
import TreeContextMenu from "./Tree/contexmenu/treecontexmenu";
import TreeFirstItem from "./TreeFirstItem";

const TreeWrapper = ({
  setArticleText,
  setArticleData,
  onPopupActive,
  setFilesData,
  FilesData,
  toggle_width_article,
  getSections,
  setIsRedaction,
  setRedArticle,
  onRedact,
  deleteArticle,
  getLeftArr,
  changePid,
  showPopup,
  setShowPopup,
  setPopupLewelTwooTreeSelected,
  showLeftToolbarTree,
  setArticleHistory,
  getLeftTree,
  nameAndPathUpdate,
  nameAndPathCreate,
  GoArticleToRedact,
  getThreePathData,
  parentData,
  toggleSelect,
  getFirstItemForTree,
  setSeectionSectionsPageList,
  sectionPageList,
  toggleEdit,
  addArticleText,
  setItsarticleHistory,
  setSeectionArticlesPageList,
  setitemisfirst,
  LeftMenuShow,
  setLeftMenuShow,
  deleteDiagram,
  deleteFile,
  setFirstArticleLoad,
  setHistoryPopup,
  setHistoryArticleData,
  getHist,
  toggleSelectArticle,
  setSecUndefined,
  setNameAndPathUpdate,
  setParentData,
  setArticleWiew,
  selected,
  cfg,
  getParentId,
  userFolder,
  firstArticleLoad,
  ArticleData,
  setFormData,
  setSelected,
  setdeleteBtnF,
  setcreateBtnArtF,
  setredactBtnF,
  setcreateSecBtnF,
  redactBtn,
  deleteBtn,
  createSecBtn,
  createBtnArt,
  coords,
  showContext, setStowContext
}) => {
  let navigate = useNavigate();

  useEffect(() => {
    const leftPane = document.querySelector(".leftToolbar");
    const gutter = document.querySelector(".gufleft");
    function resizer(e) {
      // Отключаем возможность выделения текста
      document.body.style.userSelect = "none";

      window.addEventListener("mousemove", mousemove);
      window.addEventListener("mouseup", mouseup);

      let prevX = e.x;
      const leftPanel = leftPane.getBoundingClientRect();

      function mousemove(e) {
        let newX = prevX - e.x;
        leftPane.style.width = leftPanel.width - newX + "px";
      }

      function mouseup() {
        // Включаем возможность выделения текста обратно
        document.body.style.userSelect = "";

        window.removeEventListener("mousemove", mousemove);
        window.removeEventListener("mouseup", mouseup);
      }
    }

    gutter.addEventListener("mousedown", resizer);
  }, []);

  let btnIc;
  if (!LeftMenuShow) {
    btnIc = "<";
  } else {
    btnIc = ">";
  }

  return (
    <div
      style={
        LeftMenuShow
          ? { minWidth: "10px", width: "40px", position: "relative" }
          : { display: "flex", alignItems: "flex-start", position: "relative" }
      }
      className="leftToolbar"
    >
      <div
        className="TreeWrapper"
        id="TreeLeft"
        style={LeftMenuShow ? { display: "none" } : {}}
      >
        <div>
          <h4 style={{ marginLeft: "2.5%", marginBottom: "2vh" }}>
            {!nameAndPathUpdate.isArticle ? "РАЗДЕЛЫ" : "СТАТЬЯ"}
          </h4>
          <div className="Treeheader" style={{ flexWrap: "wrap" }}>
            <button
              disabled={nameAndPathUpdate.isArticle ? "disabled" : ""}
              className="wikiButton"
              style={{
                width: "47%",
                margin: "1%",
                display: nameAndPathUpdate.isArticle ? "none" : "block",
              }}
              onClick={createSecBtn}
            >
              Создать раздел
            </button>
            <button
              className="wikiButton"
              style={{ width: "47%", margin: "1%" }}
              onClick={redactBtn}
            >
              Редактировать
            </button>
            <button
              disabled={nameAndPathUpdate.isArticle ? "disabled" : ""}
              className="wikiButton"
              style={{
                width: "47%",
                margin: "1%",
                display: nameAndPathUpdate.isArticle ? "none" : "block",
              }}
              onClick={createBtnArt}
            >
              Создать статью
            </button>
            <button
              className="wikiButton"
              style={{ width: "47%", margin: "1%" }}
              onClick={deleteBtn}
            >
              Удалить
            </button>
          </div>

          <>
            {
              <div
                style={{
                  height: "60vh",
                  overflowY: "scroll",
                  overflowX: "hidden",
                }}
              >
                <TreeFirstItem
                  context={true}
                  deleteBtn={deleteBtn}
                  createBtnArt={createBtnArt}
                  redactBtn={redactBtn}
                  createSecBtn={createSecBtn}
                  setitemisfirst={setitemisfirst}
                  setArticleHistory={setArticleHistory}
                  setSeectionArticlesPageList={setSeectionArticlesPageList}
                  setItsarticleHistory={setItsarticleHistory}
                  toggleEdit={toggleEdit}
                  sectionPageList={sectionPageList}
                  setSeectionSectionsPageList={setSeectionSectionsPageList}
                  chuldren={showLeftToolbarTree}
                  parentData={parentData}
                  getThreePathData={getThreePathData}
                  toggleSelect={toggleSelect}
                />
                { (
                  <TreeContextMenu
                    isart={nameAndPathUpdate.isArticle}
                    coords={coords}
                    deleteBtn={deleteBtn}
                    createBtnArt={createBtnArt}
                    redactBtn={redactBtn}
                    showContext={showContext}
                    createSecBtn={createSecBtn}
                    setStowContext={setStowContext}
                    array={[
                      { name: "Редактировать", fun: redactBtn },
                      { name: "Создать раздел", fun: createSecBtn },
                      { name: "Создать статью", fun: createBtnArt },
                      { name: "Удалить", fun: deleteBtn },
                    ]}
                  />
                ) }
                <Tree
                  context={true}
                  deleteBtn={deleteBtn}
                  createBtnArt={createBtnArt}
                  redactBtn={redactBtn}
                  createSecBtn={createSecBtn}
                  article_id={userFolder.article_id}
                  ids_list={userFolder.ids_list}
                  firstArticleLoad={firstArticleLoad}
                  setFirstArticleLoad={setFirstArticleLoad}
                  setSeectionArticlesPageList={setSeectionArticlesPageList}
                  setSeectionSectionsPageList={setSeectionSectionsPageList}
                  setHistoryPopup={setHistoryPopup}
                  setHistoryArticleData={setHistoryArticleData}
                  getHist={getHist}
                  toggleSelectArticle={toggleSelectArticle}
                  getLeftArr={getLeftArr}
                  setArticleHistory={setArticleHistory}
                  setSecUndefined={setSecUndefined}
                  toggleSelect={toggleSelect}
                  setNameAndPathUpdate={setNameAndPathUpdate}
                  nameAndPathUpdate={nameAndPathUpdate}
                  getThreePathData={getThreePathData}
                  parentData={parentData}
                  setParentData={setParentData}
                  setArticleData={setArticleData}
                  ArticleData={ArticleData}
                  setArticleWiew={setArticleWiew}
                  getSections={getSections}
                  selected={selected}
                  setSelected={setSelected}
                  getParentId={getParentId}
                  cfg={cfg}
                  data={userFolder}
                  have_user_section={
                    userFolder.haveFolderInDb === false ? false : true
                  }
                  is_user_folder={true}
                />
              </div>
            }
          </>
        </div>
      </div>
      <button
        className="btnIcrement"
        style={{ border: "none", position: "absolute", right: 0 }}
        onClick={() => toggle_width_article(setLeftMenuShow, LeftMenuShow)}
      >
        {btnIc}
      </button>
      <div className={"gutter gufleft"}></div>
    </div>
  );
};

export default TreeWrapper;
