const PopupTwooLevel=({child, onShow})=>{


    return(
        <div className="popupTwooLevel">
        <div className="popupTwooLevelHeader">
            <button className="popupclose" onClick={onShow}>X</button>
        </div>{child}</div>
    )
}
export default PopupTwooLevel