import { useState, useEffect } from "react";
import Jwt_proxy from "../../API/Api";
import config from "../../../config/config";
import { useParams, useNavigate } from "react-router-dom";
import Article from "../../wiki/WikiArticteViever/WikiArticteViever";
import Datefunc from "../../wiki/DateFunc";
const CandidatsForm = ({
  data,
  setCandidatsList,
  setPage,
  candidat,
  setCandidat,
}) => {
  const { candidat_id } = useParams();
  let Datefunction = new Datefunc();
  function onChange(e) {
    setCandidat((candidat) => ({
      ...candidat,
      [e.target.name]: e.target.value,
    }));
  }
  let [onFileChande, setOnFileChange] = useState();
  function getFileFromInput(e) {
    setOnFileChange((onFileChande = e.target.files[0]));
  }
  async function SetFile() {
    let ip = localStorage.getItem("server_ip") + "/files/new_file";

    let token = localStorage.getItem("token");
    const formData = new FormData();
    formData.append("storage_type", "HR");
    formData.append("file", onFileChande);

    const res = await fetch(ip, {
      method: "POST",
      headers: {
        "x-token": token,
      },
      body: formData,
    });

    if (res.ok) {
      let response = await res.json();
      setCandidat((candidat) => ({
        ...candidat,
        cv_file_id: response.id
      }));
      Jwt_proxy(() => getResume(response.id));
    
    }
  }
  async function getResume(id) {
    let token = localStorage.getItem("token");
    let ip = localStorage.getItem("server_ip") + "/api/hr/candidat/resume";
    const result = await fetch(ip, {
      method: "POST",
      headers: {
        "x-token": token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id: id }),
    });
    if (result.ok) {
      await result.blob().then((blob) => {
        let file = window.URL.createObjectURL(blob);
        setPdfFile(file);
      });
    }
  }

  const [showResume, setShowResume] = useState(false);

  async function getCandidat(id) {
    let token = localStorage.getItem("token");
    let ip = localStorage.getItem("server_ip") + "/api/hr/candidat/get";
    const result = await fetch(ip, {
      method: "POST",
      headers: {
        "x-token": token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id: id }),
    });
    if (result.ok) {
      let r = await result.json();
      setCandidat({...r,
        interwiew_date:r.interwiew_date !== null
                ? Datefunction.nowDateI(r.interwiew_date)
                : null});
      Jwt_proxy(() => getResume(r.cv_file_id));
    }
  }

  useEffect(() => {
    if (candidat_id !== undefined) {
      Jwt_proxy(() => getCandidat(candidat_id));
    }
  }, [candidat_id]);

  async function onsubmit() {
    let ip = localStorage.getItem("server_ip");
    if (candidat_id !== undefined) {
      ip += "/api/hr/candidat/update";
    } else {
      ip += "/api/hr/candidat/create";
    }

    let token = localStorage.getItem("token");
    let res = await fetch(ip, {
      method: "POST",
      headers: {
        "x-token": token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(candidat),
    });
    if (res.ok) {
      setCandidat({
        id: null,
        full_name_of_candidat: null,
        description: null,
        interwiew_date: null,
        cv_file_id: null,
        result_communication: null,
        application_id: localStorage.getItem("applicationId"),
      });
      window.location.reload();
    }
  }
  let navigate = useNavigate();
  const goList = () => {
    navigate("/hr/list/" + localStorage.getItem("applicationId"));
  };
  const [pdfFile, setPdfFile] = useState();
  return (
    <div
      className="PersonelFormWrapper"
      style={{
        width: "50%",
        margin: "0 25%",
        position: "absolute",
        zIndex: "9",
      }}
    >
      <div className="ThreeItem row">
        <div className="inputblock">
          <span>ФИО</span>
          <input
            type="text"
            value={candidat.full_name_of_candidat}
            name="full_name_of_candidat"
            onChange={onChange}
          />
        </div>
        <div className="inputblock">
          <span>
            {candidat.cv_file_id === null
              ? "Добавить резюме"
              : "Обновить резюме"}
          </span>
          <input
            type="file"
            name="cv_file_id"
            accept=".PDF"
            onChange={(e) => {
              getFileFromInput(e);
              Jwt_proxy(SetFile);
            }}
          />
        </div>
        <div className="inputblock">
          <span>Дата собеседования</span>
          <input
            type="date"
            value={candidat.interwiew_date}
            name="interwiew_date"
            onChange={onChange}
          />
        </div>
      </div>
      <div className="OneItem row">
        <div className="inputblock">
          <span>Примечание</span>
          <textarea
            name="description"
            value={candidat.description}
            onChange={onChange}
          />
        </div>
      </div>
      <div className="OneItem row">
        <div className="inputblock">
          <span>Результат общения</span>
          <textarea
            name="result_communication"
            value={candidat.result_communication}
            onChange={onChange}
          />
        </div>
      </div>
      <div className="OneItem row"></div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-around",
          marginBottom: "20px",
        }}
      >
        <button
          className="wikiButton"
          onClick={() => {
            Jwt_proxy(onsubmit);
            goList();
          }}
        >
          Записать
        </button>
        <button
          className="wikiButton"
          disabled={candidat.cv_file_id == null ? "disabled" : ""}
          onClick={() => setShowResume(!showResume)}
        >
          {!showResume ? "Просмотр резюме" : "Скрыть резюме"}
        </button>
        <button
          className="wikiButton"
          onClick={() => {
            goList();
          }}
        >
          Закрыть
        </button>
      </div>
      <div
        className="cv_file_id"
        style={showResume ? { display: "block" } : { display: "none" }}
      >
        <Article setFilePdf={setPdfFile} filePdf={pdfFile} />
      </div>
    </div>
  );
};

export default CandidatsForm;
