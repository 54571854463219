import 'html5-qrcode'
import './Qrcode.css'
import { Html5QrcodeScanner } from 'html5-qrcode';

const Qrscanner = () => {

function domReady(fn){
  if (document.readyState==='complete'||document.readyState==='interactive'){
    setTimeout(fn,1) 
} else{
  document.addEventListener("DOMContentLoaded", fn)
}
}

domReady(()=>{
  let mycr=document.querySelector('qrresult')
  let lastresult, counterresults =0;
  function onScanSuccess(decodeText, decodeResult){
    if (decodeText!==lastresult){
      ++counterresults
      lastresult=decodeText
      alert("Результат"+ +decodeText)
    }
    mycr.innerHTML= decodeResult + decodeText
  }
  let htmlScanner = new Html5QrcodeScanner(
    'myqrreader', {fps:10,qrbox:450}
    )
    htmlScanner.render(onScanSuccess)
    
})

  return (
    <>
    <div className="qrresult">   </div>
    <div style={{display:'flex', justifyContent:'center'}}>
      <div id="myqrreader" style={{width:'800px'}}>

      </div>
    </div>
    

    </>
  );
};
export default Qrscanner;
