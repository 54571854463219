import { useRef, useState } from "react";
import "./AdminPage.css";
// import config from "../../config/config";
import Jwt_proxy from "../API/Api";
import PopupSelect from "./SelectPopup";
import { useNavigate } from "react-router-dom";

const AdminPage = () => {
  const navigate = useNavigate();
  const ref = useRef();
  const [createdGroup, setCreatetGroup] = useState(null);
  let groupData;
  const [popupData, setpopupData] = useState({
    child: "",
    click: "",
  });

  const [Data, setData] = useState();
  function getTableDataGroups() {
    Jwt_proxy(getAllGroups);
  }
  function getTableDataUsers() {
    Jwt_proxy(getAllUsers);
  }

  function GenPopup(e, n) {
    setpopupData((popupData) => ({ ...popupData, child: e, click: n }));
  }
  async function getAllUsers() {
    let token = localStorage.getItem("token");
    let ip = localStorage.getItem("server_ip");
    let res = await fetch(ip + "/api/wiki/admin/get_all_users", {
      headers: {
        "Content-Type": "application/json",
        "x-token": token,
      },
      method: "POST",
      body: {},
    });
    if (res.ok) {
      let r = await res.json();
      setData(<Users data={r} />);
    } else {
      navigate("/");
    }
  }
  async function getAllGroups() {
    let token = localStorage.getItem("token");
    let ip = localStorage.getItem("server_ip");
    let res = await fetch(ip + "/api/wiki/admin/get_all_groups", {
      headers: {
        "Content-Type": "application/json",
        "x-token": token,
      },
      method: "POST",
      body: {},
    });
    if (res.ok) {
      let r = await res.json();
      setData(<Groups data={r} />);
    } else {
      navigate("/");
    }
  }
  async function FetchData() {
    setCreatetGroup(ref.current);
    let token = localStorage.getItem("token");
    let ip = localStorage.getItem("server_ip");

    let res = await fetch(ip + "/api/wiki/admin/user_group/create", {
      headers: {
        "Content-Type": "application/json",
        "x-token": token,
      },
      method: "POST",
      body: JSON.stringify({ name: groupData }),
    });
    if (res.ok) {
      alert("OK");
      Jwt_proxy(getAllGroups);
    } else {
      navigate("/");
    }
  }
  const AddGroupForm = () => {
    return (
      <>
        <input
          type="text"
          value={groupData}
          onBlur={(e) => (groupData = e.target.value)}
          placeholder="Название группы"
        />
      </>
    );
  };

  const UserTable = ({
    data,
    getdGroupsFromSelecteUser,
    selectId,
    setSelectId,
  }) => {
    const [thisData, setThisData] = useState(data);
    function filter(items, term) {
      if (term.length === 0) {
        return items;
      }
      return items.filter((item) => {
        return Object.keys(item).some((key) => {
          if (item[key] !== null) {
            return item[key].toString().toLowerCase().includes(term.toLowerCase());
          }
          return false;
        });
      });
    }
    function onFilterChange(e) {
      setThisData(filter(data, e.target.value));
    }
    const UsrString = ({ dat }) => {
      let stl = { backgroundColor: "white" };
      if (select === dat.id) {
        stl = { backgroundColor: "gray" };
      }
      return (
        <tr
          style={stl}
          id={dat.id}
          onClick={() => {
            setSelectId(dat.id);
            setSelect(dat.id);
            getdGroupsFromSelecteUser(dat.id);
          }}
        >
          <td style={{ width: "12%" }}>{dat.id}</td>
          <td style={{ width: "25%", textAlign: "left", paddingLeft: "1%" }}>
            {dat.login}
          </td>
          <td style={{ width: "30%", textAlign: "left", paddingLeft: "1%" }}>
            {dat.person.length > 3 ? dat.person : null}
          </td>
          <td style={{ width: "40%" }}>{dat.name}</td>
        </tr>
      );
    };
    const [select, setSelect] = useState();
    return (
      <>
        <input type="text" name="" id="" onChange={onFilterChange} />
        <table>
          <tr>
            <th style={{ width: "12%" }}>id</th>
            <th style={{ width: "25%" }}>Логин</th>
            <th style={{ width: "30%" }}>Имя</th>
            <th style={{ width: "40%" }}>Тип пользователя</th>
          </tr>
        </table>
        <div className="scroll">
          <table>
            <tbody>
              {" "}
              {[
                ...thisData.map((n) => (
                  <UsrString
                    select={select}
                    dat={n}
                    setSelect={setSelect}
                    setSelectId
                  />
                )),
              ]}
            </tbody>
          </table>
        </div>
      </>
    );
  };

  const UserList = ({ usersdata, sel, setSel }) => {
    
    const UsrString = ({ dat, sel }) => {
      const [stl, setStl] = useState({ backgroundColor: "white" });
      function selectStr() {
        if (stl["backgroundColor"] === "white") {
          if (sel !== undefined && setSel !== undefined) {
            if (sel.includes(dat.id)) {
              delete sel[sel.indexOf(dat.id)];
            }
            sel.push(dat.id);
            setSel(sel);
            setStl({ backgroundColor: "gray" });
          }
        } else if (stl["backgroundColor"] === "gray") {
          if (sel !== undefined && setSel !== undefined) {
            delete sel[sel.indexOf(dat.id)];
            setSel(sel);
            setStl({ backgroundColor: "white" });
          }
        }
      }

      return dat.id !== null ? (
        <tr style={stl} id={dat.id} onClick={selectStr}>
          <td style={{ width: "10%" }}>{dat.id}</td>
          <td style={{ width: "25%", textAlign: "left", paddingLeft: "1%" }}>
            {dat.login}
          </td>
          <td style={{ width: "55%", textAlign: "left", paddingLeft: "1%" }}>
            {dat.person.length > 3 ? dat.person : null}
          </td>
        </tr>
      ) : null;
    };
    return (
      <>
        <table>
          <tr>
            <th style={{ width: "10%" }}>id</th>
            <th style={{ width: "25%" }}>Логин</th>
            <th style={{ width: "55%" }}>Имя</th>
          </tr>
        </table>
        <div className="scroll">
          <table>
            <tbody>
              {[
                ...usersdata.map((n) => (
                  <UsrString dat={n} sel={sel} setSel={setSel} />
                )),
              ]}
            </tbody>
          </table>
        </div>
      </>
    );
  };

  const GroupList = ({ data, sel, setSel }) => {
    const UsrString = ({ dat, sel }) => {
      const [stl, setStl] = useState({ backgroundColor: "white" });
      function selectStr() {
        if (stl["backgroundColor"] === "white") {
          if (sel !== undefined && setSel !== undefined) {
            if (sel.includes(dat.id)) {
              delete sel[sel.indexOf(dat.id)];
            }
            sel.push(dat.id);
            setSel(sel);
            setStl({ backgroundColor: "gray" });
          }
        } else if (stl["backgroundColor"] === "gray") {
          if (sel !== undefined && setSel !== undefined) {
            delete sel[sel.indexOf(dat.id)];
            setSel(sel);
            setStl({ backgroundColor: "white" });
          }
        }
      }

      return (
        <tr style={stl} id={dat.id} onClick={selectStr}>
          <td style={{ width: "10%" }}>{dat.id}</td>
          <td style={{ width: "90%" }}>{dat.name}</td>
        </tr>
      );
    };
    return (
      <>
        <table>
          <tr>
            <th style={{ width: "10%" }}>id</th>
            <th style={{ width: "90%" }}>Имя</th>
          </tr>
        </table>
        <div className="scroll">
          <table>
            <tbody>
              {[
                ...data.map((n) => (
                  <UsrString dat={n} sel={sel} setSel={setSel} />
                )),
              ]}
            </tbody>
          </table>
        </div>
      </>
    );
  };

  const Groups = ({ data }) => {
    const [selectId, setSelectId] = useState();

    const [addedUsers, setAddedUsers] = useState([]);
    async function getUserListForAdd(groupId) {
      let token = localStorage.getItem("token");
      let ip = localStorage.getItem("server_ip");
      let res = await fetch(ip + "/api/wiki/admin/users/get_for_add_to_group", {
        headers: {
          "Content-Type": "application/json",
          "x-token": token,
        },
        method: "POST",
        body: JSON.stringify({ id: groupId }),
      });
      if (res.ok) {
        let r = await res.json();
        setpopupData((popupData) => ({
          ...popupData,
          child: (
            <UserList usersdata={r} setSel={setAddedUsers} sel={addedUsers} />
          ),
          click: () => {
            Jwt_proxy(postUserListForAdd);
          },
        }));
      } else {
        navigate("/");
      }
    }
    async function postUserListForAdd() {
      let token = localStorage.getItem("token");
      let ip = localStorage.getItem("server_ip");
      let res = await fetch(
        ip + "/api/wiki/admin/users/post_for_add_to_group",
        {
          headers: {
            "Content-Type": "application/json",
            "x-token": token,
          },
          method: "POST",
          body: JSON.stringify({ group_id: selectId, values: addedUsers }),
        }
      );
      if (res.ok) {
        getUsersFromSelectedGroup(selectId);
        setAddedUsers([]);
        alert("ok");
      } else {
        navigate("/");
      }
    }

    async function postUserListForDelete() {
      let token = localStorage.getItem("token");
      let ip = localStorage.getItem("server_ip");
      let res = await fetch(
        ip + "/api/wiki/admin/users/post_for_delete_to_group",
        {
          headers: {
            "Content-Type": "application/json",
            "x-token": token,
          },
          method: "POST",
          body: JSON.stringify({ group_id: selectId, values: addedUsers }),
        }
      );
      if (res.ok) {
        getUsersFromSelectedGroup(selectId);
        setAddedUsers([]);
        getUsersFromSelectedGroup(selectId);
      } else {
        navigate("/");
      }
    }
    const [StartTableValue, setStartTableValue] = useState([
      { id: null, login: null },
    ])
    const [TableOfSelectedValues, setTableOfSelectedValues] = useState([
      { id: null, login: null },
    ]);
    function filter(items, term) {
      if (term.length === 0) {
        return items;
      }
      return items.filter((item) => {
        return Object.keys(item).some((key) => {
          if (item[key] !== null) {
            return item[key].toString().toLowerCase().includes(term.toLowerCase());
          }
          return false;
        });
      });
    }
    function onFilterChange(e) {
      setTableOfSelectedValues(filter(StartTableValue, e.target.value));
    }
    async function deleteGroup() {
      let token = localStorage.getItem("token");
      let ip = localStorage.getItem("server_ip");
      let res = await fetch(ip + "/api/wiki/admin/users/group_delete", {
        headers: {
          "Content-Type": "application/json",
          "x-token": token,
        },
        method: "POST",
        body: JSON.stringify({ id: selectId }),
      });
      if (res.ok) {
        getAllGroups();
      } else {
        navigate("/");
      }
    }

    async function getUsersFromSelectedGroup(groupId) {
      let token = localStorage.getItem("token");
      let ip = localStorage.getItem("server_ip");
      let res = await fetch(ip + "/api/wiki/admin/users/get_from_group", {
        headers: {
          "Content-Type": "application/json",
          "x-token": token,
        },
        method: "POST",
        body: JSON.stringify({ id: groupId }),
      });
      if (res.ok) {
        let r = await res.json();
        setTableOfSelectedValues(r);
        setStartTableValue(r)
        setAddedUsers([]);
      } else {
        navigate("/");
      }
    }

    return (
      <>
        <div
          style={{
            width: "100%",
            margin: "15px 0",
            display: "flex",
            justifyContent: "space-between",
            width: "56vw",
            marginLeft: "1.6%",
          }}
        >
          <button
            className="wikiButton min"
            onClick={() => {
              getUserListForAdd(selectId);
              setPopupActive(true);
              setAddedUsers([]);
            }}
          >
            Добавить пользователей
          </button>
          <button
            className="wikiButton min"
            onClick={() => {
              setpopupData((popupData) => ({
                ...popupData,
                child: <AddGroupForm ref={groupData} />,
                click: FetchData,
              }));
              setPopupActive(true);
            }}
          >
            Создать группу
          </button>
          <button
            className="wikiButton min"
            onClick={() => {
              deleteGroup();
            }}
          >
            Удалить группу
          </button>
          <button
            className="wikiButton min"
            onClick={() => {
              GenPopup(
                <UserList
                  usersdata={TableOfSelectedValues}
                  setSel={setAddedUsers}
                  sel={addedUsers}
                />,
                postUserListForDelete
              );
              setAddedUsers([]);
              setPopupActive(true);
            }}
          >
            {" "}
            Удалить пользователей
          </button>
        </div>
        <div className="AdminPanelContent">
          <div className="TableWrapper">
            <h3>Группы</h3>
            <GroupTable
              data={data}
              getUsersFromSelectedGroup={getUsersFromSelectedGroup}
              setSelectId={setSelectId}
            />
          </div>
          <div className="TableWrapper">
          <input type="text"  onChange={onFilterChange}/>
            <h3>Пользователи в группе</h3>
            <UserList usersdata={TableOfSelectedValues} />
          </div>
        </div>
      </>
    );
  };

  const GroupTable = ({ data, getUsersFromSelectedGroup, setSelectId }) => {
    const GrpString = ({ dat, select, setSelect, setSelectId }) => {
      let stl = { backgroundColor: "white" };
      if (select === dat.id) {
        stl = { backgroundColor: "gray" };
      }
      function blockGroup(id) {
        let text;
        if (dat.blocked === true) {
          text = "Разблокировать группу?";
        } else {
          text = "Заблокировать группу?";
        }
        let r = window.confirm(text);
        if (r) {
          toggleBlockGroup(id);
          dat.blocked = !dat.blocked;
        }
      }
      async function toggleBlockGroup(id) {
        let token = localStorage.getItem("token");
        let ip = localStorage.getItem("server_ip");
        let res = await fetch(ip + "/api/wiki/admin/group_block", {
          headers: {
            "Content-Type": "application/json",
            "x-token": token,
          },
          method: "POST",
          body: JSON.stringify({ id: id, blocked: !dat.blocked }),
        });
      }

      return (
        <tr
          style={stl}
          id={dat.id}
          onClick={() => {
            setSelect(dat.id);
            setSelectId(dat.id);
            Jwt_proxy(() => getUsersFromSelectedGroup(dat.id));
          }}
        >
          <td style={{ width: "10%" }}>{dat.id}</td>
          <td style={{ width: "50%" }}>{dat.name}</td>
          <td style={{ width: "40%" }}>
            <input
              type="checkbox"
              checked={dat.blocked}
              onChange={() => {
                Jwt_proxy(() => blockGroup(dat.id));
              }}
            />
          </td>
        </tr>
      );
    };
    const [select, setSelect] = useState();
    return (
      <>
        <table>
          <tr>
            <th style={{ width: "10%" }}>id</th>
            <th style={{ width: "50%" }}>Имя</th>
            <th style={{ width: "40%" }}>Заблокировать группу</th>
          </tr>
        </table>
        <div className="scroll">
          <table>
            <tbody>
              {[
                ...data.map((n) => (
                  <GrpString
                    select={select}
                    dat={n}
                    setSelect={setSelect}
                    setSelectId={setSelectId}
                  />
                )),
              ]}
            </tbody>
          </table>
        </div>
      </>
    );
  };

  function selectList(e) {
    let el = document.querySelectorAll(".ListBtn");
    for (let i = 0; i < el.length; i++) {
      el[i].classList.remove("selectedList");
      if (e.target.id === el[i].id) {
        e.target.classList.add("selectedList");
      }
    }
  }

  const Users = ({ data }) => {
    const [selectId, setSelectId] = useState();

    const [addedGroups, setAddedGroups] = useState([]);
    const [TableGroupsFromUser, setTableGroupsFromUser] = useState([]);
    async function getdGroupsFromSelecteUser(groupId) {
      let token = localStorage.getItem("token");
      let ip = localStorage.getItem("server_ip");
      let res = await fetch(ip + "/api/wiki/admin/users/get_groups_from_user", {
        headers: {
          "Content-Type": "application/json",
          "x-token": token,
        },
        method: "POST",
        body: JSON.stringify({ id: groupId }),
      });
      if (res.ok) {
        let r = await res.json();
        setTableGroupsFromUser(r);
      } else {
        navigate("/");
      }
    }

    async function postGroupListForAdd() {
      let token = localStorage.getItem("token");
      let ip = localStorage.getItem("server_ip");
      let res = await fetch(ip + "/api/wiki/admin/users/post_for_add_to_user", {
        headers: {
          "Content-Type": "application/json",
          "x-token": token,
        },
        method: "POST",
        body: JSON.stringify({ user_id: selectId, values: addedGroups }),
      });
      if (res.ok) {
        getdGroupsFromSelecteUser(selectId);
        setAddedGroups([]);
      } else {
        navigate("/");
      }
    }
    async function getGroupListForAdd(groupId) {
      let token = localStorage.getItem("token");
      let ip = localStorage.getItem("server_ip");
      let res = await fetch(ip + "/api/wiki/admin/users/get_for_add_to_user", {
        headers: {
          "Content-Type": "application/json",
          "x-token": token,
        },
        method: "POST",
        body: JSON.stringify({ id: groupId }),
      });
      if (res.ok) {
        let r = await res.json();
        setpopupData((popupData) => ({
          ...popupData,
          child: (
            <GroupList data={r} setSel={setAddedGroups} sel={addedGroups} />
          ),
          click: postGroupListForAdd,
        }));
      } else {
        navigate("/");
      }
    }
    async function postGroupListForDelete() {
      let token = localStorage.getItem("token");
      let ip = localStorage.getItem("server_ip");
      let res = await fetch(
        ip + "/api/wiki/admin/users/post_for_delete_to_user",
        {
          headers: {
            "Content-Type": "application/json",
            "x-token": token,
          },
          method: "POST",
          body: JSON.stringify({ user_id: selectId, values: addedGroups }),
        }
      );
      if (res.ok) {
        getdGroupsFromSelecteUser(selectId);
        setAddedGroups([]);
        getdGroupsFromSelecteUser(selectId);
      } else {
        navigate("/");
      }
    }
    return (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: "15px",
            width: "56vw",
            marginLeft: "1.6%",
          }}
        >
          <button className="wikiButton">Изменить пароль</button>
          <button
            className="wikiButton "
            onClick={() => {
              setAddedGroups([]);
              Jwt_proxy(() => getGroupListForAdd(selectId));
              setPopupActive(true);
            }}
          >
            Добавить в группы
          </button>
          <button
            className="wikiButton"
            onClick={() => {
              setAddedGroups([]);
              GenPopup(
                <GroupList
                  data={TableGroupsFromUser}
                  setSel={setAddedGroups}
                  sel={addedGroups}
                />,
                postGroupListForDelete
              );
              setPopupActive(true);
            }}
          >
            Удалить из групп
          </button>
        </div>
        <div className="AdminPanelContent">
          <div className="TableWrapper">
            <h3>Пользователи</h3>
            <UserTable
              data={data}
              selectId={selectId}
              setSelectId={setSelectId}
              getdGroupsFromSelecteUser={getdGroupsFromSelecteUser}
            />
          </div>

          <div className="TableWrapper">
            <h3>Группы пользователя</h3>
            <GroupList data={TableGroupsFromUser} />
          </div>
        </div>
        <div
          style={{
            width: "65%",
            margin: "15px 0",
            display: "flex",
            justifyContent: "space-between",
          }}
        ></div>
      </>
    );
  };
  const [popupActive, setPopupActive] = useState(false);

  return (
    <div className="AdminPanelWrapper">
      <div className="AdminDescTop">
        <div className="AdminPanelMenu">
          <button
            id="list1"
            className="ListBtn"
            onClick={(e) => {
              getTableDataGroups();
              selectList(e);
            }}
          >
            Группы
          </button>
          <button
            id="list2"
            className="ListBtn"
            onClick={(e) => {
              getTableDataUsers();
              selectList(e);
            }}
          >
            Пользователи
          </button>
          <button id="list3" className="ListBtn" onClick={(e) => selectList(e)}>
            Хранилища
          </button>
        </div>
        {Data}
      </div>
      {popupActive === true ? (
        <PopupSelect
          setPopupActive={setPopupActive}
          children={popupData.child}
          OnClickFetch={popupData.click}
        />
      ) : null}
    </div>
  );
};
export default AdminPage;
