import { useState } from "react";

const CapsulItem = ({ data, setPopupProdId, setProdPopupActive }) => {
  let match = data.name.match(/^(.*\d+)\s(.*)$/);
  let beforeLastSpace;
  let afterLastSpace;
  if (match) {
    beforeLastSpace = match[1];
    afterLastSpace = match[2];
  }

  const [showHide, setShovHide] = useState(false);

  return (
    <div
      className="hover"
      onClick={() => {
        setPopupProdId(data.id);
        setProdPopupActive(true);
      }}
      onMouseEnter={() => setShovHide(true)}
      onMouseLeave={() => setShovHide(false)}
    >
      <img src={data.url} alt="" />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <span>{afterLastSpace}</span>
        <span>{"Метка: " + data.product_label}</span>
      </div>
      <div
        style={{
          position: "absolute",
          backgroundColor: "rgba(128, 128, 128, 0.7)",
          width: "100%",
          height: "100%",
          left: 0,
          top: 0,
          display: showHide ? "flex" : "none",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <button className="catrtBtn white" style={{ width: "70%" }}>
          Добавить в заказ
        </button>
      </div>
    </div>
  );
};
export default CapsulItem;
