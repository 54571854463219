import React from "react";
import "./MainHeaderMenu.css";
import { Link} from "react-router-dom";

const HeaderMenu = ({ menu }) => {
  let classN = "HeaderMenu noActiv";
  if (menu) {
    classN += "HeaderMenu";
  }
  return (
    <div className={classN}>
      <ul>
      <li><Link to='/wiki'>База знаний</Link></li>
      <li><Link to='/hr'>Заявки на персонал</Link></li>
      </ul>
    </div>
  );
};
export default HeaderMenu;
