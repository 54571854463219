import { useState } from "react";
import Jwt_proxy from "../API/Api";


const ParserPage = ({}) => {
  let [onFileChande, setOnFileChange] = useState();

  function getFileFromInput(e) {
    setOnFileChange((onFileChande = e.target.files[0]));
  }
const [data, setdata] = useState({})
  async function SetFile() {
    let ip = localStorage.getItem("server_ip") + "/api/files/technolog";

    let token = localStorage.getItem("token");
    const formData = new FormData();
    formData.append("file", onFileChande);

    const res = await fetch(ip, {
      method: "POST",
      headers: {
        "x-token": token,
      },
      body: formData,
    });

    if (res.ok) {
      let response = await res.json();
     
        setdata(response)


     
    }
  }

  return (
    <div className="contentFlexWrapper parser">
      <div><div>
        <input
          type="file"
          onChange={(e) => {
            getFileFromInput(e);
            Jwt_proxy(SetFile);
          }}
        />
      </div>
      <div>
        <h4>{data.filename?"Имя файла: "+data.filename:null}</h4>
        <h4>{data.collection?"Коллекция: "+data.collection:null}</h4>
        <h4>{data.product_index?"Индекс продукта: "+data.product_index:null}</h4>
        <h4>{data.nomenclature?"Номенклатура: "+data.nomenclature:null}</h4>
        <h4>{data.filetype?"Тип файла: "+data.filetype:null}</h4>
        <h3>Технологическая последовательность</h3>
        {<table>{data.table_tech_subsequence&&data.table_tech_subsequence.length>0?
        data.table_tech_subsequence.map((i)=><tr>{i.map((j)=>j!=="None"?<td style={{textAlign:'left'}}>{j}</td>:<td></td>)}</tr>):null}</table>}
        <h3>Технические условия</h3>
        {<table>{data.table_tech_usl&&data.table_tech_usl.length>0?
        data.table_tech_usl.map((i)=><tr>{i.map((j)=>j!=="None"?<td style={{textAlign:'left'}}>{j}</td>:<td></td>)}</tr>):null}</table>}
      </div></div>
    </div>
  );
};

export default ParserPage;
