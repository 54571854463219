import capsuls from "../capsuls.js";
import Capsul from "../Capsul/Capsul.jsx";
import { useEffect, useState } from "react";
import Jwt_proxy from "src/components/API/Api.jsx";
import Spinner from "./spinner.jsx";
import Switch from "./swich/swich.jsx";
import MainOptProductCard from "../MainOptProduct/ProductCard.jsx";
const CapsulPage = ({
  setUrlPage,
  scrollId,
  scrollRef,
  setScrollId,
  setPopupActive,
}) => {
  const [data, setData] = useState([]);
  async function getLines(view) {
    let ip = localStorage.getItem("server_ip") + "/api/opt/get_lines";
    let token = localStorage.getItem("token");
    let res = await fetch(ip, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-token": token,
      },
      body: JSON.stringify({ view: view }),
    });
    if (res.ok) {
      let r = await res.json();
      setData(r);
    }
  }

  const [isLoading, setIsLoading] = useState(true);
  setPopupActive(false);
  const [view, setview] = useState(8);
  useEffect(() => {
    if (!isLoading) {
      setIsLoading(true);
    }
    Jwt_proxy(() =>
      getLines(view).then(() => {
        setIsLoading(false);
      })
    );
    setUrlPage("capsul_line");
  }, [view]);

  const [popupProdId, setPopupProdId] = useState(null);
  const [prodPopupActive, setProdPopupActive] = useState(false);
  const ProdPopup = ({ id, prodPopupActive, setProdPopupActive }) => {
    const [child, setChild] = useState(null);
    async function getProd() {
      if (id !== null) {
        let ip = localStorage.getItem("server_ip") + "/api/opt/product/get";
        let token = localStorage.getItem("token");
        let res = await fetch(ip, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "x-token": token,
          },
          body: JSON.stringify({ id: id }),
        });
        if (res.ok) {
          let r = await res.json();
          setChild(<MainOptProductCard data={r} setScrollId={setScrollId} />);
        }
      }
    }
    useEffect(() => {
      Jwt_proxy(getProd);
    }, [id]);
    return (
      <div
        className="grayfone"
        style={{
          display: prodPopupActive ? "block" : "none",
        }}
      >
        <div className="miniPopup">
          <div
            style={{
              width: "96%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <div
              className="popupCloseMini"
              onClick={() => {
                setProdPopupActive(false);
              }}
            >
              <img src="/static/img/Group 125.svg" alt="" />
              <p>ЗАКРЫТЬ</p>
            </div>
          </div>
          {child}
        </div>
      </div>
    );
  };

  return (
    <div className="CapsulWrapperPage" style={{ height: "70vh" }}>
      <div className="SwichBlock">
        На манекене{" "}
        {
          <Switch
            data={data}
            setview={setview}
            view={view}
            getLines={getLines}
            setData={setData}
          />
        }
        На человеке
      </div>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <div
            class="contentFlexWrapper  capsul"
            style={{ overflow: "scroll", minHeight: "100vh%" }}
          >
            {data.map((i, n) => (
              <Capsul
                prodPopupActive={prodPopupActive}
                setProdPopupActive={setProdPopupActive}
                popupProdId={popupProdId}
                setPopupProdId={setPopupProdId}
                view={view}
                data={i}
                scrollId={scrollId}
                scrollRef={scrollRef}
                key={n}
              />
            ))}
            {
              <ProdPopup
                prodPopupActive={prodPopupActive}
                setProdPopupActive={setProdPopupActive}
                id={popupProdId}
              />
            }
          </div>
        </>
      )}
    </div>
  );
};
export default CapsulPage;
