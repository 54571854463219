import {  useState } from "react";
import {
  useNavigate,
} from "react-router-dom";
// import config from "../../../config/config";
const TreeFirstItem = ({
  chuldren,
  parentData,
  getThreePathData,
  toggleSelect,
  changePid,
  setSeectionSectionsPageList,
  setArticleHistory,
  sectionPageList,
  toggleEdit,
  setItsarticleHistory,
  setSeectionArticlesPageList,
  setitemisfirst,
  setpopupActive
}) => {
  let navigate = useNavigate();
  const goWiki = () => {
    navigate("/wiki/");
  };
  function ButtonToggleOpen() {
    if (buttonToggle === "+") {
      setButtonToggle((buttonToggle = "-"));
    } else {
      setButtonToggle((buttonToggle = "+"));
    }
  }
  let [buttonToggle, setButtonToggle] = useState("+");

  const [active, setActive] = useState(true);
  let classLists = "TreeBody";
 
  if (active) {
    classLists += " acActive";
  }
  function click() {
    if (changePid !== undefined) {
      changePid(parentData);
      if (setpopupActive){
        setpopupActive(false)
      }
      
    }
  }
 
  const getSections = async (sectionId) => {
   
    let ip = localStorage.getItem('server_ip') + "/wiki/section/gen_children";
    let token = localStorage.getItem("token");

    let res = await fetch(ip, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-token": token,
      },
      body: JSON.stringify({ pid: sectionId }),
    });
    if (res.status === 403) {
      alert("секция не найдена");
    } else {
      let r = await res.json();
        setSeectionSectionsPageList(r.sections_for_page);
        setSeectionArticlesPageList(r.articles_for_page);
        setitemisfirst(r.tirstItem)
      
    }
  };
  return (
    
    <div style={{ width: "100%" }}>
      <div className={"TreeGroupItem"} >
        <div
          className={"TreeGroupItemTitle"}
          id={parentData.id}
          onDoubleClick={() => {
            ButtonToggleOpen();
            goWiki();
            setActive(!active);
            click();
            if (toggleEdit!==undefined){
              toggleEdit(false);
            }
            setItsarticleHistory(false)
            if (setArticleHistory!==undefined){
              setArticleHistory([])
            }
          }}
          onClick={() => {
            goWiki();
            getThreePathData(parentData);
            toggleSelect(parentData.id);
            if (setSeectionSectionsPageList !== undefined) {
              getSections(parentData.id);
            }
            if (toggleEdit!==undefined){
              toggleEdit(false);
            }
            setItsarticleHistory(false)
            if (setArticleHistory!==undefined){
              setArticleHistory([])
            }
          }}
        >
         
          <button
            type="button"
            style={{marginLeft:'9px'}}
            onClick={() => {
              ButtonToggleOpen();
              goWiki();
              setActive(!active);
              if (setSeectionSectionsPageList !== undefined) {
                getSections(parentData.id);
              }
              if (setArticleHistory!==undefined){
              setArticleHistory([])
            }
            }}
            className="TreeOpenButton"
          >
            {buttonToggle}
          </button>
          <img src="/static/img/Group 187.svg" alt=""></img>

          <span className="strong">{parentData.name}</span>
        </div>
        <div className={classLists}>{chuldren}</div>
      </div>
    </div>
  );
};
export default TreeFirstItem;
