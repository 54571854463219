import { useEffect } from "react";
import {

  useParams,
  useNavigate,
} from "react-router-dom";
import config from "../../../config/config";
import Jwt_proxy from "../../API/Api";

const SectionMenu = ({
  setSeectionSectionsPageList,
  setSeectionArticlesPageList,
  setSectionId,
  setLeftTree,
  setItsarticleHistory,
  getLeftTree,
  section_id,
  getLeftArr,
  toggleEdit,
  nameAndPathCreate,
  toggleSelect,
  secundefined,
  setArticleHistory,
  getHist,
  setitemisfirst,
  parentData
}) => {
  const { Section_Id } = useParams();
  let navigate = useNavigate();
  const goWiki = () => {
    navigate("/wiki");
  };
  setSectionId(Section_Id);
  
  async function getSection() {
    let ip = localStorage.getItem("server_ip") + "/api/section/get";
    let token = localStorage.getItem("token");
    let res = await fetch(ip, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-token": token,
      },
      body: JSON.stringify({id:Section_Id}),
    });
    if (!res.ok) {
      alert("у вас нет доступа к данной секции");
      goWiki();
    }
     
  }
  useEffect(() => {
    
    Jwt_proxy(getSection)
   
    if (nameAndPathCreate.id === null) {
      
      Jwt_proxy(() => getLeftArr(Section_Id));
      
    }
    // else{
    //  Jwt_proxy(()=> getSections(Section_Id))
    //   Jwt_proxy(getHistory)
    // }
    if (secundefined === true) {
      alert("у вас нет доступа к данной секции");
      goWiki();
    }
    
    toggleEdit(false);
    setItsarticleHistory(false);
  }, [Section_Id]);
  return <></>;
};
export default SectionMenu;
